export enum ScrollToTopSpeed {
  SLOW = "500",
  FAST = "250",
}

export interface ScrollToTopProps {
  /** For scroll to top accessibility */
  ariaLabel?: string;

  /** Any extra CSS classes for the component */
  className?: string;

  /** Options to set the scroll speed to `FAST` or `SLOW` in milliseconds. Defaults to FAST. */
  duration?: ScrollToTopSpeed | "250" | "500";

  /** ID for the component */
  id?: string;

  /** A callback that runs when the button is clicked */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** A callback that runs after the button is clicked and page has scrolled to the top */
  onScrollEnd?: () => void;

  /** Testing Key for Jest */
  testId?: string;
}
