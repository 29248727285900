import React from "react";
import { TableHeadProps } from "./table.types";

export const TableHead = ({
  children,
  className,
  ...rest
}: TableHeadProps): JSX.Element => {
  return (
    <thead className={className} {...rest}>
      {children}
    </thead>
  );
};

TableHead.displayName = "TableHead";
