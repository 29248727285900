import React, { ReactElement, useEffect, useRef } from "react";
import classNames from "classnames";
import { DrawerHeading, DrawerProps } from "./drawer.types";
import RadioButton from "../radio-button";

export const Drawer = ({
  title,
  headingLevel,
  children,
  className,
  showRadioButton,
  id,
  _privateProps = { accordionId: "", isExpanded: false },
}: DrawerProps): ReactElement => {
  const drawerContentRef = useRef<HTMLDivElement>(null);

  // Set proper style for default open drawers to avoid missing animation on close
  useEffect(() => {
    if (_privateProps.isExpanded) {
      if (drawerContentRef.current) {
        drawerContentRef.current.style.height = "auto";
      }
    }
    // Deps disabled, because we need this setting only for initial render.
    // On later interactions "expand" and "collapse" take care of proper animation transitions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!_privateProps.accordionSet) {
    return (
      <div>
        {`<Drawer>`} component can only be used inside an {`<Accordion>`}{" "}
        instance.
      </div>
    );
  }

  const classes = classNames("spark-accordion__drawer", className);
  const Title = headingLevel ?? DrawerHeading.H4;

  /**
   * Styles needed to accommodate semantic changes in the component's structure.
   * The `header` has been replaced with a `button` to avoid accessibility issues.
   */
  const headerToButtonStyleAdjustments: React.CSSProperties = {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    textAlign: _privateProps.rtl ? "right" : "left",
  };

  return (
    <section className={classes}>
      <button
        type="button"
        style={headerToButtonStyleAdjustments}
        onClick={showRadioButton ? undefined : _privateProps.onClick}
        className="spark-accordion__header"
        tabIndex={showRadioButton ? -1 : 0}
        aria-controls={`${id}-content`}
        aria-expanded={_privateProps.isExpanded}
        id={showRadioButton ? undefined : id}
      >
        {showRadioButton ? (
          <RadioButton
            onClick={_privateProps.onClick}
            label={title}
            name={_privateProps.accordionId}
            defaultChecked={_privateProps.isExpanded}
            id={id}
          />
        ) : (
          <Title>{title}</Title>
        )}
      </button>
      <div
        role="region"
        className="spark-accordion__content"
        id={`${id}-content`}
        aria-labelledby={id}
        ref={drawerContentRef}
      >
        {children}
      </div>
    </section>
  );
};

// Backwards compatibility to support component property. Enum import should be used instead.
Drawer.HEADING = DrawerHeading;

Drawer.displayName = "Drawer";
