import React, { forwardRef, ReactElement } from "react";
import classNames from "classnames";
import { SwitchProps } from "./switch.types";

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      ariaLabelledBy,
      checked,
      className,
      defaultChecked,
      disabled,
      id,
      name,
      testId,
      value,
      onChange,
      ...rest
    },
    ref
  ): ReactElement => (
    <label
      className={classNames("spark-toggle-switch", className)}
      data-testid={testId}
      id={id}
    >
      <input
        aria-labelledby={ariaLabelledBy}
        className={classNames("spark-toggle__input")}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        type="checkbox"
        value={value}
        ref={ref}
        onChange={(e): void => {
          onChange?.(e, e.target.value);
        }}
        {...rest}
      />
      <span className="spark-toggle-switch__handle" />
      <span className="spark-toggle-switch__track" />
    </label>
  )
);

Switch.displayName = "Switch";
