import React, { ReactElement } from "react";
import { Global, css } from "@emotion/react";

export const CalendarCssOverrides = (): ReactElement => {
  /**
   * CSS override description:
   * - Supplementing missing styles for Calendar disabled days
   */

  return (
    <Global
      styles={css`
        /* Spark-React styles for Calendar disabled days */
        .spark-calendar__day--disabled:hover .spark-calendar__day--number {
          background-color: transparent !important;
          color: #adb1b8 !important;
        }
        .spark-calendar__day--disabled.spark-calendar__day--selected
          .spark-calendar__day--number,
        .spark-calendar__day--disabled.spark-calendar__day--selected:hover
          .spark-calendar__day--number {
          background-color: #caccd1 !important;
          color: #fff !important;
          text-shadow: none !important;
        }
        .spark-calendar__range-middle.spark-calendar__day--disabled:not(
            .spark-calendar__day--selected
          ):not(.spark-calendar__day--inactive)::before,
        .spark-calendar__range-end.spark-calendar__day--disabled::before,
        .spark-calendar__range-start.spark-calendar__day--disabled::before {
          background-color: #f6f6f7 !important;
        }
      `}
    />
  );
};
