import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const ClientOnlyPortal = ({
  children,
  selector,
}: {
  children: ReactNode;
  selector: string;
}): ReactElement | null => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export default ClientOnlyPortal;
