import { ReactNode } from "react";

export enum DrawerHeading {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

export interface PrivateProps {
  /** [Internal] Unique parent accordion Id */
  accordionId: string;

  /** [Internal] Toggles whether Drawer is open or closed */
  isExpanded?: boolean;

  /** [Internal] */
  accordionSet?: boolean;

  /** [Internal] */
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void | undefined;

  /** [Internal] Right to Left Property */
  rtl?: boolean;
}

export interface DrawerProps {
  /** Content to use on Drawer's header */
  title?: ReactNode;

  /** Determines which <H*> level to use for the Drawer's title */
  headingLevel?: DrawerHeading | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  /** React children for the component */
  children: ReactNode;

  /** Any extra CSS classes for the component */
  className?: string;

  /** Show radio button in the drawer header */
  showRadioButton?: boolean;

  /** Unique identifier for drawer */
  id?: string;

  /** Determines if drawer is initially open, used for uncontrolled variant. For controlled variant use `activeDrawerIds` property on Accordion instead */
  expanded?: boolean;

  /** Internal props */
  _privateProps?: PrivateProps;
}
