import React, { forwardRef, ReactElement } from "react";
import { TypeAhead } from "../../utils/typeahead/typeahead";
import { TypeaheadProps } from "../../utils/typeahead/typeahead.types";
import { isValidValue } from "./TypeaheadYear";

interface TypeaheadMonthProps
  extends Omit<
    TypeaheadProps,
    "width" | "name" | "onChange" | "isValidInputCharacter"
  > {
  /** A callback function run when the input value changes. **/
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const TypeaheadMonth = forwardRef(
  (
    { value: month, onChange, onBlur, ...rest }: TypeaheadMonthProps,
    externalRef
  ): ReactElement => {
    const onChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>,
      val: string
    ): boolean => {
      if (isValidValue(val) || val === "") {
        const num = Number(val);
        onChange(e, num > 12 ? "12" : val);
        return true;
      }
      return false;
    };

    const onBlurHandle = (
      e: React.FocusEvent<HTMLInputElement>,
      val: string
    ): void => {
      let valStr = val;
      if (val.length === 1) {
        valStr = val.padStart(2, "0");
        onChange(e, valStr);
      }
      onBlur(e, valStr);
    };

    return (
      <TypeAhead
        {...rest}
        ref={externalRef}
        name="month"
        value={month}
        width={21}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
        isValidInputCharacter={isValidValue}
      />
    );
  }
);

TypeaheadMonth.displayName = "TypeaheadMonth";
