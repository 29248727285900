/**
 * # Get Children
 * See if an element has children which match a query.
 *
 * @param {Element} el
 * @param {String} query
 * @return {List}
 *
 */

import matches from "../dom/matches";

function getChildren(el, query) {
  // @TODO: utils/traversal methods should be rewritten to Typescript
  var list = [];
  var i = 0;
  var len = el.children.length;

  for (; i < len; i++) {
    if (matches(el.children[i], query)) {
      list.push(el.children[i]);
    }
  }

  return list;
}

export default getChildren;
