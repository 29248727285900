import React, { Dispatch } from "react";
import { InputGroupState } from "../context/context.types";
import { InputGroupAction } from "../context/reducer.types";

export const initialState: InputGroupState = {
  isWithinGroup: false,
  statusMessage: undefined,
  status: undefined,
  childrenStatus: [],
};

export const InputGroupContext = React.createContext<{
  state: InputGroupState;
  dispatch: Dispatch<InputGroupAction>;
}>({ state: initialState, dispatch: () => null });
