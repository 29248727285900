import React from "react";

export const DayOfWeek = ({
  dayOfWeek,
}: {
  dayOfWeek: string;
}): JSX.Element => {
  return (
    <span className="spark-calendar__day-of-week" key={dayOfWeek}>
      {dayOfWeek}
    </span>
  );
};

DayOfWeek.displayName = "DayOfWeek";
