/**
 * Methods that show a hover indicator when one range value has been entered but the other has not yet been entered
 * Code mainly based on EDL calendar-popover.js file
 * @TODO: Code should be refactored to avoid modes mutation and nested ternary operators
 * Functionality should be based on the current state, not the existence of CCS classes
 */

import { MutableRefObject } from "react";
import getParent from "../../utils/traversal/get-parent";
import getSiblingAfter from "../../utils/traversal/get-sibling-after";
import getSiblingBefore from "../../utils/traversal/get-sibling-before";
import hasClass from "../../utils/dom/has-class";

const _updateHoverClasses = (
  hoverStarts: HTMLButtonElement[],
  hoverEnds: HTMLButtonElement[],
  hoverStartsRef: MutableRefObject<HTMLButtonElement[]>,
  hoverEndsRef: MutableRefObject<HTMLButtonElement[]>
): void => {
  hoverStarts =
    hoverStarts instanceof Array
      ? hoverStarts
      : hoverStarts
      ? [hoverStarts]
      : [];
  hoverEnds =
    hoverEnds instanceof Array ? hoverEnds : hoverEnds ? [hoverEnds] : [];

  let allStarts: HTMLButtonElement[] = [];
  const curStarts: HTMLButtonElement[] = [];
  const newStarts: HTMLButtonElement[] = [];
  let allEnds: HTMLButtonElement[] = [];
  const curEnds: HTMLButtonElement[] = [];
  const newEnds: HTMLButtonElement[] = [];

  hoverStarts.forEach((el) => {
    const index = hoverStartsRef.current.indexOf(el);

    // Already hovered.
    if (index !== -1) {
      curStarts.push(el);

      // Not already hovered. Ready to add the class.
    } else if (el) {
      newStarts.push(el);
      el.classList.add("hover-start");
    }
  });

  allStarts = Array.prototype.concat.call([], curStarts, newStarts);

  hoverStartsRef.current.forEach((el) => {
    if (allStarts.indexOf(el) === -1) {
      el?.classList.remove("hover-start");
    }
  });

  hoverEnds.forEach((el) => {
    const index = hoverEndsRef.current.indexOf(el);

    // Already hovered.
    if (index !== -1) {
      curEnds.push(el);

      // Not already hovered. Ready to add the class.
    } else if (el) {
      newEnds.push(el);
      el.classList.add("hover-end");
    }
  });

  allEnds = Array.prototype.concat.call([], curEnds, newEnds);

  hoverEndsRef.current.forEach((el) => {
    if (allEnds.indexOf(el) === -1) {
      el?.classList.remove("hover-end");
    }
  });

  hoverStartsRef.current = allStarts;
  hoverEndsRef.current = allEnds;
};

// Manage range variant hover state indicator
export const onCalendarDateMouseOver = (
  e: React.MouseEvent<HTMLButtonElement>,
  hoverStartsRef: MutableRefObject<HTMLButtonElement[]>,
  hoverEndsRef: MutableRefObject<HTMLButtonElement[]>
): void => {
  const day = getParent(e.target, ".spark-calendar__day");

  if (!day) {
    _updateHoverClasses([], [], hoverStartsRef, hoverEndsRef);
    return;
  }

  const month = getParent(e.target, ".spark-calendar__month");

  const mHasClass = (c: string): boolean => {
    return hasClass(month, c);
  };
  let newStart = [];
  let newEnd = [];
  let daySel;

  // A month with a value before it but no value of its own, hover starts
  // from the first day to the hovered day.
  if (
    mHasClass("value-before") &&
    !mHasClass("after-range-end") &&
    !mHasClass("has-value") &&
    !mHasClass("value-after")
  ) {
    newStart.push(
      month.querySelector(
        ".spark-calendar__day:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
      )
    );
    newEnd.push(day);

    // Add a hover range to a previous month.
    let prevMonth = month;
    while (
      (prevMonth = getSiblingBefore(prevMonth, ".spark-calendar__month")) &&
      !hasClass(prevMonth, "value-after") &&
      (hasClass(prevMonth, "has-value") || hasClass(prevMonth, "value-before"))
    ) {
      daySel = prevMonth.querySelectorAll(".spark-calendar__day--selected");
      daySel = daySel[daySel.length - 1];
      daySel = daySel || prevMonth.querySelector(".spark-calendar__day");

      if (daySel) {
        newStart.push(daySel);
        daySel = prevMonth.querySelectorAll(".spark-calendar__day");
        daySel = daySel[daySel.length - 1];
        newEnd.push(daySel);
      }
    }

    // A month with a value, highlight either from the hovered day to
    // the selection or from the selection to the day.
  } else if (
    mHasClass("value-after") &&
    !mHasClass("before-range-start") &&
    !mHasClass("has-value") &&
    !mHasClass("value-before")
  ) {
    let nextMonth = getSiblingAfter(month, ".spark-calendar__month");
    let daySelNextMonth;
    if (nextMonth) {
      daySelNextMonth = nextMonth.querySelectorAll(
        ".spark-calendar__day:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
      );
    }
    const daySelMonth = month.querySelectorAll(
      ".spark-calendar__day:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
    );

    newStart.push(day);
    newEnd.push(
      nextMonth
        ? daySelNextMonth[daySelNextMonth.length - 1]
        : daySelMonth[daySelMonth.length - 1]
    );

    // Add a hover range to a next month.
    nextMonth = month;
    while (
      (nextMonth = getSiblingAfter(nextMonth, ".spark-calendar__month")) &&
      !hasClass(nextMonth, "value-before") &&
      (hasClass(nextMonth, "has-value") || hasClass(nextMonth, "value-after"))
    ) {
      const dayNormal = nextMonth.querySelectorAll(".spark-calendar__day");
      const daySelected = nextMonth.querySelector(
        ".spark-calendar__day--selected"
      );

      newStart.push(dayNormal[0]);
      newEnd.push(daySelected || dayNormal[dayNormal.length - 1]);
    }

    // A month with a value, highlight either from the hovered day to
    // the selection or from the selection to the day.
  } else if (
    mHasClass("has-value") &&
    !mHasClass("value-before") &&
    !mHasClass("value-after") &&
    ((newStart = getSiblingBefore(day, ".spark-calendar__day--selected")) ||
      (newEnd = getSiblingAfter(day, ".spark-calendar__day--selected")))
  ) {
    if (newStart) {
      newStart = [newStart];
      newEnd = [day];
    } else {
      newEnd = [newEnd];
      newStart = [day];
    }

    if (
      hasClass(newEnd[0], "spark-calendar__range-start") ||
      hasClass(newStart[0], "spark-calendar__range-end")
    ) {
      newStart = [];
      newEnd = [];
    }
  }

  _updateHoverClasses(newStart, newEnd, hoverStartsRef, hoverEndsRef);
};
