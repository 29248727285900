import { ResizeObserver } from "@juggle/resize-observer";
import useResizeObserver from "use-resize-observer";

const useResizeObserverWithPolyfill: typeof useResizeObserver = (opts) => {
  if (typeof window !== "undefined" && !window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
  }
  return useResizeObserver(opts);
};

export default useResizeObserverWithPolyfill;
