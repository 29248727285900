/**
 * Method handles Arrow keys pressed within the Calendar
 * Code mainly based on EDL calendar-popover.js file
 * @TODO: utils/traversal methods should be rewritten to Typescript
 */

import getParent from "../../utils/traversal/get-parent";
import getSiblingAfter from "../../utils/traversal/get-sibling-after";
import getSiblingBefore from "../../utils/traversal/get-sibling-before";
import hasClass from "../../utils/dom/has-class";
import getChildren from "../../utils/traversal/get-children";
import getIndex from "../../utils/traversal/get-index";

export const onCalendarKeyDown = (
  e: React.KeyboardEvent<HTMLButtonElement>
): void => {
  // Verify that this is a calendar day, otherwise exit
  if (!hasClass(e.target as Element, "spark-calendar__day")) {
    return;
  }

  let currentMonth;

  if (
    e.key === "ArrowLeft" ||
    e.key === "ArrowUp" ||
    e.key === "ArrowRight" ||
    e.key === "ArrowDown"
  ) {
    currentMonth = getParent(e.target, ".spark-calendar__month");
  }

  if (e.key === "ArrowLeft") {
    const prevAvailableDay = getSiblingBefore(
      e.target,
      ".spark-calendar__day:not(.spark-calendar__day--disabled)"
    );

    if (prevAvailableDay !== null) {
      prevAvailableDay.focus();
    } else {
      // Check if we are picking dates from a calendar range
      const prevMonth =
        currentMonth !== null
          ? getSiblingBefore(currentMonth, ".spark-calendar__month")
          : null;

      if (prevMonth) {
        const prevAvailableDays = getChildren(
          prevMonth.querySelector(".spark-calendar__days"),
          ".spark-calendar__day"
        );

        for (let i = prevAvailableDays.length - 1; i >= 0; i--) {
          if (
            !hasClass(prevAvailableDays[i], "spark-calendar__day--disabled") &&
            !hasClass(prevAvailableDays[i], "spark-calendar__day--inactive")
          ) {
            prevAvailableDays[i].focus();
            break;
          }
        }
      }
    }
  }

  if (e.key === "ArrowRight") {
    const nextAvailableDay = getSiblingAfter(
      e.target,
      ".spark-calendar__day:not(.spark-calendar__day--disabled)"
    );

    if (nextAvailableDay !== null) {
      nextAvailableDay.focus();
    } else {
      // Check if we are picking dates from a calendar range
      const nextMonth =
        currentMonth !== null
          ? getSiblingAfter(currentMonth, ".spark-calendar__month")
          : null;

      if (nextMonth) {
        const nextAvailableDays = getChildren(
          nextMonth.querySelector(".spark-calendar__days"),
          ".spark-calendar__day"
        );

        for (let i = 0; i < nextAvailableDays.length; i++) {
          if (
            !hasClass(nextAvailableDays[i], "spark-calendar__day--disabled") &&
            !hasClass(nextAvailableDays[i], "spark-calendar__day--inactive")
          ) {
            nextAvailableDays[i].focus();
            break;
          }
        }
      }
    }
  }

  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
    // Prevent page scroll on keyDown
    e.preventDefault();
    let calendarDays = currentMonth.querySelector(".spark-calendar__days");
    const targetIndex = getIndex(calendarDays.children, e.target as Element);

    let nextIndex = targetIndex;
    let availableDay = null;
    let focusSet = false;

    do {
      if (e.key === "ArrowUp") {
        nextIndex = nextIndex - 7;
      } else {
        nextIndex = nextIndex + 7;
      }

      availableDay = calendarDays.children.item(nextIndex);

      if (
        availableDay !== null &&
        !hasClass(availableDay, "spark-calendar__day--disabled")
      ) {
        focusSet = true;
        availableDay.focus();
        break;
      }
    } while (availableDay !== null && nextIndex >= 0 && nextIndex < 42);

    if (!focusSet) {
      if (e.key === "ArrowUp") {
        const prevMonth =
          currentMonth !== null
            ? getSiblingBefore(currentMonth, ".spark-calendar__month")
            : null;

        if (prevMonth !== null) {
          calendarDays = prevMonth.querySelector(".spark-calendar__days");

          if (nextIndex <= 0) {
            nextIndex = 42 + nextIndex;
          }

          while (nextIndex >= 0 && nextIndex < 42) {
            availableDay = calendarDays.children.item(nextIndex);

            if (
              availableDay !== null &&
              !hasClass(availableDay, "spark-calendar__day--disabled")
            ) {
              availableDay.focus();
              break;
            }

            nextIndex = nextIndex - 7;
          }
        }
      } else {
        const nextMonth =
          currentMonth !== null
            ? getSiblingAfter(currentMonth, ".spark-calendar__month")
            : null;

        if (nextMonth !== null) {
          calendarDays = nextMonth.querySelector(".spark-calendar__days");

          if (nextIndex >= 42) {
            nextIndex = nextIndex - 42;
          }

          while (nextIndex >= 0 && nextIndex < 42) {
            availableDay = calendarDays.children.item(nextIndex);

            if (
              availableDay !== null &&
              !hasClass(availableDay, "spark-calendar__day--disabled")
            ) {
              availableDay.focus();
              break;
            }

            nextIndex = nextIndex + 7;
          }
        }
      }
    }
  }
};
