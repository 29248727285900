import React from "react";
import { IconStyle } from "../icon/icon.types";

export enum BadgeStatus {
  SUCCESS = "success",
  WARNING = "warning",
  NEUTRAL = "neutral",
  CRITICAL = "critical",
  LIGHT = "light",
}

export interface PrivateProps {
  /** [Internal] Passed by Popover component only. Toggles the use of button/span elements for the Badge */
  hasPopover?: boolean;

  /** [Internal] Passed by Tooltip component only. Used for allowing Badges with Tooltip to be accessed by a keyboard */
  hasTooltip?: boolean;
}

export interface BadgeProps extends React.HTMLProps<HTMLSpanElement> {
  /** Content inside the bubble */
  label: string;

  /** Options are success, warning, critical, light and neutral */
  status:
    | BadgeStatus
    | "success"
    | "warning"
    | "neutral"
    | "critical"
    | "light";

  /** For badge accessibility when passed as child to popover or tooltip components */
  ariaLabel?: string;

  /** Id for badge */
  id?: string;

  /** Any extra CSS classes for the component */
  className?: string;

  /** The name of the Spark icon to be displayed inside of a the badge. Available icons list can be found on http://sabrespark.com/design/icons.html */
  iconName?: string;

  /** The style of the Icon **/
  iconStyle?: IconStyle | "fill" | "line";

  /** Use only with neutral status notification badge passed as a child to avatar component */
  light?: boolean;

  /** Overlays badge on avatar */
  notification?: boolean;

  /** Smaller variation of badge added as an additional class */
  small?: boolean;

  /** onClick callback, available if passed by Popover component */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** Allows getting a ref to the component instance. Once the component unmounts, React will set ref.current to null (or call the ref with null if you passed a callback ref) */
  ref?: React.RefObject<HTMLSpanElement>;

  /** [Internal] Used by Popover component */
  getRef?: (ref: HTMLSpanElement | HTMLButtonElement | null) => void;

  /** Internal props */
  _privateProps?: PrivateProps;
}
