import classNames from "classnames";
import React, { useContext } from "react";
import { TableHeaderCellProps, TableSortOrder } from "./table.types";
import { TableContext } from "./utils/tableContext";

export const TableHeaderCell = ({
  children,
  className,
  disabled,
  sort = TableSortOrder.NO_SORT,
  ...rest
}: TableHeaderCellProps): JSX.Element => {
  const { isResizable } = useContext(TableContext);

  const columnClasses = classNames(className, {
    "spark-table__disabled-column": disabled,
  });
  const tabIndexProp = sort === TableSortOrder.NO_SORT ? {} : { tabIndex: 0 };
  let sortProp = {};
  if (sort === TableSortOrder.NONE) {
    sortProp = { "data-sort": "" };
  } else if (sort === TableSortOrder.ASC || sort === TableSortOrder.DESC) {
    sortProp = { "data-sort": sort };
  }
  const classesObj = columnClasses ? { className: columnClasses } : {};
  const allProps = {
    ...rest,
    ...sortProp,
    ...tabIndexProp,
    ...classesObj,
  };

  return (
    <th {...allProps}>
      {isResizable && (
        <span className="spark-table__resize spark-table__resize--left" />
      )}
      {children}
      {sort !== TableSortOrder.NO_SORT && " "}
      {isResizable && (
        <span className="spark-table__resize spark-table__resize--right" />
      )}
    </th>
  );
};

TableHeaderCell.displayName = "TableHeaderCell";
