import classNames from "classnames";
import React, {
  FocusEventHandler,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldLevelMessage } from "../message/field-level-message";
import { HourTypeahead } from "./hour-typeahead";
import { MinuteTypeahead } from "./minute-typeahead";
import { PeriodTypeahead } from "./period-typeahead";
import {
  getTimeInput24HR,
  getValidHrMin,
  getValidMin,
  isValidMin,
  isValidPeriod,
} from "./time-selection.util";
import { TimeFormat, TimeTextInputProps } from "./types/time-text-input.types";

export const TimeTextInput = ({
  id,
  timeFormat = TimeFormat.TWELVE,
  hourAriaLabel = "Please enter hour",
  minuteAriaLabel = "Please enter minute",
  periodAriaLabel = "Please enter a.m. or p.m.",
  hourPlaceholder = "12",
  minutePlaceholder = "00",
  periodPlaceholder = "a.m.",
  hourInputName = "hr",
  minuteInputName = "min",
  periodInputName = "period",
  value,
  defaultValue,
  ref,
  className,
  onChange,
  label,
  status,
  statusMessage,
  disabled,
  inputAttrs = {},
  ...rest
}: TimeTextInputProps): ReactElement => {
  const isControlled = value !== undefined;

  const messageId = id ? `${id}_message` : undefined;

  const [focus, setFocus] = useState(false);

  const {
    hr: hrInp,
    min: minInp,
    period: periodInp,
  } = useMemo(
    () =>
      value
        ? getValidHrMin(value, timeFormat)
        : { hr: "", min: "", period: "" },
    [value, timeFormat]
  );

  const {
    hr: hrInpDefault,
    min: minInpDefault,
    period: periodInpDefault,
  } = useMemo(
    () =>
      defaultValue
        ? getValidHrMin(defaultValue, timeFormat)
        : { hr: "", min: "", period: "" },
    [defaultValue, timeFormat]
  );

  const [hours, setHours] = useState(isControlled ? hrInp : hrInpDefault);
  const [minutes, setMinutes] = useState(isControlled ? minInp : minInpDefault);
  const [period, setPeriod] = useState(
    isControlled ? periodInp : periodInpDefault
  );

  const prevTimeRef = useRef(
    getTimeInput24HR(hours, minutes, timeFormat, period)
  );

  useEffect(() => {
    if (isControlled) {
      setHours(hrInp);
      setMinutes(minInp);
      setPeriod(periodInp);
    }
  }, [isControlled, hrInp, minInp, periodInp]);

  const hrRef = useRef<HTMLInputElement>(null);
  const minRef = useRef<HTMLInputElement>(null);
  const periodRef = useRef<HTMLInputElement>(null);

  const _customFocus = (
    inpElement: HTMLInputElement | null,
    cursorPosition?: number
  ): void => {
    if (inpElement) {
      inpElement.focus();
      if (cursorPosition !== undefined && cursorPosition !== null) {
        inpElement.setSelectionRange(cursorPosition, cursorPosition);
      }
    }
  };

  const hourExitCallback = (
    isNext: boolean,
    cursorPosition?: number,
    inputForNext?: string
  ): void => {
    if (isNext) {
      if (inputForNext) {
        const newMin = inputForNext + (minutes[0] || "");
        if (isValidMin(newMin)) setMinutes(getValidMin(newMin));
        setTimeout(() => _customFocus(minRef.current, cursorPosition));
      } else {
        _customFocus(minRef.current, cursorPosition);
      }
    }
  };

  const minExitCallback = (
    isNext: boolean,
    cursorPosition?: number,
    inputForNext?: string
  ): void => {
    if (isNext) {
      if (inputForNext) {
        const newPeriod = inputForNext + period.slice(1);
        if (isValidPeriod(newPeriod)) setPeriod(newPeriod);
        setTimeout(() => _customFocus(periodRef.current, cursorPosition));
      } else {
        _customFocus(periodRef.current, cursorPosition);
      }
    } else {
      _customFocus(hrRef.current, cursorPosition);
    }
  };

  const periodExitCallback = (
    isNext: boolean,
    cursorPosition?: number
  ): void => {
    if (!isNext) {
      _customFocus(minRef.current, cursorPosition);
    }
  };

  const onTimeChange = (
    hours: string,
    minutes: string,
    period: string
  ): void => {
    const resultTime = getTimeInput24HR(hours, minutes, timeFormat, period);
    if (prevTimeRef.current !== resultTime) {
      /*
      Below IF condition is to reset the state in controlled variant to what the parent set it last.
      A controlled variant cannot update it's value without it's parent's consent.
      So, after passing the value via onChange, we reset it back to the value what parent set it last.
      */
      if (isControlled) {
        setHours(hrInp);
        setMinutes(minInp);
        setPeriod(periodInp);
      }
      onChange?.(resultTime);
      prevTimeRef.current = resultTime;
    }
  };

  const InputFields = (
    <span className="spark-input__fields">
      <HourTypeahead
        ref={hrRef}
        name={hourInputName}
        maxLength={2}
        value={hours}
        ariaLabel={hourAriaLabel}
        placeholder={hourPlaceholder}
        timeFormat={timeFormat}
        maxHr={timeFormat === TimeFormat.TWELVE ? 12 : 23}
        onChange={(e, value): void => setHours(value)}
        onBlur={(e, value): void => onTimeChange(value, minutes, period)}
        exitCallback={hourExitCallback}
        disabled={disabled}
      />
      <span className="spark-input__divider">:</span>
      <MinuteTypeahead
        ref={minRef}
        name={minuteInputName}
        maxLength={2}
        ariaLabel={minuteAriaLabel}
        placeholder={minutePlaceholder}
        value={minutes}
        onChange={(e, value): void => setMinutes(value)}
        onBlur={(e, value): void => onTimeChange(hours, value, period)}
        exitCallback={minExitCallback}
        disabled={disabled}
      />
      {timeFormat === TimeFormat.TWELVE ? (
        <>
          <span className="spark-input__divider"> </span>
          <PeriodTypeahead
            ref={periodRef}
            name={periodInputName}
            maxLength={4}
            value={period}
            ariaLabel={periodAriaLabel}
            placeholder={periodPlaceholder}
            onChange={(e, value): void => setPeriod(value)}
            onBlur={(e, value): void => onTimeChange(hours, minutes, value)}
            exitCallback={periodExitCallback}
            disabled={disabled}
          />
        </>
      ) : null}
    </span>
  );

  const labelFocus: FocusEventHandler<HTMLLabelElement> = (): void =>
    setFocus(true);

  const labelBlur: FocusEventHandler<HTMLLabelElement> = (): void =>
    setFocus(false);

  const componentClasses = classNames(
    "spark-input",
    "spark-time",
    {
      active:
        !!hours ||
        !!minutes ||
        (timeFormat === TimeFormat.TWELVE && !!period) ||
        focus,
      focus,
    },
    { disabled },
    className
  );

  const containerAttrs: { [key: string]: string } = {};

  if (status) {
    containerAttrs["data-" + status] = "true";
  }

  if (timeFormat === TimeFormat.TWENTY_FOUR) {
    inputAttrs["data-time-format"] = "24";
  }

  const valueIn24HrFormat = getTimeInput24HR(
    hours,
    minutes,
    timeFormat,
    period
  );

  return (
    <label
      {...rest}
      className={componentClasses}
      onFocus={labelFocus}
      onBlur={labelBlur}
      {...containerAttrs}
    >
      {InputFields}
      <input
        {...inputAttrs}
        className={"spark-input__field"}
        disabled={disabled}
        value={valueIn24HrFormat}
        type="time"
        style={{ display: "none" }}
        onChange={(e): void => {}}
      />
      <span className="spark-label">{label}</span>
      <FieldLevelMessage
        id={messageId}
        status={status}
        statusMessage={statusMessage}
      />
    </label>
  );
};

TimeTextInput.displayName = "TimeTextInput";
