import React, { useMemo, useRef } from "react";
import classNames from "classnames";
import { CalendarDateProps } from "./CalendarDate.types";
import { withoutTimeStr } from "./calendar.util";

export const CalendarDate: React.FC<CalendarDateProps> = ({
  date,
  tabIndex,
  note,
  focussedDate,
  isToday,
  isRangeStart,
  isRangeEnd,
  isRangeMiddle,
  inMonth,
  isDateDisabled,
  isCurrent,
  hideDaysOutsideCurrentMonth,
  className,
  onClick,
  onKeyDown,
  onMouseOver,
}): JSX.Element => {
  const styles = classNames(
    "spark-calendar__day",
    {
      "spark-calendar__day--selected":
        inMonth && (isCurrent || isRangeStart || isRangeEnd),
      "spark-calendar__day--disabled": isDateDisabled,
      "spark-calendar__day--inactive spark-calendar__day--disabled": !inMonth,
      "spark-calendar__day--today": inMonth && isToday,
      "spark-calendar__range-start": isRangeStart,
      "spark-calendar__range-end": isRangeEnd,
      "spark-calendar__range-last": isRangeEnd,
      "spark-calendar__range-middle": isRangeMiddle,
    },
    className
  );

  const _date = useMemo((): Date => {
    if (Array.isArray(date)) {
      return new Date(date.join("/"));
    }

    return new Date(date);
  }, [date]);

  // Used for setting focus when keyboard navigation is used
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  if (focussedDate && withoutTimeStr(focussedDate) === withoutTimeStr(_date)) {
    buttonRef?.current?.focus();
  }

  return (
    <button
      type="button"
      aria-label={_date.toLocaleString("default", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}
      aria-hidden={isDateDisabled || !inMonth}
      disabled={isDateDisabled && inMonth}
      ref={buttonRef}
      data-date={date?.join("-")}
      className={styles}
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseOver={onMouseOver}
      style={{
        visibility:
          !inMonth && hideDaysOutsideCurrentMonth ? "hidden" : undefined,
      }}
    >
      <span className="spark-calendar__day--number">
        {_date.getDate()}
        <span className="spark-calendar__day-note">{note}</span>
      </span>
    </button>
  );
};

CalendarDate.displayName = "CalendarDate";
