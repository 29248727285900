import React from "react";

export enum StepIndicatorType {
  STANDARD = "standard",
  STANDARD_DROPDOWN = "standard-dropdown",
  LARGE = "large",
  CONDENSED = "condensed",
  DROPDOWN = "dropdown",
}

export interface StepIndicatorProps {
  /** Index number of the step to mark as active. Steps are numbered sequentially starting with `1`. Cannot pass in active step that is also disabled */
  activeStep: number;

  /** Any extra CSS classes for the component */
  className?: string;

  /** Expects instance of Step */
  children?: React.ReactNode;

  /** Steps to mark as "completed" */
  completedSteps?: number[];

  /** The aria-label for the Step component. Used by screen reader technology to provide a user-friendly name for completed Steps */
  completedAriaLabel?: string;

  /** The aria-label for the Step component. Used by screen reader technology to provide a user-friendly name for the current Step */
  currentAriaLabel?: string;

  /** Text for the header menu of the dropdown and standard dropdown variants for accessibility purposes */
  dropdownHeaderText?: string;

  /** A unique identifer for the container element that wraps the Steps */
  id?: string;

  /** The type of Step Indicator to render */
  type?:
    | StepIndicatorType
    | "standard"
    | "standard-dropdown"
    | "large"
    | "condensed"
    | "dropdown";

  /** Callback to trigger every time a step is clicked. It receives two arguments: `clickedStep` and `event` */
  onChange?: (
    e: React.MouseEvent<HTMLAnchorElement>,
    clickedStep: number
  ) => void;

  /**
   * @ignore
   * Test ID used for identifying component link in automated tests
   */
  testId?: string;
}
