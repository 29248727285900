import React from "react";

export enum AvatarSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
}

export interface AvatarProps {
  /** For accessibility when child component is passed to avatar */
  ariaDescribedby?: string;

  /** Id for avatar */
  id?: string;

  /** For avatar accessibility */
  ariaLabel?: string;

  /** Any extra CSS classes for the component */
  className?: string;

  getRef?: (ref: HTMLSpanElement | null) => void;

  /** Adds class for non-text avatars */
  image?: boolean;

  /** A lighter theme for the neutral color scheme. */
  light?: boolean;

  /** Function triggered by onClick */
  onClick?: React.MouseEventHandler<HTMLSpanElement>;

  /** Determines size Avatar will render: sm, md, lg, xl */
  size?: AvatarSize | "sm" | "md" | "lg" | "xl";

  /** Adds class for non-image avatars */
  text?: boolean;

  children: React.ReactNode;
}
