import { Options } from "../../select-input/select-input.types";
import React from "react";

type SelectForCalendarType = {
  selectName: "month" | "year";
  options: Options[];
  value: string;
  onChange: (val: number) => void;
  disabled?: boolean;
};

export const SelectForCalendar = ({
  selectName,
  options,
  value,
  onChange,
  disabled,
}: SelectForCalendarType): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value = "0" } = e.target;
    onChange?.(parseInt(value, 10));
  };
  const filteredOptions = options.filter(
    (item) => item.value !== "" && item.label !== ""
  );
  return (
    <label className="spark-select spark-calendar__select">
      <select
        value={value}
        className="spark-select__input"
        name={selectName}
        onChange={handleChange}
        disabled={disabled}
      >
        {filteredOptions
          ? filteredOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label || option.value}
              </option>
            ))
          : null}
        )
      </select>
    </label>
  );
};

SelectForCalendar.displayName = "SelectForCalendar";
