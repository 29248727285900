import React, { forwardRef } from "react";
import { TextInputProps } from "./text-input.types";
import { TextInputBase } from "./text-input-base";

export interface TextInputWithForwardRef
  extends React.ForwardRefExoticComponent<
    TextInputProps & React.RefAttributes<HTMLInputElement>
  > {}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    return <TextInputBase {...props} ref={ref} />;
  }
) as TextInputWithForwardRef;

TextInput.displayName = "TextInput";
