type Breakpoint = {
  min: number;
  max: number;
};

export type Breakpoints = {
  xs: Breakpoint;
  sm: Breakpoint;
  md: Breakpoint;
  lg: Breakpoint;
  xl: Breakpoint;
};

export const defaultBreakpoints: Breakpoints = {
  xs: {
    min: 0,
    max: 543,
  },
  sm: {
    min: 544,
    max: 795,
  },
  md: {
    min: 796,
    max: 1047,
  },
  lg: {
    min: 1048,
    max: 1799,
  },
  xl: {
    min: 1800,
    max: Infinity,
  },
};

export const isUndefined = (value: any): boolean => {
  return value === undefined;
};

/**
 * # Offset Position
 * Source: sabre-spark version 2.4.6
 * Get the offset position of the element.
 *
 * @param {Element} el
 * @param {Boolean} isFixed
 * @return {Object}
 *
 * @module node_modules/sabre-spark/js/src/helpers/dom
 */
export const offset = (
  el: Element,
  isFixed: boolean
): { left: number; top: number } => {
  if (!el) {
    return {
      left: 0,
      top: 0,
    };
  }
  const { top, left } = el.getBoundingClientRect();
  const { scrollX, scrollY, pageXOffset, pageYOffset } = window;

  if (!isFixed) {
    return {
      left: left + (isUndefined(scrollX) ? pageXOffset : scrollX),
      top: top + (isUndefined(scrollY) ? pageYOffset : scrollY),
    };
  }

  return {
    left,
    top,
  };
};

/**
 * # Box Position
 * Source: sabre-spark version 2.4.6
 * How is one element positioned relative to another?
 *
 * @example
 * boxPosition(
 * {width: 100, height: 300, left: 0, top: 0},
 * {width: 200, height: 50, left: 100, top: 40}
 * )
 *
 * @module node_modules/sabre-spark/js/src/helpers/position/box-position.js
 *
 * @param {Object} a
 * @param {Object} b
 * @return {String}
 */

type box = { width: number; height: number; left: number; top: number };
export const boxPosition = (
  a: box,
  b: box
): "left" | "right" | "above" | "below" | "overlap" => {
  const aXSpan = a.left + a.width;
  const aYSpan = a.top + a.height;
  const bXSpan = b.left + b.width;
  const bYSpan = b.top + b.height;

  // A is fully left of b
  if (aXSpan <= b.left) return "left";

  // A is fully right of b
  if (a.left >= bXSpan) return "right";

  // A is fully above b
  if (aYSpan <= b.top) return "above";

  // A is fully below b
  if (a.top >= bYSpan) return "below";

  // Boxes overlap
  return "overlap";
};

/**
 * This method returns the key of the firstvelement `predicate`
 * returns truthy for instead of the element itself.
 *
 * Source: lodash 4.17.21
 * @category Object
 * @param {Object} object The object to inspect.
 * @param {Function} predicate The function invoked per iteration.
 * @returns {string|undefined} Returns the key of the matched element,
 *  else `undefined`.
 * @example
 * const users = {
 *   'barney':  { 'age': 36, 'active': true },
 *   'fred':    { 'age': 40, 'active': false },
 *   'pebbles': { 'age': 1,  'active': true }
 * }
 *
 * findKey(users, ({ age }) => age < 40)
 * // => 'barney' (iteration order is not guaranteed)
 */

export const findKey = (object: any, predicate: any): any => {
  let result;
  if (object == null) {
    return result;
  }
  Object.keys(object).some((key) => {
    const value = object[key];
    if (predicate(value, key, object)) {
      result = key;
      return true;
    }
    return null;
  });
  return result;
};

/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 *
 * Source: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 * @category Object
 * @param {Object} object The object to query.
 * @param {Array|string} path The path of the property to get.
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 * @example
 * const object = { 'a': [{ 'b': { 'c': 3 } }] }
 *
 * get(object, 'a[0].b.c')
 * // => 3
 *
 * get(object, ['a', '0', 'b', 'c'])
 * // => 3
 *
 * get(object, 'a.b.c', 'default')
 * // => 'default'
 */

export const get = (obj: any, path: string, defaultValue = undefined): any => {
  const travel = (regexp: any): any =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

/**
 * # Breakpoint Helpers
 * Find the active breakpoint.
 *
 * @param {Number} innerWidth Width (in pixels) of the browser window viewport including, if rendered, the vertical scrollbar.
 * @param {object} breakpoints
 *
 * @module node_modules/sabre-spark/js/src/helpers/dom/breakpoint.js
 */

export const getBreakpoint = (
  innerWidth: number = 0,
  breakpoints: Breakpoints = defaultBreakpoints
): string | undefined =>
  findKey(
    breakpoints,
    (breakpoint: Breakpoint) =>
      breakpoint.min <= innerWidth && breakpoint.max >= innerWidth
  );

export const isCustomEvent = (event: Event): event is CustomEvent => {
  return "detail" in event;
};

export const isSmallScreen = (breakpoint?: string): boolean => {
  return ["sm", "xs"].indexOf(breakpoint || "") !== -1;
};

export const normalizeToIdFormat = (str: string): string =>
  str.replace(/\s+/g, "-").toLowerCase();

/**
 * Method returns a valid Spark class name whether the icon name already contained a prefix or not
 */
export const renderIconClassName = (
  icon: string = "",
  prefix: string = "spark-icon-"
): string | undefined => {
  if (!icon) return;

  // Regular expression pattern to match and replace the start of the string
  const searchPattern = new RegExp("^" + prefix);

  // Replacement text
  const replacement = "";

  // Perform the replacement using the regular expression
  return `${prefix}${icon?.replace(searchPattern, replacement)}`;
};
