import React, { forwardRef, ReactElement } from "react";
import { TimeFormat } from "../types";
import { TypeAhead } from "../utils/typeahead/typeahead";
import { TypeaheadProps } from "../utils/typeahead/typeahead.types";
import { isValidHr } from "./time-selection.util";

interface HourTypeaheadProps
  extends Omit<TypeaheadProps, "width" | "onChange" | "isValidInputCharacter"> {
  maxHr: number;
  timeFormat: TimeFormat;

  /** A callback function run when the input value changes. **/
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const HourTypeahead = forwardRef(
  (
    {
      value: hours,
      maxHr,
      timeFormat,
      onChange,
      onBlur,
      ariaLabel,
      name,
      ...rest
    }: HourTypeaheadProps,
    externalRef
  ): ReactElement => {
    const onChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>,
      val: string
    ): boolean => {
      if (isValidHr(val) || val === "") {
        const num = Number(val);
        onChange(e, num > maxHr ? maxHr.toString() : val);
        return true;
      }
      return false;
    };

    const onBlurHandle = (
      e: React.FocusEvent<HTMLInputElement>,
      val: string
    ): void => {
      let valStr = val;
      if (valStr.length === 1) {
        valStr = valStr.padStart(2, "0");
        onChange(e, valStr);
      }
      if (valStr === "00" && timeFormat === TimeFormat.TWELVE) {
        valStr = "12";
        onChange(e, valStr);
      }
      onBlur(e, valStr);
    };

    return (
      <TypeAhead
        {...rest}
        ref={externalRef}
        name={name}
        value={hours}
        ariaLabel={ariaLabel}
        width={21}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
        isValidInputCharacter={isValidHr}
      />
    );
  }
);

HourTypeahead.displayName = "HourTypeahead";

export { HourTypeahead };
