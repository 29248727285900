import { ReactNode } from "react";
import { exceptRegion } from "./message-panel.types";

export enum MessageRole {
  ALERT = "alert",
  REGION = "region",
  STATUS = "status",
}

export enum MessageStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export enum MessageType {
  PAGE = "page",
  CONTAINER = "container",
}

export interface MessagePropsBase {
  /** React children for the component if `type` is "page"  */
  children?: ReactNode;

  /** Should the component animate in */
  animate?: boolean;

  /** Any extra CSS classes for the component */
  className?: string;

  /** Message content for the component */
  content?: string;

  /** Message status */
  status?: MessageStatus | "success" | "warning" | "error" | "info";

  /** Title for the component */
  title?: string;

  /** Title Heading Level **/
  titleHeadingLevel?: 2 | 3 | 4 | 5 | 6 | "2" | "3" | "4" | "5" | "6";

  /** Message type */
  type: MessageType | "page" | "container";
}

interface MessageWithNonRegion extends MessagePropsBase {
  role: exceptRegion;
  ariaLabel?: string;
  ariaLabelledBy?: string;
}

interface MessageWithRegionAriaLabel extends MessagePropsBase {
  // aria-label, required when ARIA role is "region", but can be substituted by ariaLabelledBy
  role: MessageRole.REGION | "region";
  ariaLabel: string;
  ariaLabelledBy?: never;
}

interface MessageWithRegionAriaLabelledBy extends MessagePropsBase {
  // aria-labelledby, required when ARIA role is "region", but can be substituted by ariaLabel
  role: MessageRole.REGION;
  ariaLabelledBy: string;
  ariaLabel?: never;
}

export type MessageProps =
  | MessageWithNonRegion
  | MessageWithRegionAriaLabel
  | MessageWithRegionAriaLabelledBy;
