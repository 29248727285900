import React, { ReactElement, forwardRef, useEffect, useRef } from "react";
import classNames from "classnames";
import { BadgeProps, BadgeStatus } from "./badge.types";
import { renderIconClassName } from "../utils";
import { IconStyle } from "../icon/icon.types";

export interface BadgeWithForwardRef
  extends React.ForwardRefExoticComponent<
    BadgeProps & React.RefAttributes<HTMLSpanElement>
  > {
  STATUS: typeof BadgeStatus;
}

export const Badge = forwardRef<
  HTMLSpanElement & HTMLButtonElement,
  BadgeProps
>((props, ref): ReactElement => {
  const {
    ariaLabel,
    className,
    iconName,
    iconStyle = IconStyle.FILL,
    label,
    light,
    notification,
    small,
    status,
    getRef,
    _privateProps = {},
    ...rest
  } = props;

  const { hasPopover, hasTooltip } = _privateProps;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    getRef?.(buttonRef.current);
    // Needed only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = classNames(
    { "spark-badge": status && !notification },
    { "spark-badge--sm": small },
    { [`spark-badge--${status}`]: status && !notification },
    { "spark-badge--notification": notification },
    {
      [`spark-badge--notification__${light ? "light" : status}`]: notification,
    },
    className
  );

  const iconClasses = classNames(
    `spark-icon spark-mar-r-1 ${renderIconClassName(iconName)}`,
    { "spark-icon--fill": iconStyle === IconStyle.FILL },
    { "spark-icon--line": iconStyle === IconStyle.LINE }
  );

  if (hasPopover) {
    return (
      <button
        type="button"
        ref={ref}
        onClick={props.onClick}
        className={classes}
        id={props.id}
        aria-label={ariaLabel}
        aria-expanded={props["aria-expanded"]}
      >
        {iconName ? <i className={iconClasses} aria-hidden="true" /> : null}
        {label}
      </button>
    );
  }

  return (
    <span
      className={classes}
      id={props.id}
      tabIndex={hasTooltip ? 0 : undefined}
      aria-label={ariaLabel}
      ref={ref}
      {...rest}
    >
      {iconName ? <i className={iconClasses} aria-hidden="true" /> : null}
      {label}
    </span>
  );
}) as BadgeWithForwardRef;

// Backwards compatibility to support component property. Enum import should be used instead.
Badge.STATUS = BadgeStatus;

Badge.displayName = "Badge";
