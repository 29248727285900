import React, { ReactElement, forwardRef } from "react";
import classNames from "classnames";
import { MessageStatus } from "../types";
import { RadioButtonGroupProps } from "./radio-button-group.types";

export interface RadioButtonGroupWithForwardRef
  extends React.ForwardRefExoticComponent<
    RadioButtonGroupProps & React.RefAttributes<HTMLFieldSetElement>
  > {
  STATUS: typeof MessageStatus;
}

export const RadioButtonGroup = forwardRef<
  HTMLFieldSetElement,
  RadioButtonGroupProps
>(
  (
    {
      className,
      messageClassName,
      errorMessage,
      name,
      onChange,
      status,
      statusMessage,
      value,
      children,
      ...rest
    },
    ref
  ): ReactElement => {
    const fieldSetClasses = classNames("spark-radio-group", className);

    const attrs = {
      className: fieldSetClasses,
    } as any;

    switch (status) {
      case MessageStatus.ERROR:
        attrs["data-error"] = "true";
        break;
      case MessageStatus.INFO:
        attrs["data-info"] = "true";
        break;
      case MessageStatus.SUCCESS:
        attrs["data-success"] = "true";
        break;
      case MessageStatus.WARNING:
        attrs["data-warning"] = "true";
        break;
      default:
        break;
    }

    const _children = React.Children.toArray(children).map((child, index) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      const childType: any = child.type;

      return childType.displayName === "RadioButton"
        ? React.cloneElement(child as ReactElement<any>, {
            name,
            onChange,
            checked:
              value !== undefined ? child.props.value === value : undefined,
            defaultChecked: child.props.defaultChecked,
            key: index,
            _privateProps: { isRadioGroup: true },
          })
        : child;
    });

    return (
      <fieldset {...attrs} {...rest} ref={ref}>
        {status && (
          <div className={messageClassName}>
            <span className="spark-radio-group__message">
              {statusMessage || errorMessage}
            </span>
          </div>
        )}
        {_children}
      </fieldset>
    );
  }
) as RadioButtonGroupWithForwardRef;

RadioButtonGroup.displayName = "RadioButtonGroup";

// Backwards compatibility to support component property. Enum import should be used instead.
RadioButtonGroup.STATUS = MessageStatus;
