import React, { PureComponent } from "react";
import { bool, func, node, oneOf, oneOfType, string } from "prop-types";
import classNames from "classnames";
import uniqueId from "lodash.uniqueid";
import { undefinedButHas } from "../utils/customPropTypes";
import {
  showSSRHydrationWarning,
  isPropEmpty,
} from "../utils/SSR/showSSRHydrationWarning";
import isServer from "../utils/SSR/isServer";

export class Modal extends PureComponent {
  constructor(props) {
    super(props);
    if (isServer()) {
      isPropEmpty(props.id) &&
        isPropEmpty(props.modalId) &&
        showSSRHydrationWarning("id or modalId", "Modal");
    }
    this.id = props.id || props.modalId || uniqueId("spark-modal_");
    this.headerId = `${this.id}_label`;
    this.nodeToRestore = React.createRef();
    this.backdropClick = null;
  }

  modalRef = React.createRef();

  onClose = () => {
    const { onBeforeClose, onClose } = this.props;
    onBeforeClose?.();
    onClose?.();
    this.nodeToRestore.current?.focus();
  };

  _focusModalItem = () => {
    this._parseFocusableElements();
    if (this.focusableElements && this.focusableElements.length > 0) {
      this.firstModalFocusableEl.focus();
    }
  };

  _onBackdropClick = (e) => {
    // Ignore the events not coming from the "backdrop".
    if (!this.backdropClick) {
      return;
    }

    if (this.props.disableBackdropClick) {
      return;
    }

    this.backdropClick = null;

    this.onClose();
  };

  _handleMouseDown = (e) => {
    // We don't want to close the dialog when clicking the dialog content.
    // Make sure the event starts and ends on the same DOM element.
    this.backdropClick =
      e.target === e.currentTarget ||
      e.target === this.modalRef.current.children[0];
  };

  _onKeyup = (e) => {
    if (e.key === "Escape" && !this.props.disableEscapeKeyDown) {
      this.onClose();
    }
  };

  _onKeydown = (e) => {
    if (e.key === "Tab") {
      if (this.focusableElements?.length === 1) {
        e.preventDefault();
      }
      if (e.shiftKey) {
        this._onBackwardTab(e);
      } else {
        this._onForwardTab(e);
      }
    }
  };

  _onBackwardTab(e) {
    if (document.activeElement === this.firstModalFocusableEl) {
      e.preventDefault();
      this.lastModalFocusableEl.focus();
    }
  }

  _onForwardTab(e) {
    if (document.activeElement === this.lastModalFocusableEl) {
      e.preventDefault();
      this.firstModalFocusableEl.focus();
    }
  }

  componentDidMount() {
    const { open } = this.props;
    this.modalRef.current.addEventListener("keyup", this._onKeyup);
    this.modalRef.current.addEventListener("keydown", this._onKeydown);
    if (open) {
      this._focusModalItem();
    }
  }

  componentWillUnmount() {
    this.modalRef.current.removeEventListener("keyup", this._onKeyup);
    this.modalRef.current.removeEventListener("keydown", this._onKeydown);
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { open, onBeforeOpen } = this.props;
    if (open && open !== prevProps.open) {
      onBeforeOpen?.();
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const { open, onAfterOpen, onAfterClose } = this.props;
    if (!prevProps.open && prevProps.open !== open) {
      document.body.classList.add("spark-modal-open");
      this.nodeToRestore.current = document.activeElement;
      this._focusModalItem();
      onAfterOpen?.();
    } else if (prevProps.open && prevProps.open !== open) {
      onAfterClose?.();
      document.body.classList.remove("spark-modal-open");
    }
  }

  _parseFocusableElements = () => {
    const modalFocusableEls = this.modalRef.current.querySelectorAll(
      'a[href]:not([tabindex^="-"]):not([disabled]), area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([tabindex^="-"]):not([disabled]), [tabindex]:not([tabindex^="-"]):not([disabled]), [tabindex="0"]'
    );
    if (modalFocusableEls.length > 0) {
      this.focusableElements = Array.prototype.slice.call(modalFocusableEls);
      this.firstModalFocusableEl = this.focusableElements[0];
      this.lastModalFocusableEl =
        this.focusableElements[this.focusableElements.length - 1];
    }
  };

  render() {
    const {
      ariaLabel,
      ariaDescribedBy,
      children,
      className,
      title,
      modalHeader,
      open,
      modalFooter,
      contentClassName,
      titleHeadingLevel,
      closeTitle,
      modalId,
      onBeforeOpen,
      onBeforeClose,
      onAfterOpen,
      onAfterClose,
      disableEscapeKeyDown,
      disableBackdropClick,
      ...rest
    } = this.props;
    const modalClass = classNames(
      "spark-modal",
      { active: open },
      { "spark-modal--fullscreen-xs": modalHeader },
      className
    );
    const modalBodyClasses = classNames("spark-modal__body ", {
      "spark-modal__body--snug-bottom": modalHeader,
    });

    const renderCloseButton = () => (
      <a
        role="button"
        onClick={this.onClose}
        tabIndex={0}
        className="spark-modal__close spark-icon-close"
        aria-label={closeTitle}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.onClose();
          }
        }}
      />
    );

    const renderHeading = () => {
      const HeadingTag = "h" + titleHeadingLevel;
      return title ? (
        <HeadingTag id={this.headerId} style={{ paddingRight: "2em" }}>
          {title}
        </HeadingTag>
      ) : null;
    };

    return (
      <div
        {...rest}
        id={this.id}
        ref={this.modalRef}
        className={modalClass}
        onClick={this._onBackdropClick}
        onMouseDown={this._handleMouseDown}
        tabIndex={-1}
      >
        <div className="spark-modal__scroll">
          <div
            className={classNames("spark-modal__content", contentClassName)}
            role="dialog"
            aria-modal={true}
            aria-labelledby={!ariaLabel && title ? this.headerId : undefined}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedBy}
          >
            {modalHeader ? (
              <div className="spark-modal__header">
                {renderCloseButton()}
                {renderHeading()}
                {modalHeader}
              </div>
            ) : null}
            <div className={modalBodyClasses}>
              {!modalHeader ? (
                <>
                  {renderCloseButton()}
                  {renderHeading()}
                </>
              ) : null}
              {children}
            </div>
            {modalFooter ? (
              <div className="spark-modal__footer">{modalFooter}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Modal.displayName = "Modal";

Modal.propTypes = {
  /**
   * Aria label for the dialog.
   * Either ariaLabel or title needs to be passed for accessibility.
   * <a href="https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal">See W3C Aria Practices for Modals</a>
   **/
  ariaLabel: oneOfType([string, undefinedButHas("title")]),

  /** Optional attribute to indicate the ID of the element that descibes the object */
  ariaDescribedBy: string,

  /**
   * Title for the component
   * Either ariaLabel or title needs to be passed for accessibility.
   * <a href="https://www.w3.org/TR/wai-aria-practices-1.1/#dialog_modal">See W3C Aria Practices for Modals</a>
   * */
  title: oneOfType([string, undefinedButHas("ariaLabel")]),

  /** Title for the close Icon */
  closeTitle: string,

  /** A callback function that closes the modal */
  onClose: func.isRequired,

  /** Boolean to show/hide the component */
  open: bool.isRequired,

  /** React children for the component */
  children: node.isRequired,

  /** Any extra CSS classes for the component */
  className: string,

  /** Additonal CSS classes to determine the size of the component or any custom class */
  contentClassName: string,

  /** Function to run before the component opens */
  onBeforeOpen: func,

  /** Function to run before the component closes */
  onBeforeClose: func,

  /** Function to run after the component opens */
  onAfterOpen: func,

  /** Function to run after the component closes */
  onAfterClose: func,

  /** Node to add a Header to the component */
  modalHeader: node,

  /** Node to add a Footer to the component */
  modalFooter: node,

  /** Title Heading Level **/
  titleHeadingLevel: oneOf([1, 2, 3, 4, 5, 6, "1", "2", "3", "4", "5", "6"]),

  /** Modal ID */
  id: string,

  /** **Deprecated** use `id` instead. */
  modalId: string,

  /** If true, escape key will not fire onClose callback */
  disableEscapeKeyDown: bool,

  /** If true, backdrop click will not fire onClose callback */
  disableBackdropClick: bool,
};

Modal.defaultProps = {
  modalFooter: null,
  modalHeader: null,
  open: false,
  contentClassName: "col-xs-12 col-sm-10 col-md-7 col-lg-6 col-xl-5",
  titleHeadingLevel: 4,
  closeTitle: "Close Modal",
};
