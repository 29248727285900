import React, {
  CompositionEvent,
  forwardRef,
  ReactElement,
  SyntheticEvent,
  useLayoutEffect,
  useRef,
} from "react";
import { useCombinedRefs } from "../useCombinedRefs";
import { TypeaheadProps } from "./typeahead.types";

const TypeAhead = forwardRef(
  (
    {
      name,
      type = "tel",
      autoComplete = "off",
      ariaLabel,
      value,
      placeholder,
      width,
      onChange,
      onBlur,
      onKeyDown,
      exitCallback,
      maxLength,
      isValidInputCharacter,
      ...rest
    }: TypeaheadProps,
    externalRef
  ): ReactElement => {
    const inpRef = useRef<HTMLInputElement>(null);
    const [selection, setSelection] = React.useState<
      [number | null, number | null] | null
    >(null);

    const isDelPressedRef = useRef(false);

    useCombinedRefs(inpRef, externalRef);

    const placeholderDynamic =
      "\xa0".repeat(value.length) +
      placeholder.substring(value.length, placeholder.length);

    /**
     * When a text input's value is modified outside the component,
     * the cursor always moves to the end of input.
     * This doesn't suit Typeahead while editing the value.
     * eg: input is full and enter a text at first position.
     * This useLayoutEffect uses the saved cursor position and
     * always overrides the cursor position
     */
    useLayoutEffect(() => {
      if (selection && inpRef.current) {
        [inpRef.current.selectionStart, inpRef.current.selectionEnd] =
          selection;
      }
    }, [selection]);

    const onChangeHandle = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      let targetVal = event.target.value;
      const selStart = event.currentTarget.selectionStart || 0;
      // save cursor position
      setSelection([event.target.selectionStart, event.target.selectionEnd]);

      // always truncate from last based on max length
      if (targetVal.length > maxLength) {
        targetVal = targetVal.slice(0, maxLength);
      }

      const willChange = onChange?.(event, targetVal);
      if (!willChange) {
        if (value.length === maxLength) {
          exitCallback(true, 1);
        }
        return;
      }

      if (isDelPressedRef.current) {
        // special use case for advanced typeaheads
        if (selStart === 0) {
          event.currentTarget.setSelectionRange(1, 1);
          return;
        }
      }
      setTimeout(() => {
        if (selStart === 0) {
          exitCallback(false);
        }
        if (selStart === maxLength) {
          exitCallback(true, 0);
        }
      });
    };

    const onKeyDownHandle = (
      event: React.KeyboardEvent<HTMLInputElement>
    ): void => {
      if (isDelPressedRef.current) {
        isDelPressedRef.current = false;
      }
      const targetVal = inpRef.current?.value || "";
      if (event.key === "ArrowLeft") {
        if (event.currentTarget.selectionStart === 0) {
          event.preventDefault();
          exitCallback(false, 1);
        }
      } else if (event.key === "ArrowRight") {
        if (event.currentTarget.selectionStart === targetVal.length) {
          event.preventDefault();
          exitCallback(true, 1);
        }
      } else if (event.key === "Backspace") {
        if (event.currentTarget.selectionStart === 0) {
          event.preventDefault();
          exitCallback(false);
        }
      } else if (event.key === "Delete") {
        isDelPressedRef.current = true;
        if (event.currentTarget.selectionStart === maxLength) {
          event.preventDefault();
          exitCallback(true, 0);
        }
      } else if (
        (event.key === "Home" || event.key === "End") &&
        value.length === maxLength
      ) {
        event.preventDefault();
        exitCallback(true, 1);
      }
      onKeyDown?.(event);
    };

    /**
     * Event to handle before the input character appears in the DOM
     * The handler prevents invalid characters to be
     * entered when the cursor is before the last character.
     * It passes the entered character to next input if user tried entering
     * text while cursor is at the last position
     * @param e
     * @param selStart
     */
    const onBeforeInputCaptureHandle = (
      e: SyntheticEvent,
      selStart: number | null
    ): void => {
      const event = e as CompositionEvent;
      const selectionStart = selStart || 0;
      // If cursor is at the end of input, prevent default action and skip to next typeahead with passing the entered character
      if (selectionStart === maxLength) {
        event.preventDefault();
        exitCallback(true, 1, event.data);
      } else {
        if (!isValidInputCharacter(event.data)) {
          event.preventDefault();
          if (value.length === maxLength) {
            exitCallback(true, 1);
          }
        }
      }
    };

    const onBlurHandle = (e: React.FocusEvent<HTMLInputElement>): void => {
      onBlur(e, e.target.value);
    };

    return (
      <span className="spark-input">
        <input
          {...rest}
          name={name}
          ref={inpRef}
          className="spark-input__field"
          data-typeahead=""
          type={type}
          autoComplete={autoComplete}
          aria-label={ariaLabel}
          value={value}
          style={{ width }}
          onChange={onChangeHandle}
          onKeyDown={onKeyDownHandle}
          onBlur={onBlurHandle}
          onBeforeInputCapture={(e): void =>
            onBeforeInputCaptureHandle(e, e.currentTarget.selectionStart)
          }
        />
        <span className="spark-input__placeholder">{placeholderDynamic}</span>
      </span>
    );
  }
);

TypeAhead.displayName = "TypeAhead";

export { TypeAhead };
