import { ToastType } from "./toast.types";

export const getToastTypeClassName = (toastType: ToastType): string => {
  switch (toastType) {
    case ToastType.WARNING:
      return "spark-toast--warning";
    case ToastType.POSITIVE:
      return "spark-toast--positive";
    default:
      return "spark-toast--neutral";
  }
};
