import classNames from "classnames";
import uniqueId from "lodash.uniqueid";
import React, {
  AnimationEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { TableRowProps } from "./table.types";
import { TableCell } from "./table-cell";
import {
  showSSRHydrationWarning,
  isPropEmpty,
} from "../utils/SSR/showSSRHydrationWarning";
import { useSSR } from "../utils/SSR/useSSR";
import { TableContext } from "./utils/tableContext";
import Checkbox from "../checkbox";

enum DrawerState {
  expanded,
  expand,
  collapsed,
  collapse,
}

const renderDrawerExpander = (
  rowId: string,
  drawerToggle: Function,
  isExpanded = false,
  disabled = false,
  ariaLabel = "Expand/Collapse"
): JSX.Element => {
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    drawerToggle?.();
  };

  const buttonClasses = classNames(
    "spark-btn--icon",
    "spark-icon spark-icon--fill",
    "spark-icon-arrow-chevron-down"
  );

  return (
    <button
      type="button"
      onClick={onClickHandler}
      data-drawer-handle={rowId}
      className={buttonClasses}
      aria-label={ariaLabel}
      aria-expanded={isExpanded}
      aria-controls={`drawer${rowId}`}
      disabled={disabled}
    ></button>
  );
};

export const TableRow = ({
  children,
  id: idInput,
  className,
  disabled,
  onClick,
  drawerRowProps,
  drawerExpanderCellProps,
  drawerContent,
  drawerIsExpandedInitState,
  onBeforeDrawerExpand,
  onDrawerExpand,
  onAfterDrawerExpand,
  onBeforeDrawerCollapse,
  onDrawerCollapse,
  onAfterDrawerCollapse,
  drawerExpanderAriaLabel,
  ...rest
}: TableRowProps): JSX.Element => {
  const { current: idUniqueInput } = useRef(uniqueId("tableRow_"));
  useSSR(
    () => isPropEmpty(idInput) && showSSRHydrationWarning("id", "TableRow")
  );

  const { enableSelectRow, rowSelectionPropsInternal } =
    useContext(TableContext);

  const {
    onSelectRowInternal,
    onSelectAllChkBoxChange,
    selectRowIds,
    isSelectAllChkBoxChecked,
    selectAllChkBoxLabel,
    selectRowChkBoxLabel,
  } = rowSelectionPropsInternal || {};

  const id = idInput ? idInput.toString() : idUniqueInput;

  const onRowClick = (e: React.MouseEvent<HTMLTableRowElement>): void => {
    if (
      e.target instanceof Element &&
      (e.target.classList.contains("spark-checkbox__box") ||
        e.target.nodeName === "INPUT" ||
        e.target.nodeName === "BUTTON")
    ) {
      return;
    }
    if (!disabled) {
      onClick?.(e, id);
      if (enableSelectRow && !isHeaderRow) {
        onSelectRowInternal?.(e, id);
      }
    }
  };

  const [drawerState, setDrawerState] = useState(
    drawerIsExpandedInitState ? DrawerState.expanded : DrawerState.collapsed
  );

  useEffect(() => {
    if (!drawerContent) {
      return;
    }
    if (drawerState === DrawerState.expand) {
      onDrawerExpand?.();
    } else if (drawerState === DrawerState.collapse) {
      onDrawerCollapse?.();
    }
  }, [drawerContent, drawerState, onDrawerCollapse, onDrawerExpand]);

  const _onAnimationEnd: AnimationEventHandler<HTMLDivElement> = (e) => {
    if (drawerState === DrawerState.expand) {
      onAfterDrawerExpand?.();
      setDrawerState(DrawerState.expanded);
    } else if (drawerState === DrawerState.collapse) {
      onAfterDrawerCollapse?.();
      setDrawerState(DrawerState.collapsed);
    }
  };

  const drawerClasses = classNames(
    "spark-table__drawer",
    `spark-table__drawer--${DrawerState[drawerState]}`,
    { [`${drawerRowProps?.className}`]: drawerRowProps?.className }
  );

  const toggleRowExpanded = (): void => {
    if (
      drawerState === DrawerState.expanded ||
      drawerState === DrawerState.expand
    ) {
      onBeforeDrawerCollapse?.();
      setDrawerState(DrawerState.collapse);
    } else if (
      drawerState === DrawerState.collapsed ||
      drawerState === DrawerState.collapse
    ) {
      onBeforeDrawerExpand?.();
      setDrawerState(DrawerState.expand);
    }
  };

  const renderDrawerRow = (): JSX.Element => (
    <tr
      {...drawerRowProps}
      id={`drawer${id}`}
      key={`drawer${id}`}
      className={drawerClasses}
      data-drawer={id}
    >
      <td colSpan={"100%" as any}>
        <div
          className="spark-table__drawer__content"
          onAnimationEnd={_onAnimationEnd}
        >
          {drawerContent}
        </div>
      </td>
    </tr>
  );

  const drawerExpanderCellClass = drawerExpanderCellProps?.className
    ? drawerExpanderCellProps?.className
    : "";

  const _children = React.Children.toArray(children);

  const childType: any =
    _children.length > 0 &&
    React.isValidElement(_children[0]) &&
    _children[0].type;

  const isHeaderRow = childType.displayName === "TableHeaderCell";

  const isRowSelected =
    enableSelectRow && !isHeaderRow && selectRowIds?.includes(id);

  const rowClasses = classNames(
    className,
    { "spark-table__disabled-row": disabled },
    {
      [`spark-table__handle-row--${DrawerState[drawerState]}`]: drawerContent,
    },
    {
      active: isRowSelected && !disabled,
    }
  );

  const selectAllCellClasses = classNames(
    "spark-table__checkbox",
    "spark-table__select-all"
  );

  const selectAllChkBoxClasses = classNames("spark-mar-b-0");

  const selectRowCellClasses = classNames("spark-table__checkbox");

  const selectRowChkBoxClasses = classNames("spark-mar-b-0");

  const renderRow = (): JSX.Element => (
    <tr id={id} key={id} className={rowClasses} {...rest} onClick={onRowClick}>
      {enableSelectRow && isHeaderRow ? (
        <TableCell scope="col" role="cell" className={selectAllCellClasses}>
          <Checkbox
            disabled={disabled}
            checked={isSelectAllChkBoxChecked}
            className={selectAllChkBoxClasses}
            showLabel={false}
            label={selectAllChkBoxLabel ?? "select all rows"}
            onChange={onSelectAllChkBoxChange}
          />
        </TableCell>
      ) : null}
      {enableSelectRow && !isHeaderRow ? (
        <TableCell role="cell" className={selectRowCellClasses}>
          <Checkbox
            checked={isRowSelected}
            disabled={disabled}
            className={selectRowChkBoxClasses}
            showLabel={false}
            label={selectRowChkBoxLabel ?? "select row"}
            onChange={(e): void => onSelectRowInternal?.(e, id)}
          />
        </TableCell>
      ) : null}
      {children}
      {drawerContent && (
        <TableCell
          {...drawerExpanderCellProps}
          className={drawerExpanderCellClass}
          align="center"
        >
          {renderDrawerExpander(
            id.toString(),
            toggleRowExpanded,
            drawerState === DrawerState.expanded ||
              drawerState === DrawerState.expand,
            disabled,
            drawerExpanderAriaLabel
          )}
        </TableCell>
      )}
    </tr>
  );

  return drawerContent ? (
    <>
      {renderRow()}
      {drawerContent && renderDrawerRow()}
    </>
  ) : (
    renderRow()
  );
};

TableRow.displayName = "TableRow";
