import React, { ReactElement } from "react";
import classNames from "classnames";
import { ToggleGroupProps } from "./toggle-group.types";

export const ToggleGroup = ({
  children,
  className,
  condensed,
  name,
  onChange,
  value,
  onClick,
  ...rest
}: ToggleGroupProps): ReactElement => {
  if (React.Children.count(children) < 2) {
    console.error(
      "You tried to use <ToggleGroup> with less than 2 children <Toggle> instances."
    );
  }

  const isControlled = !!value;
  const componentGroupClass = classNames("row spark-toggle-group", className);

  const childrenItems = React.Children.toArray(children).map(
    (child, key: number) => {
      return React.isValidElement(child)
        ? React.cloneElement(child as ReactElement<any>, {
            condensed,
            name,
            onChange,
            checked: isControlled ? value === child.props.value : undefined,
            onClick,
            key,
            _privateProps: {
              isToggleGroup: true,
            },
          })
        : null;
    }
  );

  return (
    <div className={componentGroupClass} {...rest}>
      {childrenItems}
    </div>
  );
};

ToggleGroup.displayName = "ToggleGroup";
