import React, { ReactElement, useState, useMemo, forwardRef } from "react";
import classNames from "classnames";
import { MessageStatus } from "../types";
import { CheckboxGroupProps } from "./checkbox-group.types";

export interface CheckboxGroupWithForwardRef
  extends React.ForwardRefExoticComponent<
    CheckboxGroupProps & React.RefAttributes<HTMLFieldSetElement>
  > {
  STATUS: typeof MessageStatus;
}

export const CheckboxGroup = forwardRef<
  HTMLFieldSetElement,
  CheckboxGroupProps
>(
  (
    {
      className,
      messageClassName,
      status,
      errorMessage,
      statusMessage,
      name,
      value: valueProp,
      onChange,
      children,
    },
    ref
  ): ReactElement => {
    const findDefaultChecked = useMemo((): string[] => {
      return React.Children.toArray(children)
        .map((child) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return child.props.defaultChecked === true
            ? child.props.value
            : undefined;
        })
        .filter((value) => value !== null && value !== undefined);
    }, [children]);

    const [valueState, setValueState] = useState<string[]>(findDefaultChecked);

    const isControlled = !!valueProp;
    const value = isControlled ? valueProp : valueState;

    const _onChangeHandler = (
      e: React.ChangeEvent<HTMLInputElement>,
      clickedItemValue: string
    ): void => {
      let newActiveItems = [];

      if (e.currentTarget.checked) {
        newActiveItems = [...Array.from(new Set([...value, clickedItemValue]))];
      } else {
        newActiveItems = value?.filter((item) => item !== clickedItemValue);
      }

      setValueState(newActiveItems);

      if (onChange) {
        /**
         * Redefine target to allow name and value to be read.
         * This allows integration with popular form libraries.
         * Clone the event to not override `target` of the original event.
         */
        const nativeEvent = e.nativeEvent || e;
        const clonedEvent = new (nativeEvent as any).constructor(
          nativeEvent.type,
          nativeEvent
        );

        Object.defineProperty(clonedEvent, "target", {
          writable: true,
          value: { value: newActiveItems, name },
        });
        onChange(clonedEvent, newActiveItems);
      }
    };

    const fieldSetClasses = classNames("spark-checkbox-group", className);
    const attrs = {
      className: fieldSetClasses,
    } as any;

    switch (status) {
      case MessageStatus.ERROR:
        attrs["data-error"] = "true";
        break;
      case MessageStatus.WARNING:
        attrs["data-warning"] = "true";
        break;
      case MessageStatus.SUCCESS:
        attrs["data-success"] = "true";
        break;
      case MessageStatus.INFO:
        attrs["data-info"] = "true";
        break;
      default:
        break;
    }

    const _children = React.Children.toArray(children).map((child) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      const childType: any = child.type;

      return childType.displayName === "Checkbox"
        ? React.cloneElement(child as ReactElement<any>, {
            checked: isControlled
              ? value.includes(child.props.value)
              : undefined,
            defaultChecked: isControlled
              ? undefined
              : child.props.defaultChecked,
            name: child.props.name || name,
            onChange: _onChangeHandler,
          })
        : child;
    });

    return (
      <fieldset {...attrs} ref={ref}>
        {status && (
          <div className={messageClassName}>
            <span className="spark-checkbox-group__message">
              {errorMessage || statusMessage}
            </span>
          </div>
        )}
        {_children}
      </fieldset>
    );
  }
) as CheckboxGroupWithForwardRef;

CheckboxGroup.displayName = "CheckboxGroup";

// Backwards compatibility to support component property. Enum import should be used instead.
CheckboxGroup.STATUS = MessageStatus;
