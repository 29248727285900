import { MessageStatus } from "../../../types";

export enum InputGroupActionType {
  HOIST_MESSAGE = "HOIST_MESSAGE",
}

interface HoistMessageAction {
  type: InputGroupActionType.HOIST_MESSAGE;
  label?: string;
  status?: MessageStatus | "success" | "warning" | "error" | "info";
  statusMessage?: string;
}

export type InputGroupAction = HoistMessageAction;
