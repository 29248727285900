/* eslint-disable lines-around-comment */
import React, { LegacyRef } from "react";

export interface SliderMultipleRangeObject {
  min: number;
  max: number;
  value: number;
  activeThumb?: number;
}

export interface SliderMultipleRangeProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "onChange"> {
  /**
   * Text displayed above the Slider, for accessibility
   */
  label: string;

  /**
   * Required in controlled variants, sets the value of the middle button in slider
   */
  value?: number;

  /**
   * Required in controlled variants, sets the value of the first button in slider
   */
  minValue?: number;

  /**
   * Required in controlled variants, sets the value of the last button in slider
   */
  maxValue?: number;

  /**
   * Required in uncontrolled variants only, sets the value of the first button in slider
   */
  defaultMinValue?: number;

  /**
   * Required in uncontrolled variants only, sets the value of the last button in slider
   */
  defaultMaxValue?: number;

  /**
   * Required in uncontrolled variants, sets the value of the middle button in slider
   */
  defaultValue?: number;

  /**
   * The minimum allowed value of the slider
   */
  minRange: number;

  /**
   * The maximum allowed value of the slider
   */
  maxRange: number;

  /**
   * Name attribute of the middle input element
   */
  valueInputName?: string;

  /**
   * Name attribute of the first input element
   */
  minValueInputName?: string;

  /**
   * Name attribute of the last input element
   */
  maxValueInputName?: string;

  /**
   * Title attribute of the first input element
   */
  startTitle?: string;

  /**
   * Title attribute of the last input element
   */
  endTitle?: string;

  /**
   * Title attribute of the middle input element
   */
  middleTitle?: string;

  /**
   * A value that steps through the Slider in increments
   */
  step?: string;

  /** If `true`, `SliderMultiRange` will be disabled */
  disabled?: boolean;

  /**
   * onChange hook to get the changed values from slider
   */
  onChange?: ({ min, max, value }: SliderMultipleRangeObject) => void;

  /**
   * Variant with muted styling
   */
  alternate?: boolean;
}

export const defaultSliderMultipleRangeProps = {
  startTitle: "Start",
  endTitle: "End",
  middleTitle: "Middle",
  step: "1",
  disabled: false,
  valueInputName: "value",
  minValueInputName: "minValue",
  maxValueInputName: "maxValue",
  alternate: false,
};

export enum ButtonTypesMultiRange {
  START_BUTTON = 0,
  MIDDLE_BUTTON = 1,
  END_BUTTON = 2,
}

export type RefsMultiRange = {
  minValueRef: LegacyRef<HTMLInputElement>;
  midValueRef: LegacyRef<HTMLInputElement>;
  maxValueRef: LegacyRef<HTMLInputElement>;
};
