import React from "react";
import { TableBodyProps } from "./table.types";

export const TableBody = ({
  children,
  className,
  ...rest
}: TableBodyProps): JSX.Element => {
  return (
    <tbody className={className} {...rest}>
      {children}
    </tbody>
  );
};

TableBody.displayName = "TableBody";
