import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { ElementOrNull, ToastProps } from "./toast.types";
import { ToastBody } from "./toastBody";
import uniqueId from "lodash.uniqueid";
import { useToast } from "./useToast";
import isServer from "../utils/SSR/isServer";

export const Toast = ({
  open = false,
  onClose,
  type,
  title,
  details,
  icon,
  duration,
  actionIcon,
  actionLabel,
  el,
  elHeader,
  className,
  makeToastElRef,
  portalWrapperClassName,
}: ToastProps): React.ReactElement | null => {
  const [toastWrapperId] = useState(uniqueId("toast-wrapper-"));
  const renderToElem: ElementOrNull = el || null;
  const [openToast, setOpenToast] = useState<boolean | number | string>(open);

  useEffect(() => {
    setOpenToast(open);
    return () => {
      // Remove the toast wrapper, clean up activity
      document
        .querySelectorAll(`.spark-toast-root.${toastWrapperId}`)
        ?.forEach((item) => item.remove());
    };
  }, [open, toastWrapperId]);

  // Return null for server side rendering
  if (isServer()) {
    return null;
  }

  // Create a div which will be container for the toast element
  const toastWrapper = document.createElement("div");
  const _portalWrapperClassName = portalWrapperClassName
    ? ` ${portalWrapperClassName}`
    : "";
  toastWrapper.className =
    "spark-toast-root " + toastWrapperId + _portalWrapperClassName;

  if (!openToast) {
    return null;
  }

  if (renderToElem === null) {
    open &&
      console.warn(
        `Toast: el prop is invalid, el must be a valid querySelector`
      );
    return null;
  }

  let otherProps = {};
  const renderOffsetElem: ElementOrNull = elHeader || null;
  const wrapperChildNode = renderToElem.children[0];
  renderToElem.insertBefore(toastWrapper, wrapperChildNode);
  otherProps = {
    elOffset: renderToElem?.getBoundingClientRect(),
    elHeaderOffset: renderOffsetElem?.getBoundingClientRect(),
  };
  // Using portal to render the component at a specific location in the DOM. Similar to appendChild
  return ReactDOM.createPortal(
    <ToastBody
      duration={duration}
      onClose={onClose}
      title={title}
      type={type}
      el={el}
      elHeader={elHeader}
      details={details}
      icon={icon}
      actionIcon={actionIcon}
      actionLabel={actionLabel}
      className={className}
      makeToastElRef={makeToastElRef}
      {...otherProps}
    />,
    toastWrapper
  );
};

Toast.displayName = "Toast";

export { useToast };
