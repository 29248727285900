import React, {
  useState,
  useRef,
  ReactElement,
  useMemo,
  useEffect,
} from "react";
import classNames from "classnames";
import uniqueId from "lodash.uniqueid";
import isEqual from "lodash.isequal";
import { AccordionProps } from "./accordion.types";
import { PrivateProps } from "./drawer.types";
import {
  showSSRHydrationWarning,
  isPropEmpty,
} from "../utils/SSR/showSSRHydrationWarning";
import { useSSR } from "../utils/SSR/useSSR";

export const Accordion = ({
  children,
  singleExpand,
  className,
  rtl,
  showRadioButton,
  activeDrawerIds,
  onChange,
  id,
  ...rest
}: AccordionProps): ReactElement => {
  const accordionInstance = useRef<HTMLDivElement>(null);
  const animationInProgress = useRef<boolean>(false);
  useSSR(() => isPropEmpty(id) && showSSRHydrationWarning("id", "Accordion"));
  const { current: _uniqueAccordionId } = useRef(uniqueId("accordion_"));
  const uniqueAccordionId = id || _uniqueAccordionId;
  const findDefaultOpen = useMemo((): string[] => {
    return React.Children.toArray(children)
      .map((child, index) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return child.props.expanded === true
          ? child.props.id ?? `${uniqueAccordionId}-drawer-${index}`
          : undefined;
      })
      .filter((value) => value !== null && value !== undefined) as string[];
  }, [children, uniqueAccordionId]);

  const isControlled = !!activeDrawerIds;

  const [valueState, setValueState] = useState<string[]>(
    isControlled ? activeDrawerIds : findDefaultOpen
  );

  // CSS selectors do not support the strings generated from the useId hook, hence the use of CSS.escape()
  const escapeId = (id: string): string => CSS.escape(id);

  const value = isControlled ? activeDrawerIds : valueState;

  /**
   * Controlled variant helper
   * Handles drawers height when change is triggered by external controls
   * Otherwise a white empty space is displayed in place of the content
   */
  useEffect(() => {
    if (isControlled && !isEqual(valueState, activeDrawerIds)) {
      setValueState(activeDrawerIds);
      const allContents = accordionInstance.current?.querySelectorAll(
        ".spark-accordion__content"
      );
      allContents?.forEach((element) => {
        const drawerContent = element as HTMLElement;

        if (!activeDrawerIds?.includes(element.id.replace("-content", ""))) {
          drawerContent.style.height = "0px";
          drawerContent.style.visibility = "hidden";
        } else {
          drawerContent.style.height = "auto";
          drawerContent.style.visibility = "visible";
        }
      });
    }
  }, [activeDrawerIds, isControlled, valueState]);

  const expand = (drawer: string): Promise<void> => {
    return new Promise((resolve) => {
      const control = accordionInstance.current?.querySelector(
        `.spark-accordion__header[aria-controls="${escapeId(drawer)}-content"]`
      );
      const content: HTMLElement | undefined | null =
        accordionInstance.current?.querySelector(
          `#${escapeId(drawer)}-content`
        );

      if (content && control) {
        animationInProgress.current = true;
        const height = Math.round(content.scrollHeight);

        control.setAttribute("aria-expanded", "true");
        content.style.visibility = "visible";
        content.style.height = height + "px";

        content.addEventListener("transitionend", function onExpand() {
          content.removeEventListener("transitionend", onExpand);
          content.style.height = "auto";
          resolve();
          animationInProgress.current = false;
        });
      }
    });
  };

  const collapse = (drawer: string): Promise<void> => {
    return new Promise((resolve) => {
      const control = accordionInstance.current?.querySelector(
        `.spark-accordion__header[aria-controls="${escapeId(drawer)}-content"]`
      );
      const content: HTMLElement | undefined | null =
        accordionInstance.current?.querySelector(
          `#${escapeId(drawer)}-content`
        );

      if (content && control) {
        animationInProgress.current = true;
        const height = content?.scrollHeight;
        const transition = content?.style.transition;

        content.style.visibility = "visible";
        content.style.transition = "";

        content.addEventListener("transitionend", function onCollapse() {
          content.removeEventListener("transitionend", onCollapse);
          control.setAttribute("aria-expanded", "false");
          content.style.visibility = "hidden";
          animationInProgress.current = false;
        });

        requestAnimationFrame(() => {
          // Define height explicitly, because transitions on "auto" won't visually animate
          content.style.height = height + "px";

          // trigger another transition
          content.style.transition = transition;

          requestAnimationFrame(() => {
            // set height to 0, so that the transition may animate from the fixed height to 0
            content.style.height = 0 + "px";
          });

          control.setAttribute("aria-expanded", "false");
          resolve();
        });
      }
    });
  };

  const toggle = (drawer: string): void => {
    if ((singleExpand || showRadioButton) && value.length > 0) {
      value.map((item) => {
        if (drawer !== item) {
          collapse(item);
        }
        return false;
      });
    }

    if (value.includes(drawer)) {
      collapse(drawer);
    } else {
      expand(drawer);
    }
  };

  const _onClick: PrivateProps["onClick"] = (event) => {
    if (!animationInProgress.current) {
      const eventTarget = event.currentTarget as HTMLElement;

      let newActiveItems = [];

      if (value?.includes(eventTarget.id)) {
        if (showRadioButton) {
          // Drawer already open, do not do anything (only for accordion with radio)
          return;
        }
        newActiveItems = value?.filter((item) => item !== eventTarget.id);
      } else {
        newActiveItems =
          singleExpand || showRadioButton
            ? [eventTarget.id]
            : [...Array.from(new Set([...value, eventTarget.id]))];
      }

      toggle(eventTarget.id);

      setValueState(newActiveItems);
      onChange?.(event, newActiveItems);
    }
  };

  let dir;
  if (rtl === true) {
    dir = "rtl";
  }
  const classes = classNames(className, {
    "spark-accordion--radio": showRadioButton,
    "spark-accordion": !showRadioButton,
  });

  const drawers = React.Children.toArray(children).map((child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const childType: any = child.type;
    const childId = child.props.id ?? `${uniqueAccordionId}-drawer-${index}`;

    return childType.displayName === "Drawer"
      ? React.cloneElement(child as ReactElement<any>, {
          showRadioButton,
          id: childId,
          _privateProps: {
            accordionId: uniqueAccordionId,
            isExpanded: value?.includes(childId),
            onClick: _onClick,
            accordionSet: true,
            rtl,
          },
        })
      : child;
  });

  return (
    <div ref={accordionInstance} className={classes} dir={dir} {...rest}>
      {drawers}
    </div>
  );
};
