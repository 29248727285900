import { useRef } from "react";
import isServer from "./isServer";

export const useSSR = (callback: Function): void => {
  const isSSRRef = useRef<null | boolean>(null);
  if (isSSRRef.current === null) {
    const res = isServer();

    isSSRRef.current = res;
  }
  if (isSSRRef.current) {
    callback();
  }
};
