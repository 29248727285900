import React, { forwardRef, ReactElement, useRef } from "react";
import { TypeAhead } from "../utils/typeahead/typeahead";
import { TypeaheadProps } from "../utils/typeahead/typeahead.types";
import {
  getValidPeriod,
  isValidPeriod,
  TIME_PERIOD_AM_STRING,
  TIME_PERIOD_PM_STRING,
} from "./time-selection.util";
import { TimePeriod } from "./types/time-text-input.types";

interface PeriodTypeaheadProps
  extends Omit<TypeaheadProps, "width" | "onChange" | "isValidInputCharacter"> {
  /** A callback function run when the input value changes. **/
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const PeriodTypeahead = forwardRef(
  (
    {
      value: period,
      onChange,
      onBlur,
      ariaLabel,
      name,
      ...rest
    }: PeriodTypeaheadProps,
    externalRef
  ): ReactElement => {
    const cursorPosOnDel = useRef(-1);

    const onChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>,
      val: string
    ): boolean => {
      if (cursorPosOnDel.current >= 0) {
        onChange(e, val.slice(0, cursorPosOnDel.current));
        return true;
      }
      if (isValidPeriod(val)) {
        let validPeriod = val;
        if (val === TIME_PERIOD_AM_STRING) {
          validPeriod = "a.m";
        } else if (val === TIME_PERIOD_PM_STRING) {
          validPeriod = "p.m";
        }
        onChange(e, validPeriod.toLowerCase());
        return true;
      } else {
        if (
          (period === TimePeriod.AM || period === TimePeriod.PM) &&
          e.target.selectionStart === 1 &&
          (val[0].toLowerCase() === "a" || val[0].toLowerCase() === "p")
        ) {
          onChange(e, val[0].toLowerCase() + period.slice(1));
          return true;
        }
      }
      return false;
    };

    const onBlurHandle = (
      event: React.FocusEvent<HTMLInputElement>,
      val: string
    ): void => {
      if (!val) {
        onBlur(event, "");
      } else {
        const validPeriod = getValidPeriod(val);
        if (validPeriod !== false && typeof validPeriod === "string") {
          onChange(event, validPeriod);
          onBlur(event, validPeriod);
        }
      }
    };

    const onKeyDownHandle = (
      event: React.KeyboardEvent<HTMLInputElement>
    ): void => {
      const start = event.currentTarget.selectionStart;
      if (event.key === "Backspace") {
        cursorPosOnDel.current = (start || -1) - 1;
      } else if (event.key === "Delete") {
        cursorPosOnDel.current = !start && start !== 0 ? -1 : start;
      } else {
        cursorPosOnDel.current = -1;
      }
    };

    return (
      <TypeAhead
        {...rest}
        ref={externalRef}
        name={name}
        ariaLabel={ariaLabel}
        width={40}
        value={period}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
        onKeyDown={onKeyDownHandle}
        isValidInputCharacter={(character): boolean =>
          ["a", "p", ".", "m"].includes(character.toLowerCase())
        }
      />
    );
  }
);

PeriodTypeahead.displayName = "PeriodTypeahead";

export { PeriodTypeahead };
