export enum ProgressIndicatorSize {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  LARGE = "lg",
}

export enum ProgressIndicatorType {
  DETERMINATE = "determinate",
  INDETERMINATE = "indeterminate",
}

export interface ProgressIndicatorProps {
  /** Any extra CSS classes for the component */
  className?: string;

  /** Percent complete [0 - 1] */
  percentComplete?: number;

  /** Progress Indicator text string */
  progressText?: string;

  /** How many decimal places to render in the percentage */
  precision?: number;

  /** Size of the Progress Indicator */
  size?: ProgressIndicatorSize | "xs" | "sm" | "lg";

  /**
   * An array of Objects that contain different messages based on percent complete
   * const stages = [
      { value: 0, progressText: "Applying Policy Rules" },
      { value: 20, progressText: "Sorting Options" },
      { ... },
      { ... },
      { value: 100, progressText: "All done!" }
    ];
  */
  stages?: Array<{
    value: number;
    progressText: string;
  }>;

  /**
 * SVG configuration for drawing out the progress indicator:
 *  lg: {
      diameter: 264,
      stroke: 12,
      track: 'M132 6c69.588 0 126 56.412 126 126s-56.412 126-126 126S6 201.588 6 132 62.412 6 132 6z',
      fill: '',
      border: 'M132 252c-66.274 0-120-53.726-120-120S65.726 12 132 12s120 53.726 120 120-53.726 120-120 120z'
    },
    sm: { ... },
    xs: { ... }
 */
  svgConfigs?: {
    lg: {
      diameter: number;
      stroke: number;
      track: string;
      fill: string;
      border: string;
    };
    sm: {
      diameter: number;
      stroke: number;
      track: string;
      fill: string;
      border: string;
    };
    xs: {
      diameter: number;
      stroke: number;
      track: string;
      fill: string;
      border: string;
    };
  };

  /** Progress Indicator type */
  type?: ProgressIndicatorType | "determinate" | "indeterminate";
}
