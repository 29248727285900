import { MutableRefObject, RefCallback, useEffect } from "react";

// The handling of joining external and internal refs can also be solved with useImperativeHandle hook:
// https://medium.com/vimeo-engineering-blog/handling-internal-and-external-refs-to-the-same-element-with-useimperativehandle-in-react-746ff6d377fe

export const useCombinedRefs = <T>(
  localRef: MutableRefObject<T>,
  externalRef: MutableRefObject<T> | RefCallback<T> | null
): void => {
  useEffect(() => {
    if (!externalRef) return;

    if (typeof externalRef === "function") {
      externalRef(localRef.current);
    } else {
      externalRef.current = localRef.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalRef]);
};
