import classNames from "classnames";
import React, { useRef, useState } from "react";
import useColumnResize from "../utils/dom/useColumnResize";
import { useCombinedRefs } from "../utils/useCombinedRefs";
import { TableProps } from "./table.types";
import { TableContext } from "./utils/tableContext";

const renderHeading = (title: string): JSX.Element => (
  <header className="spark-table__header">
    <h4 className="spark-table__title">{title}</h4>
  </header>
);

/*
 * Forcing type assertion for a component due issue with component
 * type being generated as `any` in d.ts file when forwardRef is used.
 * Probably it could be fixed with new ts-loader added (webpack 5 needed)
 */
export interface TableWithForwardRef
  extends React.ForwardRefExoticComponent<
    TableProps & React.RefAttributes<HTMLTableElement>
  > {}

export const Table = React.forwardRef(
  (
    {
      title,
      children,
      condensed,
      condensedLargerFont,
      className,
      dataPanelBreakpoint,
      isResizable = false,
      isRowToPanel,
      rowSelectionProps,
      ...rest
    }: TableProps,
    tableRef
  ): JSX.Element => {
    const tableNode = useRef<HTMLTableElement>(null);
    useCombinedRefs(tableNode, tableRef);

    const {
      onSelectRow,
      onChangeSelectAllRows,
      selectRowIds = [],
      selectAllChkBoxLabel,
      selectRowChkBoxLabel,
    } = rowSelectionProps ?? {};

    const [_isSelectAllChkBoxChecked, setIsSelectAllChkBoxChecked] =
      useState(false);

    const tableWrapperClasses = classNames(
      "spark-table",
      {
        "spark-table--condensed": condensed || condensedLargerFont,
        "spark-table--condensed--large-font": condensedLargerFont,
        "spark-table--resizable": isResizable,
        "spark-table--row-to-panel": isRowToPanel,
      },
      className
    );

    const dataPanelBreakpointDivAttrs = dataPanelBreakpoint
      ? { [`data-panel-breakpoint-${dataPanelBreakpoint}`]: true }
      : {};

    const dataPanelBreakpointTableAttrs = dataPanelBreakpoint
      ? { "data-panel-breakpoint": dataPanelBreakpoint }
      : {};

    useColumnResize(tableNode, isResizable);

    const onSelectAllChkBoxChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setIsSelectAllChkBoxChecked(e.target.checked);
      onChangeSelectAllRows?.(e);
    };

    const onRowSelectHandle = (
      e:
        | React.MouseEvent<HTMLTableRowElement>
        | React.ChangeEvent<HTMLInputElement>,
      rowId?: string
    ): void => {
      if (rowId) {
        let resultIds: string[] = [];
        if (selectRowIds.includes(rowId)) {
          resultIds = selectRowIds.filter((e) => e !== rowId);
          setIsSelectAllChkBoxChecked(false);
        } else {
          resultIds = [...selectRowIds, rowId];
        }
        onSelectRow?.(e, resultIds);
      }
    };

    return (
      <section className={tableWrapperClasses}>
        {title && renderHeading(title)}
        <div className="spark-table__scroll" {...dataPanelBreakpointDivAttrs}>
          <table {...rest} ref={tableNode} {...dataPanelBreakpointTableAttrs}>
            <TableContext.Provider
              value={{
                isResizable: isResizable,
                enableSelectRow: !!rowSelectionProps,
                rowSelectionPropsInternal: {
                  onSelectRowInternal: onRowSelectHandle,
                  onSelectAllChkBoxChange: onSelectAllChkBoxChangeHandle,
                  selectRowIds,
                  isSelectAllChkBoxChecked: _isSelectAllChkBoxChecked,
                  selectAllChkBoxLabel,
                  selectRowChkBoxLabel,
                },
              }}
            >
              {children}
            </TableContext.Provider>
          </table>
        </div>
      </section>
    );
  }
) as TableWithForwardRef;

Table.displayName = "Table";
