import React from "react";
import { isLeapYear } from "../../calendar/calendar.util";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getValidDaysInMonth = (
  dateValue: string,
  monthValue: string,
  yearValue: string
): string => {
  if (!dateValue) {
    return "";
  }
  if (!monthValue || !yearValue) {
    return dateValue;
  }
  let maxDaysAllowed = "";
  switch (parseInt(monthValue)) {
    case 4:
    case 6:
    case 9:
    case 11:
      maxDaysAllowed = "30";
      break;
    case 2:
      maxDaysAllowed = isLeapYear(parseInt(yearValue)) ? "29" : "28";
      break;
    default:
      maxDaysAllowed = "31";
      break;
  }
  if (dateValue > maxDaysAllowed) {
    return maxDaysAllowed;
  }

  return dateValue;
};

export const getValidDaysInMonthFromRefs = (
  inputDdRef: React.MutableRefObject<HTMLInputElement>,
  inputMmRef: React.MutableRefObject<HTMLInputElement>,
  inputYyyyRef: React.MutableRefObject<HTMLInputElement>
): string => {
  const dateValue = inputDdRef?.current.value;
  const monthValue = inputMmRef?.current.value;
  const yearValue = inputYyyyRef?.current.value;
  return getValidDaysInMonth(dateValue, monthValue, yearValue);
};

export const getDateWithMonthNameText = (
  month: string | null,
  year: string | null,
  day: string | null
): string => {
  const monthNum = parseInt(month || "", 10);
  if (monthNames[monthNum - 1] && day && year) {
    return `${monthNames[monthNum - 1]} ${day} ${year}`;
  }
  return "";
};

export const NOOP = (): void => {};

export const makeDateHtmlValue = (
  year: string | null,
  month: string | null,
  day: string | null
): string => {
  const value = `${year}${month ? "-" + month?.padStart(2, "0") : ""}${
    day ? "-" + day?.padStart(2, "0") : ""
  }`;

  return value === "null" ? "" : value;
};
