import isServer from "./isServer";

export const isPropEmpty = (prop?: string): boolean =>
  prop === undefined || prop === "";

export const showSSRHydrationWarning = (
  propNamesString: string,
  componentName: string
): void => {
  if (isServer() && propNamesString !== "" && componentName !== "") {
    console.warn(
      `
    \x1b[41m\x1b[1m Spark Warning: \x1b[0m\x1b[7m Please pass '${propNamesString}' to '${componentName}' to avoid possible hydration errors while server side rendering \x1b[0m
    `
    );
  }
};
