import React from "react";

// Take the string version of the number from the input event,
// Then split the string based on decimal point. Use the precision number to limit the number after decimal point.
export const restrictPrecisionNum = (
  e: React.ChangeEvent<HTMLInputElement>,
  precision: number
): string | undefined => {
  const val = e.target.value;

  if (val === undefined || val === "") {
    return undefined;
  }

  const numSplits = val.split(".");
  if (numSplits[1]) {
    const decimalPart = numSplits[1].substring(0, precision);
    return [numSplits[0], decimalPart].join(".");
  }

  return parseInt(numSplits[0]).toFixed(precision);
};

export const formatCurrency = (val: string): string =>
  val?.replace(/[^\d.-]/g, "");
