import classNames from "classnames";
import {
  bool,
  string,
  func,
  oneOf,
  oneOfType,
  arrayOf,
  shape,
  node,
} from "prop-types";
import uniqueId from "lodash.uniqueid";
import React, { useCallback, useState, useRef } from "react";
import Tooltip from "../tooltip";
import {
  ButtonSize,
  TooltipDirectionX,
  TooltipDirectionY,
} from "./button.types";
import Popover from "../popover";
import { renderIconClassName } from "../utils";
import {
  showSSRHydrationWarning,
  isPropEmpty,
} from "../utils/SSR/showSSRHydrationWarning";
import { useSSR } from "../utils/SSR/useSSR";

const WithBDO = (Component, dir = "rtl") => <bdo dir={dir}>{Component}</bdo>;

const ProgressButton = React.forwardRef(
  ({ onClick, className, progressSuccessIcon, onComplete, ...props }, ref) => {
    const [cssClass, setCssClass] = useState(className);
    const isSuccessRef = useRef(false);

    const handleTransitionEnd = useCallback(() => {
      if (isSuccessRef.current === true) {
        isSuccessRef.current = false;
        setCssClass(className);
        onComplete && onComplete();
      }
    }, [className, onComplete]);

    const handleProgressSuccess = useCallback(() => {
      isSuccessRef.current = true;
      setCssClass(`${className} spark-btn--active spark-btn--complete`);
    }, [className]);

    const handleProgressFailure = useCallback(() => {
      setCssClass(className);
      onComplete && onComplete();
    }, [className, onComplete]);

    const handleOnClick = useCallback(
      (e) => {
        if (onClick) {
          setCssClass(`${className} spark-btn--active`);
          onClick(e, handleProgressSuccess, handleProgressFailure);
        }
      },
      [className, onClick, handleProgressSuccess, handleProgressFailure]
    );

    return (
      <button {...props} onClick={handleOnClick} className={cssClass} ref={ref}>
        <progress />
        <span className="spark-progress__meter" />
        <i
          aria-hidden="true"
          className={`spark-btn__icon spark-icon ${progressSuccessIcon} spark-icon--fill`}
          data-type="success"
        />
        <span
          className="spark-btn__label"
          onTransitionEnd={handleTransitionEnd}
        >
          {props.children}
        </span>
      </button>
    );
  }
);

ProgressButton.displayName = "ProgressButton";

export const Button = React.forwardRef(
  (
    {
      id,
      children,
      size,
      onClick,
      secondary,
      brand,
      negative,
      textOnly,
      icon,
      multiAction,
      combo,
      contentCloseElQuery,
      actionLinks,
      popoverChildren,
      progress,
      progressSuccessIcon,
      tooltip,
      tooltipDirectionX,
      tooltipDirectionY,
      className,
      popoverClassName,
      comboBoxContainerClassName,
      testId,
      ariaLabel,
      rtl,
      fullWidth,
      popoverId,
      ...rest
    },
    ref
  ) => {
    useSSR(() => {
      if (multiAction || combo) {
        (isPropEmpty(id) || isPropEmpty(popoverId)) &&
          showSSRHydrationWarning(
            "id & popoverId",
            (multiAction ? "Multi-Action " : "Combo ") + "Button"
          );
      } else {
        isPropEmpty(id) && showSSRHydrationWarning("id", "Button");
      }
    });

    const isNotIconBrandTextOnly = !icon && !brand && !textOnly;
    const isMultiAction = isNotIconBrandTextOnly && !combo && multiAction;
    const isCombo = !isMultiAction && combo;

    const { current: uniqueButtonId } = useRef(uniqueId("button_"));
    const buttonId = id || uniqueButtonId;
    const tooltipId = `${buttonId}-tooltip`;

    const buttonClasses = classNames(
      { "spark-btn": !icon },
      { [`spark-btn--${size}`]: !icon && !brand && !textOnly },
      { "spark-progress": progress && !icon },
      { "spark-btn--secondary": secondary },
      { "spark-btn--multi-action": isMultiAction },
      { "spark-btn--brand": brand },
      { "spark-btn--text": textOnly },
      { "spark-btn--lg": brand },
      { "spark-btn--negative": negative },
      { "spark-btn--icon spark-icon spark-tooltip": icon },
      { [`${renderIconClassName(icon)}`]: icon },
      className
    );

    const conditionalAttributes = {};

    if (icon) {
      conditionalAttributes["aria-label"] = ariaLabel;
    }

    const fullWidthStyle =
      fullWidth && !icon && !textOnly ? { width: "100%" } : {};

    const renderButton = (
      buttonChildren = children,
      cssClassNames = buttonClasses
    ) => (
      <button
        id={buttonId}
        data-testid={testId}
        onClick={onClick}
        className={cssClassNames}
        {...conditionalAttributes}
        {...rest}
        style={{ ...fullWidthStyle, ...rest.style }}
        ref={ref}
      >
        {!icon && buttonChildren}
      </button>
    );

    const renderTooltip = () => (
      <Tooltip
        id={tooltipId}
        anchorX={tooltipDirectionX}
        anchorY={tooltipDirectionY}
        toggleEl={renderButton()}
      >
        {tooltip}
      </Tooltip>
    );

    const renderActionLinks = (id = buttonId) => (
      <ul className="spark-popover__list" role="menu" aria-labelledby={id}>
        {actionLinks.map((actionLink, index) => {
          const { href, target, label, onClick, ...other } = actionLink;

          const normalizedHref = href || "#";

          return (
            <li key={index} className="spark-popover__list-item" role="none">
              <a
                role="menuitem"
                href={normalizedHref}
                target={target}
                {...other}
                className={classNames(
                  "spark-popover__list-link",
                  actionLink.className
                )}
                onClick={(e) => {
                  if (normalizedHref === "#") {
                    e.preventDefault();
                  }
                  onClick?.(e);
                }}
              >
                {label}
              </a>
            </li>
          );
        })}
      </ul>
    );

    const renderMultiActionButton = () => (
      <Popover
        id={popoverId}
        anchorX="center"
        anchorY="bottom"
        toggleEl={renderButton(<span>{children}</span>)}
        className={popoverClassName}
        rtl={rtl}
        contentCloseElQuery={contentCloseElQuery}
        toggleElWrapperStyle={{ ...fullWidthStyle }}
      >
        {popoverChildren || (actionLinks && renderActionLinks())}
      </Popover>
    );

    const renderComboButton = () => {
      return (
        <div
          className={`spark-btn-combo ${comboBoxContainerClassName}`}
          style={{ ...fullWidthStyle }}
        >
          {renderButton(children, `${buttonClasses} spark-btn-combo__primary`)}
          <Popover
            id={popoverId}
            anchorX={rtl ? "right" : "left"}
            anchorY="bottom"
            toggleEl={renderButton(
              null,
              `${buttonClasses} spark-btn-combo__secondary`
            )}
            rtl={rtl}
            className={popoverClassName}
            contentCloseElQuery={contentCloseElQuery}
          >
            {popoverChildren || (actionLinks && renderActionLinks())}
          </Popover>
        </div>
      );
    };

    if (isMultiAction) {
      return rtl
        ? WithBDO(renderMultiActionButton())
        : renderMultiActionButton();
    } else if (isCombo) {
      return rtl ? WithBDO(renderComboButton()) : renderComboButton();
    } else if (progress && !icon) {
      return (
        <ProgressButton
          id={buttonId}
          data-testid={testId}
          onClick={onClick}
          className={buttonClasses}
          progressSuccessIcon={progressSuccessIcon}
          {...conditionalAttributes}
          {...rest}
          style={{ ...fullWidthStyle, ...rest.style }}
          ref={ref}
        >
          {children}
        </ProgressButton>
      );
    } else if (tooltip) {
      return renderTooltip();
    } else {
      return renderButton();
    }
  }
);

// Backwards compatibility to support component property. Enum import should be used instead.
Button.SIZE = ButtonSize;
Button.TOOLTIP_DIRECTION_X = TooltipDirectionX;
Button.TOOLTIP_DIRECTION_Y = TooltipDirectionY;

Button.defaultProps = {
  type: "button",
  size: ButtonSize.MEDIUM,
  tooltipDirectionX: "center",
  tooltipDirectionY: "bottom",
  contentCloseElQuery: ".spark-popover__list-link",
  progressSuccessIcon: "spark-icon-check",
  rtl: false,
};

Button.propTypes = {
  /** HTML `type` attribute */
  type: oneOf(["submit", "reset", "button"]),

  /** Function triggered by onClick. Have 3 parameters - click event, success and failure callbacks. When progress is true, success or failure callback must be called to end the progress indication*/
  onClick: func,

  /** Determines size at which Button will be render. One of: `xs`, `sm`, `md`, `lg`. Not required when using "brand" or "icon".*/
  size: function (props, propName, componentName) {
    if (
      !props["icon"] &&
      !props["brand"] &&
      !Object.values(ButtonSize).includes(props[propName])
    ) {
      console.warn(
        `${props[propName]} is not a valid value of prop "size" in ${componentName}`
      );
      return new Error('Please provide a "size" prop!');
    }
  },

  /** If `true`, `Button` will be disabled */
  disabled: bool,

  /** Use a brand button for large banners and marketing contexts. Defaults size to "lg". */
  brand: bool,

  /** @deprecated */
  /** **Deprecated**. Use `textOnly` instead */
  text: bool,

  /** Use a text only button for secondary or tertiary actions that do not require the prominence of a button treatment. Ignores all "size" values. */
  textOnly: bool,

  /** Use a secondary button for non-primary actions. */
  secondary: bool,

  /** Buttons with a negative or critical action should use the negative button style. */
  negative: bool,

  /** Renders icon instead of text inside the Button. Must be Spark-supported icon name. */
  icon: string,

  /** Buttons with multiple actions to be displayed inside a popover must use multiAction prop. */
  multiAction: bool,

  /** Combination of 2 buttons with multiple actions to be displayed inside a popover must use combo prop. */
  combo: bool,

  /** DOM query of element on whose click, the popover closes */
  contentCloseElQuery: string,

  /** Display a list of links when the button variant is of 'combo' or 'multiAction'. Must be used only if popoverChildren is not given. Will be omitted if both popoverChildren and actionLinks are passed*/
  actionLinks: arrayOf(
    shape({
      label: string,
      href: string,
      target: string,
    })
  ),

  /** Content of the popover that appears on click of button when the variant is of 'combo' or 'multiAction'. 'actionLinks' shouldn't be passed when popoverChildren is passed*/
  popoverChildren: function (props, propName) {
    if (
      !props["icon"] &&
      (props["combo"] || props["multiAction"]) &&
      (!props["actionLinks"] ||
        (props["actionLinks"] && props["actionLinks"].length === 0)) &&
      props[propName] === undefined
    ) {
      return new Error(
        "Failed prop type: The prop `popoverChildren` is marked as required when props `combo` or `multiAction` is true and `actionLinks` is not given"
      );
    }
  },

  /** Right to Left Property */
  rtl: bool,

  /** If set to true, the button will occupy the full width of its container. Not applicable to "textOnly" and "icon" variants */
  fullWidth: bool,

  /** Use a button with progress indicator and success icon. */
  progress: bool,

  /** Renders icon after success inside the Button. Must be Spark-supported icon name. */
  progressSuccessIcon: string,

  /** Function triggered after success or failure of progress. Must be used only when progress is true */
  onComplete: func,

  /** Note: Note: Previous tooltip desription & propTypes. Temporary inactive because with `tooltip` applied `Popover` can't be used over */
  /** When "icon" is set, a tooltip is required to describe the icon and action for the Button. */
  // tooltip: function (props, propName, componentName) {
  //   if (props["icon"] && props[propName] === undefined) {
  //     return new Error(
  //       `You must use the "tooltip" prop when using "icon" in ${componentName}`
  //     );
  //   }
  // },

  /** Text for optional Tooltip */
  tooltip: oneOfType([string, node]),

  /** Determines horizontal direction in which icon tooltip will render. */
  tooltipDirectionX: oneOf(Object.values(TooltipDirectionX)),

  /** Determines vertical direction in which icon tooltip will render. */
  tooltipDirectionY: oneOf(Object.values(TooltipDirectionY)),

  /** Test ID used for identifying component instance in automated tests. */
  testId: string,

  /** Value of "aria-label" HTML attribute. Required to improve accessibility when using Icon Button. */
  ariaLabel: function (props) {
    if (props.icon && !props["ariaLabel"] && !props["aria-label"]) {
      return new Error(
        "Failed prop type: The prop `aria-label` is required when using the `icon` prop in `Button`."
      );
    }
  },

  /** Any extra CSS classes for the component */
  className: string,

  /** Id for the popover which will be assigned as aria-controls for the combo or multi action button */
  popoverId: string,

  /** Any extra CSS classes for the popover component while using combo or multi action button */
  popoverClassName: string,

  /** Any extra CSS classes for the container component while using combo button */
  comboBoxContainerClassName: string,

  /** React children for the component. Not required when using "icon" */
  children: function (props, propName) {
    if (!props["icon" && props[propName]] === undefined) {
      return new Error(
        "Failed prop type: The prop `children` is marked as required in `Button`, but its value is `undefined`"
      );
    }
  },
};

Button.displayName = "Button";
