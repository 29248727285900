import React, { forwardRef, ReactElement } from "react";
import { TypeAhead } from "../utils/typeahead/typeahead";
import { TypeaheadProps } from "../utils/typeahead/typeahead.types";
import { isValidMin } from "./time-selection.util";

interface MinuteTypeaheadProps
  extends Omit<TypeaheadProps, "width" | "onChange" | "isValidInputCharacter"> {
  /** A callback function run when the input value changes. **/
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const MinuteTypeahead = forwardRef(
  (
    {
      value: mins,
      onChange,
      onBlur,
      ariaLabel,
      name,
      ...rest
    }: MinuteTypeaheadProps,
    externalRef
  ): ReactElement => {
    const onChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>,
      val: string
    ): boolean => {
      if (isValidMin(val) || val === "") {
        const num = Number(val);
        onChange(e, num > 59 ? "59" : val);
        return true;
      }
      return false;
    };

    const onBlurHandle = (
      e: React.FocusEvent<HTMLInputElement>,
      val: string
    ): void => {
      let valStr = val;
      if (val.length === 1) {
        valStr = val.padStart(2, "0");
        onChange(e, valStr);
      }
      onBlur(e, valStr);
    };

    return (
      <TypeAhead
        {...rest}
        ref={externalRef}
        name={name}
        value={mins}
        ariaLabel={ariaLabel}
        width={21}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
        isValidInputCharacter={isValidMin}
      />
    );
  }
);

MinuteTypeahead.displayName = "MinuteTypeahead";

export { MinuteTypeahead };
