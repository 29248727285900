import React, { ReactElement } from "react";
import { SeparatorType } from "./DateInputField.types";

type SeparatorProp = {
  separator: SeparatorType;
};
const Separator = ({ separator = "-" }: SeparatorProp): ReactElement => (
  <span className="spark-input__divider">
    {separator !== "-" ? (
      <span style={{ paddingTop: 2 }}>{separator}</span>
    ) : (
      separator
    )}
  </span>
);

export default Separator;
