import { DisabledDates, ListItem } from "../DateInputSelect.types";
import { useEffect, useRef } from "react";

/**
 * Generate options as date list, add first item as empty
 * @param max
 * @param isFirstItemEmpty
 */
export const generateNumbers = (
  max = 31,
  isFirstItemEmpty = true
): ListItem[] =>
  isFirstItemEmpty
    ? [{ label: "", value: "" }].concat(
        Array(max)
          .fill(1)
          .map((x, y) => ({
            label: (x + y).toString(),
            value: (x + y).toString(),
          }))
      )
    : Array(max)
        .fill(1)
        .map((x, y) => ({
          label: (x + y).toString(),
          value: (x + y).toString(),
        }));

export const monthNamesDefault: ListItem[] = [
  { label: "", value: "" },
  {
    value: "1",
    label: "Jan",
    maxDays: 31,
  },
  {
    value: "2",
    label: "Feb",
    maxDays: 28,
  },
  {
    value: "3",
    label: "Mar",
    maxDays: 31,
  },
  {
    value: "4",
    label: "Apr",
    maxDays: 30,
  },
  {
    value: "5",
    label: "May",
    maxDays: 31,
  },
  {
    value: "6",
    label: "Jun",
    maxDays: 30,
  },
  {
    value: "7",
    label: "Jul",
    maxDays: 31,
  },
  {
    value: "8",
    label: "Aug",
    maxDays: 31,
  },
  {
    value: "9",
    label: "Sep",
    maxDays: 30,
  },
  {
    value: "10",
    label: "Oct",
    maxDays: 31,
  },
  {
    value: "11",
    label: "Nov",
    maxDays: 30,
  },
  {
    value: "12",
    label: "Dec",
    maxDays: 31,
  },
];

/**
 * Generate days list as options
 */
export const daysListDefault = generateNumbers(31);

/**
 * Generate last 10 years from current year as options list
 */
export const generateYears = (
  addNextYears?: boolean,
  range = 10
): ListItem[] => {
  const currentYear: number = new Date().getFullYear();
  const numbersTill10 = generateNumbers(range, false);
  const years = numbersTill10
    .map((item) => (currentYear - parseInt(item.value) + 1).toString())
    .reverse();
  if (!addNextYears) {
    return [{ label: "", value: "" }].concat(
      years.map((year) => ({
        label: year?.toString(),
        value: year?.toString(),
      }))
    );
  }
  const nextYears = numbersTill10.map((item) =>
    (currentYear + parseInt(item.value)).toString()
  );

  return [{ label: "", value: "" }]
    .concat(
      years.map((year) => ({
        label: year?.toString(),
        value: year?.toString(),
      }))
    )
    .concat(
      nextYears.map((year) => ({
        label: year?.toString(),
        value: year?.toString(),
      }))
    );
};

export const yearsList = generateYears();
export const nextYearsList = generateYears(true);

/**
 * Update days list values with disabled options taken from disabled date inputs
 * @param daysListProp
 * @param maxDaysInMonth
 * @param disabledDateInputs
 */
export const updateDaysListWithDisabledDates = (
  daysListProp: ListItem[],
  maxDaysInMonth: string,
  disabledDateInputs?: DisabledDates[]
): ListItem[] => {
  const disabledDatesFiltered = disabledDateInputs
    ?.map((item) => {
      if (item.type === "DD") {
        return item.value;
      }
      return undefined;
    })
    .filter((i) => i);
  return daysListProp
    .filter((day: ListItem, index) => index <= parseInt(maxDaysInMonth))
    .map((item) => {
      const newItem = { ...item };
      if (disabledDatesFiltered?.includes(parseInt(newItem?.value))) {
        newItem.disabled = true;
        return newItem;
      }
      return newItem;
    });
};

/**
 * Update month list values with disabled options taken from disabled date inputs
 * @param monthList
 * @param disabledMonthInputs
 */
export const updateMonthListValuesWithDisabledItems = (
  monthList: ListItem[],
  disabledMonthInputs?: DisabledDates[]
): ListItem[] => {
  const disabledDatesFiltered = disabledMonthInputs
    ?.map((item) => {
      if (item.type === "MM") {
        return item.value;
      }
      return undefined;
    })
    .filter((i) => i);
  return monthList.map((item) => {
    const newItem = { ...item };
    if (disabledDatesFiltered?.includes(parseInt(newItem?.value))) {
      newItem.disabled = true;
      return newItem;
    }
    return newItem;
  });
};

/**
 * Update year list values with disabled options taken from disabled date inputs
 * @param yearList
 * @param disabledYearInputs
 */
export const updateYearListValuesWithDisabledItems = (
  yearList: ListItem[],
  disabledYearInputs?: DisabledDates[]
): ListItem[] => {
  const disabledDatesFiltered = disabledYearInputs
    ?.map((item) => {
      const newItem = { ...item };
      if (newItem.type === "YYYY") {
        return newItem.value;
      }
      return undefined;
    })
    .filter((i) => i);

  return yearList.map((item) => {
    const newItem = { ...item };
    if (disabledDatesFiltered?.includes(parseInt(item?.value))) {
      newItem.disabled = true;
      return newItem;
    }
    return newItem;
  });
};

export const usePreviousDateString = (value: string): string | null => {
  const ref = useRef<string | null>(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
