import { ToastBody as ToastBodyTypes, ToastType } from "./toast.types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getToastTypeClassName } from "./toast.util";
import classNames from "classnames";

export const ToastBody = ({
  onClose,
  type = ToastType.POSITIVE,
  title,
  details,
  icon,
  actionIcon,
  actionLabel,
  el,
  duration = 3,
  elOffset = new DOMRect(),
  elHeaderOffset = new DOMRect(),
  className,
  makeToastElRef,
}: ToastBodyTypes): React.ReactElement | null => {
  // Check if the target el or element where toast to be rendered is having position of fixed. Used in fixed header scenario.
  const [isElFixed] = useState(() => {
    const position = el
      ? window.getComputedStyle(el, null).getPropertyValue("position")
      : false;
    return position === "fixed";
  });

  // Used to identify scroll and do the sticky logic in case where el, elHeader are present
  const [isScrolledBeyondEl, setScrolledBeyondEl] = useState(() => {
    const { top } = el?.getBoundingClientRect() || { top: 0 };

    // when the total scrolled height is more than target el position, we set the toast to sticky
    return top < 10;
  });

  // Used where the Toast has to be rendered with an offset from the target el
  const { height: heightElHeader, left: leftChild } = elHeaderOffset;

  // Used to record the changed width after window resize
  const [widthNew, setWidthNew] = useState(0);

  // Compute offset when the toast has to be rendered with an offset from target
  const { width, left } = elOffset;
  const leftOffset = leftChild ? left - leftChild : 0;

  // Get the class corresponding to toast type
  const toastTypeClass = useMemo(() => getToastTypeClassName(type), [type]);

  const actionClasses = classNames({
    "spark-btn--icon spark-icon spark-icon--fill spark-icon-close": actionIcon,
    "spark-btn spark-btn--text": !actionIcon,
  });

  const hasAction = actionIcon || actionLabel;

  // When toast is rendered, the action button gets focus
  const buttonRef = useRef<HTMLButtonElement>(null);

  // When the screen gets scrolled, position the toast message like a sticky at top
  const onScrollBound = useCallback((): void => {
    if (isElFixed) {
      return;
    }

    const {
      top,
      y = 0,
      height = 0,
    } = el?.getBoundingClientRect() || { top: 0 };
    const isHeaderComponent = el?.className?.search(/spark-header/) !== -1;

    // when the total scrolled height is more than target el position, we set the toast to sticky
    if (isHeaderComponent && Math.abs(top) >= height) {
      setScrolledBeyondEl(true);
    } else if (!isHeaderComponent && top < 0 && Math.abs(top) >= y) {
      setScrolledBeyondEl(true);
    } else {
      setScrolledBeyondEl(false);
    }
  }, [el, isElFixed]);

  // When window is resized, we set the new width to toast component
  const onResize = useCallback((): void => {
    const { width: widthNew } = el?.getBoundingClientRect() || { width: 0 };
    setWidthNew(widthNew);
  }, [el]);

  useEffect(() => {
    window.addEventListener("scroll", onScrollBound);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("scroll", onScrollBound);
      window.removeEventListener("resize", onResize);
    };
  }, [onResize, onScrollBound]);

  // This hook takes care of closing/destroying the toast after the duration. It triggers the onClose method.
  useEffect(() => {
    // scroll bound is invoked here to handle an edge case. For instance, the user has scrolled to the bottom and triggers the toast where target is fixed.
    onScrollBound();
    const timer = setTimeout(() => {
      onClose?.(false);
    }, duration * 1000 + 1250);
    buttonRef?.current?.focus();
    const elCopy = makeToastElRef?.current;
    return () => {
      elCopy?.focus({
        preventScroll: true,
      });
      clearTimeout(timer);
    };
  }, [duration, makeToastElRef, onClose, onScrollBound]);

  const classes = classNames(className, "spark-toast", {
    [toastTypeClass]: true,
    "spark-toast--fixed": isElFixed || isScrolledBeyondEl,
  });

  // Offset computed for specific scenarios like the target el is fixed, not fixed but the user has scrolled past the target el.
  let topComputed = heightElHeader || 0;
  if (isElFixed) {
    topComputed = heightElHeader;
  }
  if (isScrolledBeyondEl && !isElFixed) {
    topComputed = 0;
  }

  if (actionIcon && !actionLabel) {
    console.warn(
      "Provide actionLabel prop for ARIA purposes, when actionIcon prop is used"
    );
  }

  return (
    <>
      <div
        className={classes}
        role="alert"
        style={{
          left: `${isScrolledBeyondEl ? left : leftOffset}px`,
          top: `${topComputed}px`,
          width: `${widthNew || width}px`,
        }}
      >
        <div className="spark-toast__panel spark-toast--show">
          {icon && (
            <div aria-hidden="true" className="spark-toast__icon">
              <i className={`spark-icon spark-icon--fill ${icon}`} />
            </div>
          )}
          <div className="spark-toast__content">
            <h4 role="presentation" className="spark-toast__heading">
              {title}
            </h4>
            <p className="spark-toast__details">{details}</p>
          </div>
          <div
            className="spark-toast__actions"
            onClick={(): void => onClose?.(false)}
          >
            {hasAction && (
              <button
                type="button"
                className={actionClasses}
                ref={buttonRef}
                aria-label={hasAction && actionLabel ? actionLabel : ""}
              >
                {!actionIcon && actionLabel}
              </button>
            )}
          </div>
          <div
            className="spark-toast__timer"
            style={{
              animation: `${duration}s linear 1s 1 normal forwards running toast-timer-animation`,
            }}
          />
        </div>
      </div>
    </>
  );
};

ToastBody.displayName = "ToastBody";
