import React from "react";
import classNames from "classnames";
import {
  MessageProps,
  MessageRole,
  MessageStatus,
  MessageType,
} from "./message.types";

export const Message = ({
  animate = false,
  ariaLabel,
  ariaLabelledBy,
  className,
  content,
  title,
  titleHeadingLevel = 4,
  status,
  type,
  role,
  children,
}: MessageProps): JSX.Element => {
  /**
   * prop-types "requiredIf" conditions breaks rollup build.
   * They were moved to component's body as console.warn
   */
  if (
    role === MessageRole.REGION &&
    (ariaLabel || ariaLabelledBy) === undefined
  ) {
    console.warn(
      `ariaLabel or ariaLabelledBy prop is required when ARIA role is "region"`
    );
  }

  if (
    type === MessageType.PAGE &&
    content === undefined &&
    children === undefined
  ) {
    console.warn(
      `children prop is required when type is "page" and no content prop is provided`
    );
  }

  if (children === undefined && content === undefined) {
    console.warn(`content prop is required when no children prop is provided`);
  }

  const classes = classNames(
    "spark-message",
    {
      "spark-message--animate": animate,
    },
    {
      "spark-message--lg": type === MessageType.PAGE,
      "spark-panel": type === MessageType.PAGE,
    },
    {
      [`spark-message--${status}`]: true,
    },
    className
  );

  const iconClasses = classNames("spark-message__icon", "spark-icon--fill", {
    "spark-icon-check": status === MessageStatus.SUCCESS,
    "spark-icon-alert-triangle": status === MessageStatus.WARNING,
    "spark-icon-ban": status === MessageStatus.ERROR,
    "spark-icon-note": status === MessageStatus.INFO,
  });

  const renderPageLevel = (): JSX.Element => {
    const headerEl = React.createElement(
      "h" + titleHeadingLevel,
      { className: "spark-message__heading" },
      title
    );

    return (
      <div className="spark-panel__content">
        <i className={iconClasses}></i>
        <div className="spark-message__content">
          {headerEl}
          {React.isValidElement(children) ? children : <p>{content}</p>}
        </div>
      </div>
    );
  };

  const renderContainerLevel = (): JSX.Element => (
    <>
      <i className={iconClasses}></i>
      <p className="spark-message__content">
        <strong>{title}</strong> {content}
      </p>
    </>
  );

  const ariaProps: {
    "aria-label"?: string;
    "aria-labelledby"?: string;
  } = {};

  if (ariaLabel) {
    ariaProps["aria-label"] = ariaLabel;
  } else if (ariaLabelledBy) {
    ariaProps["aria-labelledby"] = ariaLabelledBy;
  }

  return (
    <div className={classes} role={role} {...ariaProps}>
      {type === MessageType.PAGE ? renderPageLevel() : renderContainerLevel()}
    </div>
  );
};

// Backwards compatibility to support component property. Enum import should be used instead.
Message.ROLES = MessageRole;
Message.STATUS = MessageStatus;
Message.TYPE = MessageType;

Message.displayName = "Message";
