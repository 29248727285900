import React from "react";

export type ElementOrNull = Element | null;

export type ToastProps = {
  /**
   * A short title for the notification
   */
  title: string;
  /**
   * DOM element inside which the toast message will be placed
   */
  el: ElementOrNull;
  /**
   * Opens or close the toast
   */
  open?: boolean | number | string;
  /**
   * This DOM element value is used to display the toast messages below the heading of a panel or the top navigation
   */
  elHeader?: ElementOrNull;
  /**
   * This method is used to close the toast before/when the duration is expired
   */
  onClose: (arg?: any) => void;
  /**
   * Duration that the toast will display in seconds. A value of 3 would display the notification for 3 seconds before auto removing
   */
  duration?: number;
  /**
   * A longer description about the toast notification. an example would be providing positive feed-back on a user action
   */
  details?: string;
  /**
   * This icon should reinforce the type of notification such as spark-icon-check for success or spark-icon-alert-triangle for failure
   */
  icon?: string;
  /**
   * This value applies the color styles to the notification, positive, neutral or warning
   */
  type?: ToastType;
  /**
   * A one word action in UPPER case, such as DISMISS. If both the actionIcon and actionLabel are specified then only the actionIcon will be displayed
   */
  actionLabel?: string;
  /**
   * spark icon, spark-icon-close is the icon used for dismissing the toast. If both the actionIcon and actionLabel are specified then only the actionIcon will be displayed
   */
  actionIcon?: string;
  /**
   * Any extra CSS classes for the component
   */
  className?: string;
  /**
   * Pass the React ref of the element that triggered the toast, for accessibility reasons
   */
  makeToastElRef?: React.RefObject<HTMLButtonElement | HTMLElement>;
  /**
   * Providing this property will create a SPAN wrapper for the Portal and assign a given class to it. Useful, among others, for style isolation purposes
   */
  portalWrapperClassName?: string;
};

export type ToastBody = Omit<ToastProps, "open"> & {
  /**
   * The offset of the el DOM element
   */
  elOffset?: DOMRect;
  /**
   * The offset of the elHeaderOffset DOM element
   */
  elHeaderOffset?: DOMRect;

  /**
   * Any extra CSS classes for the component
   */
  className?: string;

  /**
   * Ref to the element that triggered the toast, for instance button
   */
  makeToastElRef?: React.RefObject<HTMLButtonElement | HTMLElement>;
};

export enum ToastType {
  POSITIVE = "positive",
  NEUTRAL = "neutral",
  WARNING = "warning",
}
