import React, { ReactElement } from "react";
import classNames from "classnames";
import Switch from "../switch";
import { ToggleProps } from "./toggle.types";

export const Toggle = ({
  ariaLabel,
  ariaLabelledBy,
  checked,
  className,
  condensed,
  defaultChecked,
  disabled,
  iconName,
  id,
  label,
  name,
  onChange,
  switchToggle,
  value,
  _privateProps = {},
  ...rest
}: ToggleProps): ReactElement => {
  if (!_privateProps.isToggleGroup && !switchToggle) {
    console.log(
      "You tried to use the <Toggle> component without wrapping it in <ToggleGroup>. For single Toggle scenarios use Switch instead."
    );
  }

  const inputProps = {
    checked: checked,
    className: "spark-toggle__input",
    defaultChecked: defaultChecked,
    disabled: disabled,
    id,
    name: name,
    onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
      onChange?.(e, value);
    },
    value: value,
  };
  if (switchToggle) {
    if (!ariaLabelledBy) {
      console.warn(
        "You must provide a value for prop 'ariaLabelledBy' when using <Toggle> as switch."
      );
    }
    return (
      <Switch ariaLabelledBy={ariaLabelledBy || ""} {...inputProps} {...rest} />
    );
  }
  return (
    <label
      className={classNames(
        { "spark-toggle--xs": condensed },
        "spark-toggle",
        className
      )}
    >
      <input
        type={"radio"}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        {...inputProps}
        {...rest}
      />
      <span className="spark-label">
        {iconName ? <i className={iconName} /> : label}
      </span>
    </label>
  );
};

Toggle.displayName = "Toggle";
