import React, {
  useState,
  useRef,
  ReactElement,
  forwardRef,
  LegacyRef,
} from "react";
import classNames from "classnames";
import { MessageStatus } from "../types";
import { Options, SelectInputProps } from "./select-input.types";
import { useCombinedRefs } from "../utils/useCombinedRefs";

export interface SelectInputWithForwardRef
  extends React.ForwardRefExoticComponent<
    SelectInputProps & React.RefAttributes<HTMLSelectElement>
  > {
  STATUS: typeof MessageStatus;
}

export const SelectInput = forwardRef<
  LegacyRef<HTMLSelectElement>,
  SelectInputProps
>(
  (
    {
      className,
      defaultValue,
      disabled,
      id,
      label,
      name,
      title,
      onBlur,
      onChange,
      onFocus,
      options,
      required,
      status,
      errorMessage,
      statusMessage,
      testId,
      value,
      ariaLabel,
      ...rest
    },
    ref
  ): ReactElement => {
    const [hasValue, setHasValue] = useState<boolean>();
    const [focus, setFocus] = useState<boolean>(false);

    let firstOption: Options | undefined = undefined;
    if (options && options.length) {
      firstOption = options[0];
    }

    const hasControlledValue = value !== undefined;
    if (hasControlledValue) {
      const values = options?.map((option) => option.value);
      const hasValueInOption = values?.includes(value);
      if (!hasValueInOption) {
        console.warn(
          "controlled form value prop, not found in options. The first option will be selected by default in select input."
        );
      }
    }

    if (!label && !ariaLabel) {
      console.error(
        `ariaLabel is a required prop in SelectInput when label prop is undefined/empty`
      );
    }

    const hasUncontrolledValue =
      hasValue !== undefined ? hasValue : Boolean(defaultValue);

    const checkIfSelectHasNonEmptyOption = (): boolean => {
      /**
       * If first value is not empty, the select has a value. We do not have to
       * check any further.
       */
      if (firstOption?.label !== "") {
        return true;
      }

      return !value ? hasUncontrolledValue : hasControlledValue;
    };

    /**
     * Adds styling classes to wrapping <label> element.
     * "has-value" class condenses label in the select field to display select's value.
     * "active" class gives focus styles on select, used only for Uncontrolled variant.
     */
    const defaultValueClasses = classNames(
      {
        "has-value": checkIfSelectHasNonEmptyOption(),
        "spark-select--no-label": !label,
      },
      { active: focus },
      "spark-select",
      className
    );
    const attrs = {
      className: defaultValueClasses,
      "aria-label": label ? undefined : ariaLabel,
      title,
    } as any;

    const inputProps = {
      ...rest,
      [`data-testid`]: testId,
      className: "spark-select__input",
      id,
      name,
      required,
      defaultValue,
      disabled,
      value,
      onFocus: (e: React.FocusEvent<HTMLSelectElement>): void => {
        setFocus(true);
        onFocus?.(e);
      },
      onBlur: (e: React.FocusEvent<HTMLSelectElement>): void => {
        setFocus(false);
        onBlur?.(e);
      },
      onChange: (e: React.ChangeEvent<HTMLSelectElement>): void => {
        checkValue(e);
        e.persist();
        const target = e.currentTarget.options[e.currentTarget.selectedIndex];
        onChange?.(e, target.value);
      },
    };

    /**
     * Applies Field Level Messaging styles to select's <label> element wrapper.
     *
     */
    switch (status) {
      case MessageStatus.ERROR:
        attrs["data-error"] = "true";
        break;
      case MessageStatus.WARNING:
        attrs["data-warning"] = "true";
        break;
      case MessageStatus.SUCCESS:
        attrs["data-success"] = "true";
        break;
      case MessageStatus.INFO:
        attrs["data-info"] = "true";
        break;
      default:
        break;
    }

    /**
     * Renders label/value pair options in select
     * @param {Array} options
     * @returns {Array}
     */
    const renderOptions = (
      options: SelectInputProps["options"]
    ): ReactElement[] | null => {
      return options
        ? options.map((option) => {
            const label = option.label || option.value;
            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {label}
              </option>
            );
          })
        : null;
    };

    /**
     * Updates hasValue state based on whether select input's value is empty or not.
     * @param {Object} e - onChange event on the select input
     */
    const checkValue = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const index = e.currentTarget.selectedIndex;
      const targetValue = e.currentTarget.options[index].text;
      targetValue === "" ? setHasValue(false) : setHasValue(true);
    };

    const inputRef = useRef(null);
    useCombinedRefs(inputRef, ref);

    return (
      <label {...attrs}>
        <select {...inputProps} ref={inputRef}>
          {renderOptions(options)}
        </select>
        <span className="spark-label">{label}</span>
        {status && (
          <span className="spark-select__message">
            {statusMessage || errorMessage}
          </span>
        )}
      </label>
    );
  }
) as SelectInputWithForwardRef;

SelectInput.STATUS = MessageStatus;

SelectInput.displayName = "SelectInput";
