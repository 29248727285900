/**
 * # Get Index
 * Get the index of an element in a nodelist.
 *
 * @param {NodeList} els
 * @param {Node} el
 * @return {Number}
 *
 */

function getIndex(els, el) {
  // @TODO: utils/traversal methods should be rewritten to Typescript
  return Array.prototype.indexOf.call(els, el);
}

export default getIndex;
