import React from "react";

import { string } from "prop-types";
import classNames from "classnames";

export class FieldLevelMessage extends React.Component {
  constructor(props) {
    super(props);
    this.messageRef = React.createRef();
    this.state = {
      height: "auto",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    // Status Message
    if (!prevProps.status && !!this.props.status) {
      window.requestAnimationFrame(() => {
        const height = this.messageRef.current?.clientHeight;
        this.setState({ height: "0px" });
        setTimeout(() => {
          this.setState({ height: height + "px" });
        }, 0);
      });
    }

    // No status Message
    if (!!prevProps.status && !this.props.status) {
      this.setState({ height: "auto" });
    }
  }

  updateAfterTransition = () => {
    // this will pull off the animation class as done in Spark.
    this.setState({ height: "auto" });
  };

  render() {
    const { id, statusMessage } = this.props;
    const classes = classNames("spark-input__message", {
      "spark-animate-height": this.state.height !== "auto",
    });

    return (
      <span
        id={id}
        className={classes}
        ref={this.messageRef}
        style={{ height: this.state.height }}
        onTransitionEnd={() => this.updateAfterTransition()}
      >
        {statusMessage}
      </span>
    );
  }
}

FieldLevelMessage.displayName = "FieldLevelMessage";

FieldLevelMessage.propTypes = {
  /** Status Message Text */
  statusMessage: string,

  /** Status Message Type */
  status: string,
};
