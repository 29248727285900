import React, { MouseEvent, TouchEvent, ReactNode } from "react";

export enum CarouselVariant {
  HERO = "hero",
  MULTIPLE_PANEL = "multiplePanel",
  FILMSTRIP = "filmstrip",
}

export type MouseOrTouchEvent =
  | MouseEvent<HTMLDivElement>
  | TouchEvent<HTMLDivElement>;

export interface CarouselItem {
  /** Image source address */
  img: string;

  /** Image alt text */
  altText?: string;

  /** Specific width passed as object property in general property `items`. Every variant has a default size, this overrides the default as well as the general property `itemWidth` */
  width?: number;

  /** Specific height passed as object property in general property `items`. Every variant has a default size, this overrides the default as well as the general property `itemHeight` */
  height?: number;

  /** onClick callback (overrides Carousel's optional `onItemClick`) */
  onClick?: (id: string) => void;

  /** Item ID. Useful for identifying an item during onClick callback */
  id?: string;
}

export interface CarouselProps extends React.HTMLProps<HTMLDivElement> {
  /** Carousel children. An alternative method of passing carousel elements, instead of `items` property */
  children?: ReactNode;

  /** Type of Carousel to render */
  variant?: CarouselVariant | "hero" | "multiplePanel" | "filmstrip";

  /**
   * @ignore
   * @deprecated use `variant` prop instead
   */
  type?: CarouselVariant | "hero" | "multiplePanel" | "filmstrip";

  /** Carousel items array. Ignored when `children` are passed */
  items?: CarouselItem[];

  /** Dots navigation. Available for "hero" and "multiplePanel" variants. Enabled by default */
  dots?: boolean;

  /** Panel title of the "filmstrip" variant. For other variants `carouselTitle` is used for "aria-label" attribute */
  carouselTitle?: string;

  /** Extra CSS classes for the component */
  className?: string;

  /** Custom width for a single Carousel item. Every variant has a default size. Note that the "hero" variant always takes the full width (100%) of its container */
  itemWidth?: number;

  /** Group of layout properties to dynamically adapt Carousel items to the width of multiple container breakpoints. */
  flexWidthBreakpoints?: {
    /** Min pixel width for a single Carousel container layout size, e.g., min: 0 */
    min: number;
    /** Max pixel width for a single Carousel container layout size, e.g., max: 640 or max: Infinity */
    max: number;
    /** Number of Carousel items to display for the given breakpoint sets */
    itemsPerPage: number;
    /** Hide Carousel item that flows past the edge of the container. By default, the "teaser" is shown */
    hideTeaser?: boolean;
    /** Custom width to partially display the Carousel "teaser" items */
    teaserWidth?: number;
  }[];

  /** Custom height for a single Carousel item. Every variant has a default size */
  itemHeight?: number;

  /** Custom horizontal margin for a single Carousel item in pixels. Every variant has a default margin */
  itemMargin?: number;

  /** Custom padding for a single Carousel item in pixels. Every variant has a default padding */
  itemPadding?: number;

  /** Enable after-scroll item centering */
  autoScrollCentering?: boolean;

  /** Prevent after-click item centering. Useful when custom onClick is provided and the centering movement should not overlap/distract the clicking action */
  preventCenteringOnClick?: boolean;

  /** Autoplay interval between transitions in seconds. Supported only when using "hero" variant */
  autoPlayInterval?: number;

  /** Id name for first focusable element after the Carousel. This property is mandatory for Skip Carousel element to appear */
  skipCarouselTargetId?: string;

  /** Custom text for Skip Carousel element */
  skipCarouselLabel?: string;

  /** Custom aria-label for Previous Image button ("hero" variant only) */
  previousImageAriaLabel?: string;

  /** Custom aria-label for Next Image button ("hero" variant only) */
  nextImageAriaLabel?: string;

  /** Custom aria-label for Pause Carousel button ("hero" variant only) */
  pauseCarouselAriaLabel?: string;

  /** onClick callback passed to every item. Must pass id to CarouselItem object or to the child to retrieve the clicked item id. Otherwise id parameter will return index of selected child */
  onItemClick?: (id: string) => void;
}
