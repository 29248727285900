import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import uniqueId from "lodash.uniqueid";
import { string, number, oneOf, oneOfType, func, bool } from "prop-types";
import { useCombinedRefs } from "../utils/useCombinedRefs";
import { FieldLevelMessage } from "../message/field-level-message";
import { MessageStatus } from "../types";
import {
  showSSRHydrationWarning,
  isPropEmpty,
} from "../utils/SSR/showSSRHydrationWarning";
import { useSSR } from "../utils/SSR/useSSR";

export const NumberSelector = React.forwardRef(
  (
    {
      name,
      decrementLabel,
      incrementLabel,
      id,
      min,
      max,
      step,
      label,
      status,
      statusMessage,
      className,
      onFocus,
      hideLabel,
      rtl,
      disabled,
      validateOnMount,
      validateOnChange,
      validateOnBlur,
      validateOnPressEnter,
      validate,
      onValidate,
      validatePattern,
      value: valueProp,
      defaultValue,
      onChange,
      onBlur,
      testId,
    },
    inputExternalRef
  ) => {
    const [decrementActive, setDecrementActive] = useState(false);
    const [incrementActive, setIncrementActive] = useState(false);
    const [focused, setFocused] = useState(false);

    const { current: isControlled } = useRef(valueProp !== undefined);

    // If step is not a number of type either number/string then set to 0
    const stepVal = useMemo(() => (!step || isNaN(step) ? 0 : step), [step]);

    // Number of digits after decimal point in the step prop
    const stepDecimals = useMemo(() => {
      return stepVal.toString().includes(".")
        ? stepVal.toString().split(".")[1].length || 0
        : 0;
    }, [stepVal]);

    const getFormattedValue = useCallback(
      (valueInp) => parseFloat(valueInp).toFixed(stepDecimals),
      [stepDecimals]
    );

    const validateRange = useCallback(
      (valueProp) => {
        const value = parseFloat(valueProp);

        if (isNaN(value)) {
          return 0;
        } else if (value > max) {
          return max;
        } else if (value < min) {
          return min;
        }

        return value;
      },
      [max, min]
    );

    const initialValueState = () =>
      isControlled
        ? validateRange(valueProp)
        : validateRange(defaultValue) || min || 0;

    const [valueState, setValueState] = useState(() => initialValueState());
    const inputRef = useRef(null);
    useCombinedRefs(inputRef, inputExternalRef);

    const { current: uniqueNumberSelectorId } = useRef(
      uniqueId("spark-number-selector_")
    );
    useSSR(
      () => isPropEmpty(id) && showSSRHydrationWarning("id", "NumberSelector")
    );
    const numberSelectorId = id || uniqueNumberSelectorId;
    const messageId = `${numberSelectorId}_message`;

    const _validate = useCallback(
      (value) => {
        onValidate?.(validate(value, validatePattern));
      },
      [onValidate, validate, validatePattern]
    );

    useEffect(() => {
      if (validateOnMount && validate) {
        _validate(inputRef.current.value);
      }
    }, [validateOnMount, validate, _validate]);

    // validate controlled value (passed from outside the input)
    useEffect(() => {
      if (isControlled && !focused) {
        const validatedValue = validateRange(valueProp);
        setDisplayValue(getFormattedValue(validatedValue));

        // onChange method provided by the form libraries (e.g. formik)
        // changes with every parent re-render. Additional condition was
        // added to prevent useEffect infinite loop
        if (validatedValue !== valueProp) {
          onChange(null, validatedValue);
        }
      }
    }, [
      focused,
      isControlled,
      onChange,
      validateRange,
      valueProp,
      getFormattedValue,
    ]);

    const value = isControlled ? valueProp : valueState;
    const [displayValue, setDisplayValue] = useState(getFormattedValue(value));

    const increment = (e) => {
      let newValue = value + parseFloat(stepVal);

      if (!isNaN(max) && newValue > max) {
        newValue = max;
      }
      if (!isNaN(min) && newValue < min) {
        newValue = min;
      }

      setValueState(newValue);
      setDisplayValue(getFormattedValue(newValue));
      if (validateOnChange && validate) {
        _validate(newValue);
      }
      onChange?.(e, newValue);
    };

    const decrement = (e) => {
      let newValue = value - parseFloat(stepVal);

      if (!isNaN(max) && newValue > max) {
        newValue = max;
      }
      if (!isNaN(min) && newValue < min) {
        newValue = min;
      }

      setValueState(newValue);
      setDisplayValue(getFormattedValue(newValue));
      if (validateOnChange && validate) {
        _validate(newValue);
      }
      onChange?.(e, newValue);
    };

    const _commonValidateOnBlurAndEnter = (eventValue) => {
      if (eventValue !== "") {
        if (!isNaN(min) && eventValue < min) {
          eventValue = min;
        } else if (!isNaN(max) && eventValue > max) {
          eventValue = max;
        }
      } else {
        eventValue = valueProp || min || 0;
        if (validateOnChange && validate) {
          _validate(eventValue);
        }
      }
      return eventValue;
    };

    const _commonEventHandleOnBlurAndEnter = (event, isEnter) => {
      let eventValue =
        event.target.value === "" ? "" : parseFloat(event.target.value);

      const validatedEventValue = _commonValidateOnBlurAndEnter(eventValue);
      setValueState(validatedEventValue);
      setDisplayValue(getFormattedValue(validatedEventValue));
      onChange?.(event, validatedEventValue);
      if (isEnter && validateOnPressEnter && validate) {
        _validate(validatedEventValue);
      } else if (validateOnBlur && validate) {
        _validate(validatedEventValue);
      }
    };

    const _handleEnter = (event) =>
      _commonEventHandleOnBlurAndEnter(event, true);

    const _handleBlur = (event) => {
      _commonEventHandleOnBlurAndEnter(event, false);
      setFocused(false);
      onBlur?.(event);
    };

    const _handleButtonKeyPress = (event) => {
      // Simulates pressing the button on enter
      if (event.type === "keydown" && event.keyCode === 13) {
        if (
          event.currentTarget.classList.contains("spark-number-selector__down")
        ) {
          setDecrementActive(true);
        } else if (
          event.currentTarget.classList.contains("spark-number-selector__up")
        ) {
          setIncrementActive(true);
        }
      } else {
        if (event.keyCode === 13) {
          setIncrementActive(false);
          setDecrementActive(false);
        }
      }
    };

    const _handleKeyPress = (event) => {
      // Simulates pressing the button on down / up arrow
      if (event.type === "keydown") {
        if (event.keyCode === 40) {
          setDecrementActive(true);
          decrement(event);
        } else if (event.keyCode === 38) {
          setIncrementActive(true);
          increment(event);
        } else if (event.keyCode === 13) {
          _handleEnter(event);
        }
      } else {
        if (event.keyCode === 40 || event.keyCode === 38) {
          setIncrementActive(false);
          setDecrementActive(false);
        }
      }
    };

    const _handleChange = (event) => {
      if (incrementActive || decrementActive) {
        return;
      }
      let eventValue =
        event.target.value === "" ? "" : parseFloat(event.target.value);

      setValueState(eventValue);
      setDisplayValue(eventValue);
      if (validateOnChange && validate) {
        _validate(eventValue);
      }
      onChange?.(event, eventValue);
    };

    const attr = {};

    const classes = classNames(
      "spark-number-selector",
      { "spark-number-selector--hidden-label": hideLabel },
      className
    );

    const decrementButtonClasses = classNames(
      "spark-btn spark-icon spark-btn--secondary spark-icon-math-subtract spark-number-selector__down",
      { active: decrementActive === true }
    );

    const incrementButtonClasses = classNames(
      "spark-btn spark-icon spark-btn--secondary spark-icon-math-add spark-number-selector__up",
      { active: incrementActive === true }
    );

    if (status) {
      attr["data-" + status] = "true";
    }

    const component = (
      <div className={classes} {...attr}>
        <div className="spark-number-selector__item">
          <input
            type="number"
            name={name}
            value={displayValue}
            id={numberSelectorId}
            min={min}
            ref={inputRef}
            max={max}
            step={stepVal}
            aria-live="assertive"
            aria-describedby={messageId}
            onChange={(e) => _handleChange(e)}
            onKeyDown={(e) => _handleKeyPress(e)}
            onKeyUp={(e) => _handleKeyPress(e)}
            onBlur={(e) => _handleBlur(e)}
            onFocus={(e) => {
              setFocused(true);
              onFocus?.(e);
            }}
            disabled={disabled}
            data-testid={testId}
          />
          <button
            type="button"
            className={decrementButtonClasses}
            aria-label={decrementLabel}
            aria-controls={numberSelectorId}
            onClick={(e) => decrement(e)}
            onKeyDown={(e) => _handleButtonKeyPress(e)}
            onKeyUp={(e) => _handleButtonKeyPress(e)}
            disabled={disabled}
          ></button>
          <button
            type="button"
            className={incrementButtonClasses}
            aria-label={incrementLabel}
            aria-controls={numberSelectorId}
            onClick={(e) => increment(e)}
            onKeyDown={(e) => _handleButtonKeyPress(e)}
            onKeyUp={(e) => _handleButtonKeyPress(e)}
            disabled={disabled}
          ></button>
          <label htmlFor={numberSelectorId}>
            <span className={hideLabel ? "spark-assistive-text" : ""}>
              {label}
            </span>
          </label>
        </div>
        <FieldLevelMessage
          id={messageId}
          className="spark-input__message"
          status={status}
          statusMessage={statusMessage}
        />
      </div>
    );

    if (rtl) {
      return <bdo dir="rtl">{component}</bdo>;
    }

    return component;
  }
);

// Backwards compatibility to support component property. Enum import should be used instead.
NumberSelector.STATUS = MessageStatus;

NumberSelector.displayName = "NumberSelector";

NumberSelector.validate = (value, validatePattern) => {
  // Nothing to validate.
  if (!validatePattern) {
    return undefined;
  }

  const re = new RegExp(validatePattern);

  return re.test(value);
};

NumberSelector.propTypes = {
  /** Input name is required. **/
  name: string.isRequired,

  /** Id for the component. **/
  id: string,

  /** Aria label for the decrement button. **/
  decrementLabel: string,

  /** Aria label for the increment button. **/
  incrementLabel: string,

  /** Min value for the step indicator **/
  min: number,

  /** Max value for step indicator **/
  max: number,

  /** Any extra CSS classes for the component **/
  className: string,

  /** Value of the Input. **/
  value: oneOfType([number, string]),

  /** Default Value of the Input for uncontrolled variant. **/
  defaultValue: oneOfType([number, string]),

  /** Amount to Increment or Decrement. Pass it as string if need to maintain number of decimal places including zeros**/
  step: oneOfType([number, string]),

  /** Input Label. **/
  label: string,

  /** A callback function to run when the input value changes. **/
  onChange: func,

  /** A callback function to run when the input has focus. **/
  onFocus: func,

  /** A callback function to run when the looses focus. **/
  onBlur: func,

  /** One of Messages Status. **/
  status: oneOf([...Object.values(MessageStatus), ""]),

  /** Status Message for Field Level Message. **/
  statusMessage: string,

  /** A callback function run after validation has completed. **/
  onValidate: func,

  /** A function to run to validate the state of the input; it is passed value, validatePattern. **/
  validate: func,

  /** The regular expression pattern will be used in the default validate method to determine if an input is in a valid state. **/
  validatePattern: string,

  /** Run validation on blur, preffered. **/
  validateOnBlur: bool,

  /** Run validation on press of enter key, preffered. **/
  validateOnPressEnter: bool,

  /** Run validation on change. **/
  validateOnChange: bool,

  /** Run validation when the component is mounted. **/
  validateOnMount: bool,

  /** Right to Left Property **/
  rtl: bool,

  /** Display Label **/
  hideLabel: bool,

  /** Testing Key for Jest**/
  testId: string,

  /** If `true`, `NumberSelector` will be disabled */
  disabled: bool,
};

NumberSelector.defaultProps = {
  step: 1,
  decrementLabel: "Less",
  incrementLabel: "More",
  validate: NumberSelector.validate,
};
