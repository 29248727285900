import React, { CSSProperties } from "react";

export enum IconStyle {
  FILL = "fill",
  LINE = "line",
}

export enum IconSize {
  SMALL = "sm",
  DEFAULT = "default",
  MEDIUM = "md",
  LARGE = "lg",
}

// @TODO: remove "fill" | "line" on `style` on ver. 2.0
export interface IconProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, "size" | "style"> {
  /** The name of the Icon to use **/
  name: string;

  /** The size of the Icon. Pixels and rems values respectively: 9px/16px/24px/36px, 0.75rem/1.33rem/2rem/3rem  **/
  size?: IconSize | "sm" | "default" | "md" | "lg";

  /** The style of the Icon. The "fill" style is applied automatically on "small" size for accessibility purposes **/
  iconStyle?: IconStyle | "fill" | "line";

  /** Style for the Icon. Deprecation warning: To set "fill" or "line" style, use `iconStyle` property */
  style?: IconStyle | "fill" | "line" | CSSProperties;

  /** Accessible title if needed **/
  label?: string;

  /** Aria labelled by Id **/
  labelledBy?: string;

  /** CSS class for the Icon */
  className?: string;
}
