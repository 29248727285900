import React, { ReactElement } from "react";
import classNames from "classnames";
import { IconProps, IconSize, IconStyle } from "./icon.types";
import { renderIconClassName } from "../utils";

export const Icon = ({
  name,
  size = IconSize.MEDIUM,
  iconStyle,
  style,
  label,
  labelledBy,
  className,
  ...rest
}: IconProps): ReactElement => {
  const classes = classNames(
    "spark-icon",
    renderIconClassName(name),
    { [`spark-icon--${size}`]: size && size !== IconSize.DEFAULT },
    {
      "spark-icon--fill":
        (typeof style === "string" && style === "fill") ||
        iconStyle === "fill" ||
        size === IconSize.SMALL,
    },
    className
  );

  const attr: any = {
    "aria-label": label,
    "aria-labelledby": labelledBy,
  };

  if (!label && !labelledBy) {
    attr["aria-hidden"] = true;
  }

  // Resolving deprecated style prop (describing "fill" or "line" variant) and new CSSProperties prop
  const cssStyle = typeof style === "object" ? style : {};

  return <span className={classes} style={cssStyle} {...attr} {...rest}></span>;
};

Icon.STYLE = IconStyle;

Icon.displayName = "Icon";
