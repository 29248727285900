import { useEffect } from "react";

const useColumnResize = (
  tableRef: React.RefObject<HTMLTableElement>,
  isResizable: boolean
): void => {
  useEffect(() => {
    let isMouseDownRef = false;
    let lastMouseXPos: number | null = null;
    let resizeEl: HTMLElement | null = null;
    const table = tableRef.current;

    if (table && isResizable) {
      const onMouseMove = (e: MouseEvent): void => {
        e.preventDefault();
        if (lastMouseXPos && resizeEl && table.parentElement) {
          const diff = e.screenX - lastMouseXPos;

          const resizableElWidth = resizeEl.offsetWidth;
          const tableWidth = table.offsetWidth;

          resizeEl.style.width = `${resizeEl.offsetWidth + diff}px`;
          table.style.width = `${table.offsetWidth + diff}px`;

          if (table.offsetWidth < table.parentElement.offsetWidth) {
            resizeEl.style.width = `${resizableElWidth}px`;
            table.style.width = `${tableWidth}px`;
          }
        }
        lastMouseXPos = e.screenX;
      };

      const onMouseUp = (): void => {
        if (isMouseDownRef === true) {
          resizeEl = null;
          lastMouseXPos = null;
          isMouseDownRef = false;
          removeResizeListeners();
        }
      };

      const removeResizeListeners = (): void => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      const addResizeListeners = (): void => {
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
      };

      const onMouseDown = (e: MouseEvent): void => {
        const element = e.target as HTMLElement;
        if (element.classList.contains("spark-table__resize")) {
          resizeEl = element.parentElement;
          if (
            resizeEl &&
            element.classList.contains("spark-table__resize--left")
          ) {
            resizeEl = resizeEl.previousElementSibling as HTMLElement;
          }
          isMouseDownRef = true;
          addResizeListeners();
        }
      };

      table.addEventListener("mousedown", onMouseDown);

      return () => {
        table.removeEventListener("mousedown", onMouseDown);
      };
    }
  }, [isResizable, tableRef]);
};

export default useColumnResize;
