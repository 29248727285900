import { TimeFormat, TimePeriod } from "./types/time-text-input.types";

const isNumber = (val: string | number): boolean => {
  const numbersOnlyRegex = /^[\d\b]+$/;
  return numbersOnlyRegex.test(val.toString());
};

export const isValidHr = (val: string | number): boolean => isNumber(val);

export const isValidMin = (val: string | number): boolean => isNumber(val);

export const getValidMin = (val: string | number): string => {
  let result = "";
  if (isNumber(val)) {
    const num = Number(val);
    result = (num > 59 ? 59 : num).toString();
  }
  return result;
};

export const TIME_PERIOD_AM_STRING = "am";
export const TIME_PERIOD_PM_STRING = "pm";

export const getAllValidPeriodStrings = (): string[] => [
  TimePeriod.AM,
  TimePeriod.PM,
  TIME_PERIOD_AM_STRING,
  TIME_PERIOD_PM_STRING,
];

export const isValidPeriod = (val: string): boolean => {
  return (
    getAllValidPeriodStrings().find(
      (e) => e.indexOf(val.toLowerCase()) === 0
    ) !== undefined
  );
};

export const getValidPeriod = (val: string): string | false => {
  if (
    TimePeriod.AM.indexOf(val) === 0 ||
    TIME_PERIOD_AM_STRING.indexOf(val) === 0
  ) {
    return TimePeriod.AM;
  } else if (
    TimePeriod.PM.indexOf(val) === 0 ||
    TIME_PERIOD_PM_STRING.indexOf(val) === 0
  ) {
    return TimePeriod.PM;
  } else {
    return false;
  }
};

export const getValidTimeInput24HR = (
  inpVal: string,
  timeFormat: TimeFormat
): string => {
  const inpObj = getValidHrMin(inpVal, timeFormat);
  return getTimeInput24HR(inpObj.hr, inpObj.min, timeFormat, inpObj.period);
};

/**
 *
 * @param _hr
 * @param _min
 * @param timeFormat
 * @param period
 * @returns
 */
export const getTimeInput24HR = (
  _hr: string,
  _min: string,
  timeFormat: TimeFormat,
  period?: string
): string => {
  if (
    !isNumber(_hr) ||
    !isNumber(_min) ||
    (timeFormat === TimeFormat.TWELVE && !period)
  ) {
    return "";
  }
  let hr = parseInt(_hr, 10);
  const min = parseInt(_min, 10);
  if (period) {
    if (period === "a.m." && hr === 12) {
      hr = 0;
    }
    if (period === "p.m." && hr !== 12) {
      hr = hr + 12;
    }
    return `${hr.toString().padStart(2, "0")}:${min
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${_hr.padStart(2, "0")}:${_min.padStart(2, "0")}`;
  }
};

/**
 * Method checks the time input for valid hours and minutes
 * and returns upper bound value if input was invalid
 * @param inpVal 24hr time format string
 * @param timeFormat TimeFormat
 * @returns { hr: string; min: string; period: string }
 */
export const getValidHrMin = (
  inpVal: string,
  timeFormat: TimeFormat
): { hr: string; min: string; period: string } => {
  let [hr, min] = inpVal.split(":").map((e) => parseInt(e, 10));
  let period = "";

  const totalMins = hr * 60 + min;

  if (totalMins >= 24 * 60) {
    hr = 23;
    min = 59;
  } else if (totalMins < 0) {
    hr = 0;
    min = 0;
  }

  if (timeFormat === TimeFormat.TWELVE) {
    if (hr >= 13 && hr <= 23) {
      hr = hr - 12;
      period = TimePeriod.PM;
    } else if (hr >= 12) {
      period = TimePeriod.PM;
    } else if (hr >= 1) {
      period = TimePeriod.AM;
    } else if (hr >= 0) {
      hr = hr + 12;
      period = TimePeriod.AM;
    }
  }

  return {
    hr: hr.toString().padStart(2, "0"),
    min: min.toString().padStart(2, "0"),
    period,
  };
};

export const twentyFourHrToMinutes = (timeInput: string): number => {
  const [hr, min] = timeInput.split(":").map((e) => parseInt(e, 10));
  return hr * 60 + min;
};

export const minutesTo24HrTime = (minutes: number): string => {
  const newHr = Math.floor(minutes / 60)
    .toString()
    .padStart(2, "0");
  const newMin = (minutes % 60).toString().padStart(2, "0");

  return `${newHr}:${newMin}`;
};

export const getMaxTimeByStep = (step: number): string => {
  const minsInDay = 24 * 60;
  if (step === 1) {
    return "23:59";
  } else {
    return minutesTo24HrTime(minsInDay - step);
  }
};

export const getValidTimeAsString = ({
  timeFormat,
  isControlled,
  timeInput,
  defaultTime,
}: {
  timeFormat: TimeFormat;
  isControlled?: boolean;
  timeInput?: string;
  defaultTime?: string;
}): string => {
  let validTime = "";
  if (timeInput || defaultTime) {
    validTime = getValidTimeInput24HR(
      (isControlled ? timeInput : defaultTime) || "",
      timeFormat
    );
  }
  return validTime;
};

export const getTimeDisplayText = (
  timeInput: string,
  timeFormat: TimeFormat
): string => {
  if (timeFormat === TimeFormat.TWENTY_FOUR) {
    return timeInput;
  }
  const obj = getValidHrMin(timeInput, timeFormat);
  return `${obj.hr}:${obj.min} ${obj.period}`;
};
