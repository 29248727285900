import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import {
  array,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  string,
} from "prop-types";
import { useCombinedRefs } from "../utils/useCombinedRefs";
import { FieldLevelMessage } from "../message/field-level-message";
import { MessageStatus } from "../types";
import { restrictPrecisionNum } from "./util/currency-input.util";

export const TextInputBase = forwardRef(
  (
    {
      id,
      maxLength,
      type,
      disabled,
      placeHolder = "",
      name,
      value: valueProp,
      defaultValue,
      isTypeahead,
      softMaxLength,
      onFocus,
      label,
      className,
      status,
      statusMessage,
      typeaheadFormat,
      children,
      optionalLabel,
      multiLine,
      validateOnChange,
      validatePattern,
      validateOnMount,
      characterCount,
      validate,
      onChange,
      onValidate,
      validateOnBlur,
      onBlur,
      isSlider = false,
      isCreditCard = false,
      isExpirationDate = false,
      isCvv = false,
      cardType,
      pattern,
      unifiedFields,
      showPrefix = false,
      showSuffix = false,
      prefixContent,
      suffixContent,
      isCurrency = false,
      prefixAriaLabel,
      suffixAriaLabel,
      precision,
      wrapperDataAttr,
      formatInputValue,
      ...rest
    },
    inputExternalRef
  ) => {
    const [active, setActive] = useState(!!valueProp || !!defaultValue);
    const [focus, setFocus] = useState(false);
    const [valueState, setValueState] = useState(
      valueProp || defaultValue || ""
    );
    const [placeHolderState, setPlaceHolderState] = useState(placeHolder);

    const { current: isControlled } = useRef(valueProp !== undefined);
    const value = isControlled ? valueProp : valueState;

    const messageId = id ? `${id}_message` : undefined;

    const inputRef = useRef(null);
    useCombinedRefs(inputRef, inputExternalRef);

    const cursorIndex = useRef(0);

    const _handleFocus = (event) => {
      setActive(true);
      setFocus(true);
      onFocus && onFocus(event);
    };

    const _handleBlur = (event) => {
      if (event.target.value.length < 1) {
        setActive(false);
        setFocus(false);
      } else {
        setFocus(false);
      }

      if (isCurrency) {
        const valFormatted = restrictPrecisionNum(event, precision);
        if (valFormatted) {
          setValueState(valFormatted);
          onChange?.(event, valFormatted);
        }
      }

      validateOnBlur && validate && _validate(event.target.value);
      onBlur && onBlur(event);
    };

    // Controlled variant in unfocused state: setting the active state to `false`
    // when clearing value and to `true` when filling the previously empty value
    useEffect(() => {
      if (isControlled && !focus) {
        value.length < 1 ? setActive(false) : setActive(true);
      }
    }, [value, isControlled, focus]);

    const _createTypeAheadMatcher = useCallback(() => {
      const matcher = [];
      const format = typeaheadFormat;
      for (let i = 0; i < format.length; i++) {
        if (format.charAt(i) !== "\\" && format.charAt(i - 1) !== "\\") {
          matcher.push({
            placeHolder: true,
            value: format.charAt(i),
          });
        } else if (format.charAt(i - 1) === "\\") {
          matcher.push({
            placeHolder: false,
            value: format.charAt(i - 1) + format.charAt(i),
          });
        }
      }

      return matcher;
    }, [typeaheadFormat]);

    const _typeAhead = useCallback(
      (input) => {
        const matcher = _createTypeAheadMatcher();
        let tempValue = input.value;
        let newValue = "";
        let valueIndex = 0;

        let newCursorIndex = input.selectionStart;
        const isDelete = valueState.length > input.value.length;

        matcher.forEach((matcherTest) => {
          if (matcherTest.placeHolder) {
            tempValue = tempValue.replace(matcherTest.value, "");
          }
        });
        const valueLength = tempValue.length;
        matcher.every((matcherTest, i) => {
          if (valueIndex === valueLength) {
            return false;
          }

          // if the test character is type placeHolder
          if (matcherTest.placeHolder) {
            newValue += matcherTest.value;

            // if the character in the user string is the same as the mask string increase the valueIndex
            if (tempValue.charAt(valueIndex) === matcherTest.value) {
              valueIndex++;
            }

            // you don't want to move the cursor forward when deleting. move it forward if we just inserted an placeholder value
            // or if the current cursor index is in front of the placeholder that was just inserted
            if (
              !isDelete &&
              (i + 1 === newCursorIndex ||
                (newCursorIndex > valueIndex &&
                  newCursorIndex < matcher.length))
            ) {
              newCursorIndex = newCursorIndex + 1;
            }

            return true;
          } else {
            const regex = new RegExp(matcherTest.value);

            if (regex.test(tempValue.charAt(valueIndex))) {
              newValue += tempValue.charAt(valueIndex);
            } else {
              // failed because of an invalid character in the string, stop checking
              // the type-ahead
              return false;
            }

            // valid value was entered, keep checking the input
            valueIndex++;

            return true;
          }
        });

        cursorIndex.current = newCursorIndex;

        return newValue;
      },
      [_createTypeAheadMatcher, valueState.length]
    );

    const _createTypeAheadPlaceHolder = useCallback(
      (value) => {
        const newPlaceHolder = placeHolder.slice(value.length);
        return newPlaceHolder.padStart(placeHolder.length, " ");
      },
      [placeHolder]
    );

    const _handleChange = (event) => {
      const newValue = isTypeahead
        ? _typeAhead(event.target)
        : event.target.value;
      const newPlaceHolder = isTypeahead
        ? _createTypeAheadPlaceHolder(newValue)
        : placeHolder;

      validateOnChange && validate && _validate(newValue);

      setValueState(newValue);
      setPlaceHolderState(newPlaceHolder);

      const formattedValue = formatInputValue
        ? formatInputValue(newValue)
        : newValue;
      setValueState(formattedValue);
      onChange?.(event, formattedValue);
    };

    const _validate = useCallback(
      (value) => {
        onValidate?.(validate(value, validatePattern));
      },
      [onValidate, validate, validatePattern]
    );

    const _adjustHeight = useCallback(() => {
      inputRef.current.style.height = "";
      if (inputRef.current.scrollHeight > 0) {
        const diff =
          inputRef.current.offsetHeight - inputRef.current.clientHeight;
        inputRef.current.style.height =
          inputRef.current.scrollHeight + diff + "px";
      }
    }, []);

    const hidePlaceholder = isCreditCard || isCvv;
    const placeholderNew = hidePlaceholder ? undefined : placeHolder;

    const _createInputAttr = (style = {}) => {
      const attr = {
        id,
        maxLength,
        className: "spark-input__field",
        style,
        name,
        type,
        value,
        placeholder: placeholderNew,
        disabled,
        pattern: typeaheadFormat,
        onFocus: (event) => {
          _handleFocus(event);
        },
        onBlur: (event) => {
          _handleBlur(event);
        },
        onChange: (event) => {
          _handleChange(event);
        },
      };

      if (isTypeahead && !isCreditCard) {
        attr["data-typeahead-format"] = "";
      }

      if (softMaxLength) {
        attr["data-maxlength-soft"] = softMaxLength;
      }

      return attr;
    };

    const _createAttr = () => {
      const attr = {};

      if (status) {
        attr["data-" + status] = "true";
      }

      if (characterCount) {
        attr["data-characters"] = (value && value.length) || 0;
      }

      if (maxLength && characterCount) {
        attr["data-characters-remaining"] =
          (value && maxLength - value.length + "") || maxLength + "";
      }

      if (softMaxLength && characterCount) {
        const charactersLeft = value
          ? softMaxLength - value.length
          : softMaxLength;

        attr["data-characters-remaining"] = charactersLeft + "";

        if (charactersLeft < 0) {
          attr["data-characters-remaining-danger"] = true;
        }
      }

      return { ...attr, ...wrapperDataAttr };
    };

    const renderChildren = (children) => {
      if (isCvv) {
        return <>{children}</>;
      }
      return <span className="spark-input__addon">{children}</span>;
    };

    const renderPlaceHolder = () => (
      <span className="spark-input__placeholder">
        {" "}
        {placeHolderState.split(" ").map((item, key) => (
          <Fragment key={key}>{item}&nbsp;</Fragment>
        ))}{" "}
      </span>
    );

    const renderOptionalLabel = () => (
      <span className="spark-thin">{optionalLabel}</span>
    );

    const renderTextInput = (attr) => (
      <input {...attr} value={value} ref={inputRef} {...rest} />
    );

    const renderTextArea = (attr) => (
      <textarea {...attr} ref={inputRef} {...rest}>
        {value}
      </textarea>
    );

    useEffect(() => {
      validateOnMount && validate && _validate(inputRef.current.value);
      if (isTypeahead) {
        const newValue = _typeAhead(inputRef.current);
        const newPlaceHolder = _createTypeAheadPlaceHolder(newValue);

        setValueState(newValue);
        setPlaceHolderState(newPlaceHolder);
      }
    }, [
      _createTypeAheadPlaceHolder,
      _typeAhead,
      _validate,
      inputRef,
      isTypeahead,
      validate,
      validateOnMount,
    ]);

    useEffect(() => {
      if (isTypeahead && focus) {
        inputRef.current.selectionStart = cursorIndex.current;
        inputRef.current.selectionEnd = cursorIndex.current;
      }
    }, [isTypeahead, focus]);

    useEffect(() => {
      if (multiLine && value) {
        _adjustHeight();
      }
    }, [value, multiLine, _adjustHeight]);

    const doNotShowSparkInputClass = !(
      isCreditCard ||
      isExpirationDate ||
      isCvv
    );

    const componentClasses = classNames(
      {
        active,
        focus: isCreditCard || isExpirationDate ? false : focus,
        "spark-input--addon": !!children,
        "spark-input": doNotShowSparkInputClass,
        "spark-input--has-prefix": showPrefix,
        "spark-input--has-suffix": showSuffix,
        "spark-payment--currency": isCurrency,
      },
      className
    );

    const inputAttr = _createInputAttr();
    const inputForSliderAttr = _createInputAttr({
      height: "3rem",
      lineHeight: "normal",
      padding: "1px, 2px",
    });
    const attr = _createAttr();

    if (isSlider) {
      return renderTextInput(inputForSliderAttr);
    }

    return (
      <label className={componentClasses} {...attr}>
        {isCreditCard && (
          <div aria-hidden="true" className="spark-payment__card-type">
            <i className="spark-icon-credit-card spark-icon--fill spark-icon--md"></i>
          </div>
        )}
        {(multiLine && renderTextArea(inputAttr)) || renderTextInput(inputAttr)}
        <span className="spark-label">
          {label} {optionalLabel && renderOptionalLabel()}
        </span>
        {children && renderChildren(children)}
        {typeaheadFormat && !hidePlaceholder && renderPlaceHolder()}
        {!unifiedFields && (
          <FieldLevelMessage
            id={messageId}
            className="spark-input__message"
            status={status}
            statusMessage={statusMessage}
          />
        )}
        {showPrefix && (
          <span className="spark-input__prefix" aria-label={prefixAriaLabel}>
            {prefixContent}
          </span>
        )}
        {showSuffix && (
          <span
            className="spark-input__suffix"
            aria-hidden="true"
            aria-label={suffixAriaLabel}
          >
            {suffixContent}
          </span>
        )}
      </label>
    );
  }
);

TextInputBase.validate = (value, validatePattern) => {
  // Nothing to validate.
  if (!validatePattern) {
    return undefined;
  }

  const re = new RegExp(validatePattern);

  return re.test(value);
};

TextInputBase.displayName = "TextInputCore";

// Backwards compatibility to support component property. Enum import should be used instead.
TextInputBase.STATUS = MessageStatus;

TextInputBase.propTypes = {
  id: string,

  /** Should the Input count characters. */
  characterCount: bool,

  /** Is the Input multi-line. */
  multiLine: bool,

  /** Is there a max length to the Input. */
  maxLength: number,

  /** Is the max length a suggestion rather than a hard . */
  softMaxLength: number,

  /** If `true`, `TextInput` will be disabled */
  disabled: bool,

  /** Element Nodes, like Icon. */
  children: node,

  /** default input type. */
  type: string,

  /** Additional Input class names. */
  className: string,

  /** Input Label. */
  label: string,

  /** Type-Ahead format for input masking. */
  typeaheadFormat: string,

  /** Is this component a Type-Ahead. */
  isTypeahead: bool,

  /** Name of the Input. */
  name: string.isRequired,

  /** Value of the Input. */
  value: string,

  /** Default value of the Input (Uncontrolled variant) */
  defaultValue: string,

  /** Placeholder. */
  placeHolder: string,

  /** One of Messages Status. */
  status: oneOf([...Object.values(MessageStatus), null]),

  /** Status Message for Inline Message. */
  statusMessage: string,

  /** A callback function run after validation has completed. */
  onValidate: func,

  /** A callback function run when the input loses focus. */
  onBlur: func,

  /** A callback function run when the input gains focus. */
  onFocus: func,

  /** A callback function run when the input value changes. */
  onChange: func,

  /** Optional label. */
  optionalLabel: string,

  /** A function to run to validate the state of the input; it is passed value, validatePattern. */
  validate: func,

  /** The regular expression pattern will be used in the default validate method to determine if an input is in a valid state. */
  validatePattern: string,

  /** Run validation on blur, preffered. */
  validateOnBlur: bool,

  /** Run validation on change. */
  validateOnChange: bool,

  /** Run validation when the component is mounted. */
  validateOnMount: bool,

  /** An array of default card types that should be disabled. */
  disableCardDefaults: array,

  /** Configurations for extending or overwriting supported card types. */
  cardTypes: array,

  /** Determines whether all default card types should be disabled. If set to true, at least one card type has to be provided using the cardTypes option. */
  disableAllCardDefaults: bool,

  /** data attributes that go into wrapper/label tag */
  wrapperDataAttr: object,

  /** formatter method */
  formatInputValue: func,
};

TextInputBase.defaultProps = {
  type: "text",
  validate: TextInputBase.validate,
  validateOnBlur: false,
  validateOnMount: false,
  validateOnChange: false,
  isTypeahead: false,
  unifiedFields: false,
};
