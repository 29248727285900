import React, { ReactElement, forwardRef } from "react";
import classNames from "classnames";
import { RadioButtonProps } from "./radio-button.types";

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      checked,
      className,
      defaultChecked,
      disabled,
      id,
      label,
      name,
      onChange,
      value,
      _privateProps = {},
      ...rest
    },
    ref
  ): ReactElement => {
    const { isRadioGroup } = _privateProps;

    if (!isRadioGroup && checked)
      throw new Error(
        "You tried to use Controlled <RadioButton> component without wrapping it in <RadioButtonGroup>."
      );

    const componentClass = classNames("spark-radio", className);

    const inputProps = {
      checked: checked,
      className: "spark-radio__input",
      defaultChecked: defaultChecked,
      disabled: disabled,
      id,
      name: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange?.(e, e.target.value);
      },
      value: value,
    };

    return (
      <label className={componentClass}>
        <input type="radio" {...inputProps} {...rest} ref={ref} />
        <span className="spark-radio__box" />
        <span className="spark-label">{label}</span>
      </label>
    );
  }
);

RadioButton.displayName = "RadioButton";
