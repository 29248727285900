import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  Fragment,
  useMemo,
  useCallback,
  useContext,
} from "react";
import Separator from "./Separator";
import { DateInputFieldProps, ViolationReason } from "./DateInputField.types";
import classNames from "classnames";
import {
  getDateWithMonthNameText,
  makeDateHtmlValue,
  NOOP,
} from "./util/DateInputField.util";
import { useCombinedRefs } from "../../utils/useCombinedRefs";
import { isString } from "../../utils/checkString";
import { isValidDate } from "../date-input-with-picker/util/datePicker.util";
import {
  isLeapYear,
  withoutTime,
  withoutTimeStr,
} from "../calendar/calendar.util";
import { isValidValue, TypeaheadYear } from "./TypeaheadYear";
import { TypeaheadMonth } from "./TypeaheadMonth";
import { TypeaheadDay } from "./TypeaheadDay";
import { FieldLevelMessage } from "../../message/field-level-message";
import { InputGroupContext } from "../input-group/context/context";
import { InputGroupActionType } from "../input-group/context/reducer.types";

export const DateInputField = forwardRef<HTMLInputElement, DateInputFieldProps>(
  (
    {
      value,
      defaultValue,
      onChange,
      onBlur,
      onFocus,
      label,
      format = "MM-DD-YYYY",
      showDateAsText = false,
      validateMethod,
      onValidate,
      status,
      statusMessage,
      errorMessage,
      displayDateTextMethod = getDateWithMonthNameText,
      YYYYPlaceholderText = "YYYY",
      MMPlaceholderText = "MM",
      DDPlaceholderText = "DD",
      DDAriaLabel = `${label} day`,
      MMAriaLabel = `${label} month`,
      YYYYAriaLabel = `${label} year`,
      children,
      className,
      daysDisabled,
      minDate,
      maxDate,
      disabled,
      daysDisabledErrorLabel = "The entered date is not allowed",
      minDateErrorLabel = "The entered date is earlier than the allowed minimum date",
      maxDateErrorLabel = "The entered date is later than the allowed maximum date",
      validateMethodErrorLabel = "Date is invalid",
      _privateProps = {},
      separator,
      labelRef,
    },
    externalRef
  ): JSX.Element => {
    const { isCalendarOpen, setHasDateInputDisabledDate } = _privateProps;

    const [isAllowedDate, setIsAllowedDate] = useState<boolean>(true);
    const [validationErrorMessage, setValidationErrorMessage] =
      useState<string>();

    const inputRef = useRef<HTMLInputElement>(null);
    useCombinedRefs(inputRef, externalRef);

    const getValidDateParts = (
      date: Date
    ): { day: string; month: string; year: string } => {
      if (!isValidDate(date)) {
        return { day: "", month: "", year: "" };
      }

      return {
        day: date.getDate().toString().padStart(2, "0"),
        month: (date.getMonth() + 1).toString().padStart(2, "0"),
        year: date.getFullYear().toString().padStart(4, "0"),
      };
    };

    const { state, dispatch } = useContext(InputGroupContext);

    const {
      day: dayValue,
      month: monthValue,
      year: yearValue,
    } = useMemo(
      () =>
        value ? getValidDateParts(value) : { day: "", month: "", year: "" },
      [value]
    );

    const {
      day: dayDefaultValue,
      month: monthDefaultValue,
      year: yearDefaultValue,
    } = useMemo(
      () =>
        defaultValue
          ? getValidDateParts(defaultValue)
          : { day: "", month: "", year: "" },
      [defaultValue]
    );

    const isControlled = value !== undefined;

    const [focus, setFocus] = useState(false);

    const inputMmRef =
      useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
    const inputDdRef =
      useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
    const inputYyyyRef =
      useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

    const [day, setDay] = useState<string>(
      isControlled ? dayValue : dayDefaultValue
    );

    const [month, setMonth] = useState<string>(
      isControlled ? monthValue : monthDefaultValue
    );

    const [year, setYear] = useState<string>(
      isControlled ? yearValue : yearDefaultValue
    );

    const prevDateRef = useRef(
      withoutTimeStr(new Date(Number(year), Number(month) - 1, Number(day)))
    );

    // reset prevDateRef if null received
    useEffect(() => {
      if (value === null) {
        prevDateRef.current = "";
      }
    }, [value]);

    const [datesToBeDisabled] = useState(() =>
      daysDisabled?.map(
        (d: Date) =>
          `${d.getFullYear()}-${(d.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`
      )
    );

    const isDisabledDate = useCallback(
      (
        year: string,
        month: string,
        day: string
      ): {
        isDisabled: boolean;
        validationErrorMessage?: string;
        reason?: ViolationReason;
      } => {
        const dateStr = `${year}-${month}-${day}`;
        const dateFromStr = new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10)
        );

        const isDateInDisabledDates = datesToBeDisabled
          ? datesToBeDisabled.includes(dateStr)
          : false;

        const isDateBelowMinDate = minDate
          ? withoutTime(dateFromStr) < withoutTime(minDate)
          : false;

        const isDateAboveMaxDate = maxDate
          ? withoutTime(dateFromStr) > withoutTime(maxDate)
          : false;

        const isCustomValidationPassed = validateMethod
          ? validateMethod?.(
              day,
              month,
              year,
              !!day && !!month && !!day
                ? new Date(`${year}-${month}-${day}`)
                : undefined
            )
          : true;

        const isDisabled =
          isDateInDisabledDates ||
          isDateBelowMinDate ||
          isDateAboveMaxDate ||
          !isCustomValidationPassed;

        const getValidationErrorMessage = (): string => {
          if (isDateInDisabledDates) {
            return daysDisabledErrorLabel;
          } else if (isDateBelowMinDate) {
            return minDateErrorLabel;
          } else if (isDateAboveMaxDate) {
            return maxDateErrorLabel;
          }

          return validateMethodErrorLabel;
        };

        const getReason = (): ViolationReason => {
          if (isDateInDisabledDates) {
            return "daysDisabled";
          } else if (isDateBelowMinDate) {
            return "minDate";
          } else if (isDateAboveMaxDate) {
            return "maxDate";
          }

          return "validateMethod";
        };

        const validationErrorMessage = isDisabled
          ? getValidationErrorMessage()
          : undefined;
        const reason = isDisabled ? getReason() : undefined;

        return { isDisabled, validationErrorMessage, reason };
      },
      [
        datesToBeDisabled,
        minDate,
        maxDate,
        validateMethod,
        validateMethodErrorLabel,
        daysDisabledErrorLabel,
        minDateErrorLabel,
        maxDateErrorLabel,
      ]
    );

    // Inform Calendar that a disabled date has been entered on Date Input. This clears the date selection in Calendar.
    useEffect(() => {
      setHasDateInputDisabledDate?.(!isAllowedDate ?? false);
    }, [isAllowedDate, setHasDateInputDisabledDate]);

    const isAllFieldsEmpty = useCallback((): boolean => {
      if (day || month || year) {
        return false;
      } else {
        return true;
      }
    }, [day, month, year]);

    const isAllFieldsFilled = useCallback((): boolean => {
      if (day && month && year) {
        return true;
      } else {
        return false;
      }
    }, [day, month, year]);

    const _onValidate = useCallback(
      (year: string, month: string, day: string): void => {
        const { isDisabled, validationErrorMessage, reason } = isDisabledDate(
          year,
          month,
          day
        );

        setIsAllowedDate(!isDisabled);
        setValidationErrorMessage(validationErrorMessage);

        onValidate?.(!isDisabled, reason);
      },
      [isDisabledDate, onValidate]
    );

    useEffect(() => {
      if (isControlled) {
        setDay(dayValue);
        setMonth(monthValue);
        setYear(yearValue);
      }
      _onValidate(yearValue, monthValue, dayValue);
      // Remove _onValidate from deps array to prevent unnecessary rerenderings
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isControlled, dayValue, monthValue, yearValue, minDate, maxDate]);

    const dateFormat = format?.split("-");
    const refs = dateFormat.map((format) => {
      switch (format) {
        case "YYYY":
          return inputYyyyRef;
        case "MM":
          return inputMmRef;
        default:
          return inputDdRef;
      }
    });

    const _customFocus = (
      inputElement: HTMLInputElement | null,
      cursorPosition?: number
    ): void => {
      if (inputElement) {
        inputElement.focus();
        if (cursorPosition !== undefined && cursorPosition !== null) {
          inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    };

    const exitCallback = (
      isNext: boolean,
      cursorPosition?: number,
      inputForNext?: string,
      dateInputType: string = "YYYY"
    ): void => {
      const inputOrderPlacement = dateFormat.indexOf(dateInputType);
      const isLastInput = inputOrderPlacement === 2;
      const isFirstInput = inputOrderPlacement === 0;

      if (isNext) {
        if (!isLastInput) {
          const dateEl = dateFormat[inputOrderPlacement + 1];

          if (inputForNext) {
            if (dateEl === "YYYY") {
              const newNextInputValue = inputForNext + (year[0] || "");
              if (isValidValue(newNextInputValue)) setYear(newNextInputValue);
            } else if (dateEl === "DD") {
              const newNextInputValue = inputForNext + (day[0] || "");
              if (isValidValue(newNextInputValue)) setDay(newNextInputValue);
            } else if (dateEl === "MM") {
              const newNextInputValue = inputForNext + (month[0] || "");
              if (isValidValue(newNextInputValue)) setMonth(newNextInputValue);
            }
          }

          _customFocus(refs[inputOrderPlacement + 1].current, cursorPosition);
        }
      } else {
        if (!isFirstInput) {
          _customFocus(refs[inputOrderPlacement - 1].current, cursorPosition);
        }
      }
    };

    const onDateChange = (year: string, month: string, day: string): void => {
      // Check number of max days base on month and year
      const maxDays = getMaxDays(month, year);
      const isTooMuchDays = Number(day) > maxDays;

      if (isTooMuchDays) {
        setDay(String(maxDays));
      }

      _onValidate(year, month, day);

      const resultDate = withoutTimeStr(
        new Date(
          Number(year),
          Number(month) - 1,
          isTooMuchDays ? maxDays : Number(day)
        )
      );

      if (prevDateRef.current !== resultDate && isAllFieldsFilled()) {
        /*
          Below IF condition is to reset the state in controlled variant to what the parent set it last.
          A controlled variant cannot update it's value without it's parent's consent.
          So, after passing the value via onChange, we reset it back to the value what parent set it last.
        */
        if (isControlled) {
          setDay(dayValue);
          setMonth(monthValue);
          setYear(yearValue);
        }
        onChange?.(
          withoutTime(
            new Date(
              Number(year),
              Number(month) - 1,
              isTooMuchDays ? maxDays : Number(day)
            )
          )
        );
        prevDateRef.current = resultDate;
      }

      if (isAllFieldsEmpty()) {
        onChange?.(null);
        prevDateRef.current = "";
      }
    };

    const getMaxDays = (_month: string, _year: string): number => {
      let maxDays;
      if ([4, 6, 9, 11].includes(parseInt(_month))) {
        maxDays = 30;
      } else if (parseInt(_month) === 2) {
        if (isLeapYear(parseInt(_year))) {
          maxDays = 29;
        } else maxDays = 28;
      } else {
        maxDays = 31;
      }
      return maxDays;
    };

    const containerAttrs: { [key: string]: string } = {};

    if (status) {
      containerAttrs["data-" + status] = "true";
    }

    useEffect(() => {
      if (state.isWithinGroup) {
        if (
          (validationErrorMessage && isString(validationErrorMessage)) ||
          statusMessage ||
          errorMessage
        ) {
          dispatch({
            type: InputGroupActionType.HOIST_MESSAGE,
            label,
            status: status || (!isAllowedDate ? "error" : undefined),
            statusMessage:
              statusMessage || errorMessage || validationErrorMessage,
          });
        } else if (status) {
          dispatch({
            type: InputGroupActionType.HOIST_MESSAGE,
            status: status || (!isAllowedDate ? "error" : undefined),
          });
        }
      }
    }, [
      validationErrorMessage,
      statusMessage,
      errorMessage,
      state.isWithinGroup,
      dispatch,
      status,
      isAllowedDate,
      label,
    ]);

    return (
      <label
        ref={labelRef}
        className={classNames(
          "spark-input",
          "spark-date",
          {
            active: !!year || !!month || !!day || focus || isCalendarOpen,
            focus,
            disabled,
            "spark-input-group__item": state.isWithinGroup,
          },
          className
        )}
        onFocus={(e): void => {
          const classNames = e.target.className;
          if (
            classNames.search(/calendar/) !== -1 ||
            classNames?.search(/spark-select__input/) !== -1
          ) {
            return;
          }
          setFocus(true);
        }}
        onBlur={(): void => setFocus(false)}
        data-error={!isAllowedDate ? true : undefined}
        {...containerAttrs}
      >
        <span className="spark-input__fields">
          {dateFormat.map((dateType, index) => {
            switch (dateType) {
              case "YYYY":
                return (
                  <Fragment key={index}>
                    <TypeaheadYear
                      ref={inputYyyyRef}
                      maxLength={4}
                      value={year}
                      disabled={disabled}
                      ariaLabel={YYYYAriaLabel}
                      placeholder={YYYYPlaceholderText || "0000"}
                      onChange={(e, value): void => {
                        _onValidate(value, month, day);
                        setYear(value);
                      }}
                      onBlur={(e, value): void => {
                        onDateChange(value, month, day);
                        onBlur?.();
                      }}
                      onFocus={(): void => onFocus?.(true)}
                      exitCallback={(
                        isNext,
                        cursorPosition,
                        inputForNext
                      ): void =>
                        exitCallback(
                          isNext,
                          cursorPosition,
                          inputForNext,
                          "YYYY"
                        )
                      }
                    />
                    {index < 2 ? <Separator separator={separator} /> : null}
                  </Fragment>
                );
              case "MM":
                return (
                  <Fragment key={index}>
                    <TypeaheadMonth
                      ref={inputMmRef}
                      maxLength={2}
                      value={month}
                      disabled={disabled}
                      ariaLabel={MMAriaLabel}
                      placeholder={MMPlaceholderText || "00"}
                      onChange={(e, value): void => {
                        _onValidate(year, value, day);
                        setMonth(value);
                      }}
                      onBlur={(e, value): void => {
                        onDateChange(year, value, day);
                        onBlur?.();
                      }}
                      onFocus={(): void => onFocus?.(true)}
                      exitCallback={(
                        isNext,
                        cursorPosition,
                        inputForNext
                      ): void =>
                        exitCallback(isNext, cursorPosition, inputForNext, "MM")
                      }
                    />
                    {index < 2 ? <Separator separator={separator} /> : null}
                  </Fragment>
                );
              default:
                return (
                  <Fragment key={index}>
                    <TypeaheadDay
                      ref={inputDdRef}
                      maxLength={2}
                      value={day}
                      ariaLabel={DDAriaLabel}
                      disabled={disabled}
                      placeholder={DDPlaceholderText || "00"}
                      maxDays={getMaxDays(month, year)}
                      onChange={(e, value): void => {
                        _onValidate(year, month, value);
                        setDay(value);
                      }}
                      onBlur={(e, value): void => {
                        onDateChange(year, month, value);
                        onBlur?.();
                      }}
                      onFocus={(): void => onFocus?.(true)}
                      exitCallback={(
                        isNext,
                        cursorPosition,
                        inputForNext
                      ): void =>
                        exitCallback(isNext, cursorPosition, inputForNext, "DD")
                      }
                    />
                    {index < 2 ? <Separator separator={separator} /> : null}
                  </Fragment>
                );
            }
          })}
        </span>
        <input
          className="spark-input__field"
          type="date"
          ref={inputRef}
          onChange={NOOP}
          disabled={disabled}
          value={isAllowedDate ? makeDateHtmlValue(year, month, day) : ""}
          style={{ display: "none" }}
        />
        <span className="spark-label">{label}</span>
        {showDateAsText && !focus && (
          <div className="spark-input__overlay">
            {displayDateTextMethod(month, year, day)}
          </div>
        )}
        {children}
        {!state.isWithinGroup &&
          ((validationErrorMessage && isString(validationErrorMessage)) ||
            statusMessage ||
            errorMessage) && (
            <FieldLevelMessage
              status={status}
              statusMessage={
                statusMessage || errorMessage || validationErrorMessage
              }
            />
          )}
      </label>
    );
  }
);

DateInputField.displayName = "DateInputField";
