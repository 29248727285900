import classNames from "classnames";
import React, {
  KeyboardEventHandler,
  MouseEvent,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import { getBreakpoint, renderIconClassName } from "../utils";
import { useCombinedRefs } from "../utils/useCombinedRefs";
import { IconSize } from "../types";
import {
  MultiActionFABPlacement,
  FloatingActionButtonProps,
  FloatingActionButtonWithForwardRef,
} from "./floating-action-button.types";
import ClientOnlyPortal from "../utils/dom/ClientOnlyPortal";
import { ConditionalWrapper } from "../utils/ConditionalWrapper";

const WithBDO = (Component: ReactNode, dir = "rtl"): ReactElement => (
  <bdo dir={dir}>{Component}</bdo>
);

const allBreakpoints = ["xs", "sm", "md", "lg", "xl"];

export const FloatingActionButtonWithOptions = React.forwardRef(
  (
    {
      icon,
      ariaLabel,
      collapseButtonAriaLabel = "Collapse Menu",
      label,
      iconSize = IconSize.MEDIUM,
      multiActionPlacement = MultiActionFABPlacement.RIGHT,
      showUnderlay,
      underlayBreakPoint,
      autoClose = true,
      onExpand,
      onCollapse,
      actions,
      onActionClick,
      condensed,
      className,
      rtl,
      fabGroupContainerProps = {},
      portalWrapperClassName,
      ...rest
    }: FloatingActionButtonProps,
    expandBtnRefInp
  ): ReactElement => {
    const containerRef = useRef<HTMLDivElement>(null);

    const expandBtnRef = useRef<HTMLButtonElement>(null);
    useCombinedRefs(expandBtnRef, expandBtnRefInp);

    const collapseBtnRef = useRef<HTMLButtonElement>(null);

    const [expanded, setExpanded] = useState(false);
    const [underlayVisible, setUnderlayVisible] = useState(false);

    const containerClassName = classNames(
      "spark-fab-group",
      {
        "spark-fab-group--sm": condensed,
        [`spark-fab-group--${multiActionPlacement}`]: multiActionPlacement,
        expanded,
      },
      fabGroupContainerProps.className,
      className
    );
    const expandBtnClassName = classNames("spark-fab-group__expand", {
      "spark-fab-group__expand--extended": label,
    });
    const iconClassName = classNames(
      { [`${renderIconClassName(icon)}`]: icon },
      { [`spark-icon--${iconSize}`]: icon && iconSize && !label }
    );

    const getUnderlayVisibleByBreakpoint = (): boolean => {
      if (!showUnderlay || !underlayBreakPoint) {
        return true;
      }
      const parsedBreakpoint = parseInt(underlayBreakPoint.toString());
      const breakPoint = Number.isInteger(parsedBreakpoint)
        ? parsedBreakpoint
        : underlayBreakPoint;
      if (Number.isInteger(breakPoint)) {
        return (
          underlayBreakPoint > 0 && window.innerWidth <= underlayBreakPoint
        );
      } else {
        const setBreakpointIndex = allBreakpoints.indexOf(
          underlayBreakPoint.toString()
        );
        const currentBreakpointIndex = allBreakpoints.indexOf(
          getBreakpoint(window.innerWidth) || ""
        );
        return (
          currentBreakpointIndex <= setBreakpointIndex &&
          setBreakpointIndex !== -1
        );
      }
    };

    const onExpandClick = (): void => {
      onExpand?.();
      setExpanded(!expanded);
      if (showUnderlay) {
        setUnderlayVisible(getUnderlayVisibleByBreakpoint());
      }
      setTimeout(() => collapseBtnRef.current?.focus(), 0);
    };

    const onCollapseClick = (): void => {
      if (!expanded) {
        return;
      }
      onCollapse?.();
      setExpanded(!expanded);
      if (showUnderlay) {
        setUnderlayVisible(false);
      }
      setTimeout(() => expandBtnRef.current?.focus(), 0);
    };

    /*
     * Expand Button
     */
    const renderExpandButton = (): ReactElement => (
      <button
        type="button"
        {...rest}
        ref={expandBtnRef}
        className={expandBtnClassName}
        aria-haspopup="true"
        aria-expanded={expanded}
        tabIndex={expanded ? -1 : undefined}
        aria-label={icon && ariaLabel}
        onClick={onExpandClick}
      >
        {icon && <i className={iconClassName} aria-hidden="true"></i>}
        {label}
      </button>
    );

    /*
     * Collapse Button
     */
    const renderCollapseButton = (): ReactElement => (
      <button
        type="button"
        ref={collapseBtnRef}
        className="spark-fab-group__collapse"
        aria-label={collapseButtonAriaLabel}
        onClick={onCollapseClick}
      >
        <i
          className="spark-icon-math-add spark-icon--md"
          aria-hidden="true"
        ></i>
      </button>
    );

    /*
     * Actions group
     */
    const renderOptions = (): ReactElement => (
      <div className="spark-fab-group__options">
        {actions?.map(
          (
            {
              name: actionName,
              icon: actionIcon,
              label: actionLabel,
              ariaLabel: actionAriaLabel,
            },
            index
          ) => {
            const actionIconClassName = classNames(
              {
                [`${renderIconClassName(actionIcon)}`]: actionIcon,
              },
              { [`spark-icon--${iconSize}`]: actionIcon && iconSize }
            );
            const actionBtnClassName = classNames("spark-fab-option", {
              "spark-fab-option--label": actionLabel,
            });
            const actionClickHandler = (
              e: MouseEvent<HTMLButtonElement>
            ): void => {
              if (autoClose) {
                onCollapseClick();
              }
              onActionClick?.(e, actionName);
            };
            return (
              <button
                type="button"
                key={index}
                name={actionName}
                className={actionBtnClassName}
                aria-label={actionAriaLabel}
                onClick={actionClickHandler}
              >
                {actionLabel && <span>{actionLabel}</span>}
                {actionIcon && (
                  <i className={actionIconClassName} aria-hidden="true"></i>
                )}
              </button>
            );
          }
        )}
      </div>
    );

    const onContainerKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
      if (!showUnderlay) {
        return;
      }
      if (e.key === "Escape") {
        onCollapseClick();
      } else if (e.key === "Tab") {
        const fabFocusableEls = containerRef.current?.querySelectorAll(
          'a[href]:not([tabindex^="-"]):not([disabled]), button:not([tabindex^="-"]):not([disabled]), [tabindex]:not([tabindex^="-"]):not([disabled]), [tabindex="0"]'
        );
        if (fabFocusableEls && fabFocusableEls.length > 0) {
          const focusableElements = Array.prototype.slice.call(fabFocusableEls);

          if (focusableElements.length === 1) {
            e.preventDefault();
          } else if (
            e.shiftKey &&
            document.activeElement === focusableElements[0]
          ) {
            e.preventDefault();
            focusableElements[focusableElements.length - 1].focus();
          } else if (
            !e.shiftKey &&
            document.activeElement ===
              focusableElements[focusableElements.length - 1]
          ) {
            e.preventDefault();
            focusableElements[0].focus();
          }
        }
      }
    };

    const content = (
      <>
        <div
          {...fabGroupContainerProps}
          ref={containerRef}
          className={containerClassName}
          onKeyDown={onContainerKeyDown}
        >
          {renderExpandButton()}
          {renderCollapseButton()}
          {renderOptions()}
        </div>
        {showUnderlay && (
          <ClientOnlyPortal selector="body">
            <ConditionalWrapper
              condition={!!portalWrapperClassName}
              wrapper={(children): ReactElement => (
                <div className={portalWrapperClassName}>{children}</div>
              )}
            >
              <div
                id="spark-underlay"
                data-interactive
                className="no-animate"
                hidden={!underlayVisible}
                onClick={onCollapseClick}
              />
            </ConditionalWrapper>
          </ClientOnlyPortal>
        )}
      </>
    );

    return rtl && !multiActionPlacement ? WithBDO(content) : content;
  }
) as FloatingActionButtonWithForwardRef;

FloatingActionButtonWithOptions.displayName = "FloatingActionButtonWithOptions";
