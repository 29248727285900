import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  ReactElement,
} from "react";
import classNames from "classnames";
import Checkbox from "../checkbox";
import { MultiSelectProps, MultiSelectOption } from "./multi-select.types";
import { MessageStatus } from "../types";

export const MultiSelect = ({
  className,
  disabled,
  name,
  native,
  options: optionsProp,
  size,
  status,
  statusMessage,
  subtitle,
  title,
  value: valueProp,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: MultiSelectProps): ReactElement => {
  const [valueState, setValueState] = useState<string[]>();
  const { current: isControlled } = useRef(valueProp !== undefined);
  const value = isControlled ? valueProp : valueState;

  if (optionsProp?.some((option) => typeof option === "string"))
    console.error(
      "You tried to pass a select option as a string. It should be an object with the mandatory property 'label'."
    );

  // Unicode character as blank space to fill the height
  const emptyOptions: MultiSelectOption[] = [{ label: " ", disabled: true }];

  // Empty state handling (no options provided or loaded)
  const options =
    optionsProp && optionsProp.length > 0 ? optionsProp : emptyOptions;

  const calculateSize = (): number => {
    let size = 0;

    const countOptions = (options?: MultiSelectOption[]): void => {
      options?.forEach((option) => {
        if (typeof option.value === "string" || option.value === undefined) {
          // add 1 for an option
          size += 1;
        }
        if (typeof option.value === "object") {
          // add 1 for a group title
          size += 1;
          countOptions(option.value);
        }
      });
    };

    countOptions(options);

    return size;
  };

  const hasTitle = !!title || !!subtitle;
  const renderHeader = (): ReactElement | null =>
    hasTitle ? (
      <span className="spark-multi-select__label">
        {title ? `${title} ` : null}
        {subtitle ? (
          <span className="spark-multi-select__label--small">{subtitle}</span>
        ) : null}
      </span>
    ) : null;

  const renderMessage = (): ReactElement | null =>
    status && statusMessage ? (
      <span className="spark-select__message">{statusMessage}</span>
    ) : null;

  const _onChangeHandlerNative: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { options } = e.target;
    const newValue = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        newValue.push(options[i].value);
      }
    }
    setValueState(newValue);
    onChange?.(e, newValue);
  };

  const _onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value: eventValue } = e.target;

    let newValue = [];

    if (value?.includes(eventValue)) {
      newValue = value.filter((item) => item !== eventValue);
    } else {
      newValue = value ? [...value, eventValue] : [eventValue];
    }

    setValueState(newValue);
    onChange?.(e, newValue);
  };

  const renderOptions = (
    options: MultiSelectOption[]
  ): (ReactElement | null)[] =>
    options.map((option, index) => {
      const key = `${option.label.substring(0, 10)}-${index}`;

      if (typeof option.value === "string" || option.value === undefined) {
        return (
          <option key={key} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        );
      }

      if (typeof option.value === "object") {
        return (
          <optgroup key={key} label={option.label}>
            {renderOptions(option.value)}
          </optgroup>
        );
      }

      return null;
    });

  const renderInputs = (
    options: MultiSelectOption[]
  ): (ReactElement | null)[] =>
    options.map((option, index) => {
      if (typeof option.value === "string" || option.value === undefined) {
        const isChecked =
          value?.includes(option.value || option.label) || false;

        return (
          <Checkbox
            className={classNames({
              "spark-mar-t-1": index === 0,
            })}
            key={`${option.value}-${option.label}`}
            label={option.label}
            checked={isChecked}
            value={option.value || option.label}
            disabled={option.disabled || disabled}
            onChange={_onChangeHandler}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        );
      }

      if (typeof option.value === "object") {
        return (
          <div
            key={option.label}
            className="spark-multi-select__group"
            aria-label={option.label}
          >
            <span className="spark-multi-select__group__label">
              {option.label}
            </span>
            {renderInputs(option.value)}
          </div>
        );
      }

      return null;
    });

  const multiSelectClasses = classNames(
    "spark-multi-select",
    { "spark-multi-select--no-title": !hasTitle },
    className
  );

  const statusAttr = {} as any;

  switch (status) {
    case MessageStatus.ERROR:
      statusAttr["data-error"] = "true";
      break;
    case MessageStatus.WARNING:
      statusAttr["data-warning"] = "true";
      break;
    case MessageStatus.SUCCESS:
      statusAttr["data-success"] = "true";
      break;
    case MessageStatus.INFO:
      statusAttr["data-info"] = "true";
      break;
    default:
      break;
  }

  // stringify options as a fix for correctly comparing objects in dependency array
  // otherwise, uncontrolledDefaultValue is calculated on every render
  const optionsJsonString = JSON.stringify(options);
  const uncontrolledDefaultValue: string[] | undefined = useMemo(() => {
    const optionsObject = JSON.parse(optionsJsonString);
    let defaultValue: string[] = [];

    const calculateDefaultValue = (
      optionsObject?: MultiSelectOption[]
    ): void => {
      optionsObject?.forEach((option) => {
        if (typeof option.value === "string" || option.value === undefined) {
          if (option.selected) {
            defaultValue = [...defaultValue, option?.value || option?.label];
          }
        }
        if (typeof option.value === "object") {
          calculateDefaultValue(option.value);
        }
      });
    };

    calculateDefaultValue(optionsObject);

    return defaultValue?.length ? defaultValue : undefined;
  }, [optionsJsonString]);

  useEffect(() => {
    if (!native) {
      setValueState(uncontrolledDefaultValue);
    }
  }, [uncontrolledDefaultValue, native]);

  const renderNativeSelect = (): ReactElement => (
    <label className={multiSelectClasses} {...statusAttr}>
      <select
        name={name}
        className="spark-multi-select__input"
        size={size || calculateSize()}
        value={value}
        defaultValue={uncontrolledDefaultValue}
        disabled={disabled}
        onChange={_onChangeHandlerNative}
        onBlur={onBlur}
        onFocus={onFocus}
        {...statusAttr}
        {...rest}
        multiple
      >
        {renderOptions(options)}
      </select>

      {renderHeader()}
      {renderMessage()}
    </label>
  );

  const renderCustomSelect = (): ReactElement => (
    <fieldset
      className={multiSelectClasses}
      aria-label={`${title || ""} ${subtitle || ""}`}
      {...statusAttr}
      size={size || calculateSize()}
      disabled={disabled}
      role="group"
    >
      {renderHeader()}
      <fieldset
        className="spark-multi-select__container"
        role="listbox"
        name={name}
      >
        {renderInputs(options)}
      </fieldset>
      {renderMessage()}
    </fieldset>
  );

  return native ? renderNativeSelect() : renderCustomSelect();
};

MultiSelect.displayName = "MultiSelect";
