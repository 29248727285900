import React, { useEffect, useMemo, useState } from "react";
import {
  monthNamesDefault,
  nextYearsList,
} from "../date-input-select/util/DateInputSelect.util";
import { SelectForCalendar } from "./SelectForCalendar";
import { Options } from "../../select-input/select-input.types";
import { ListItem } from "../date-input-select/DateInputSelect.types";

type MonthAndYearOptionsProps = {
  month: number;
  year: number;
  setMonth: (month: number) => void;
  setYear: (year: number) => void;
  monthsList?: Options[];
  yearsList?: Options[];
  disabled?: boolean;
  /** Note: minDate & maxDate are aquaired only when navigateToDisabledMonths === false */
  minDate?: Date | null;
  maxDate?: Date | null;
};

export const MonthAndYearOptions = ({
  month,
  year,
  setMonth,
  setYear,
  monthsList: monthsProp,
  yearsList: yearsProp,
  disabled,
  minDate,
  maxDate,
}: MonthAndYearOptionsProps): JSX.Element => {
  const initialMonthsList = useMemo(
    () => [...(monthsProp || monthNamesDefault)],
    [monthsProp]
  );
  const initialYearsList = useMemo(
    () => [...(yearsProp || nextYearsList)],
    [yearsProp]
  );

  const [monthsList, setMonthsList] = useState<Options[] | ListItem[]>(
    initialMonthsList
  );
  const [yearsList, setYearsList] = useState<Options[] | ListItem[]>(
    initialYearsList
  );

  const minDateYear = minDate?.getFullYear();
  const minDateMonth = minDate && minDate.getMonth() + 1;
  const maxDateYear = maxDate?.getFullYear();
  const maxDateMonth = maxDate && maxDate.getMonth() + 1;

  // Proxy for setYear method
  // When month number is disabled for new year number, shift calendar to first available month
  const _setYearWithMonthAdjustment = (year: number): void => {
    if (minDate || maxDate) {
      if (year === minDateYear && year !== maxDateYear) {
        minDateMonth && minDateMonth > month && setMonth(minDateMonth);
      } else if (year === maxDateYear && year !== minDateYear) {
        maxDateMonth && maxDateMonth < month && setMonth(maxDateMonth);
      } else if (
        minDateMonth &&
        maxDateMonth &&
        year === maxDateYear &&
        year === minDateYear
      ) {
        if (maxDateMonth < month) {
          setMonth(maxDateMonth);
        } else if (minDateMonth > month) {
          setMonth(minDateMonth);
        }
      }
    }
    setYear(year);
  };

  useEffect(() => {
    if (minDate || maxDate) {
      /**
       *  Adjust yearsList to inactive dates
       * */

      // Remove inactive years options
      const filteredYearsListMin = initialYearsList.filter((yearItem) => {
        // Remove years below minDate
        if (minDateYear) {
          return (
            (minDateYear && Number(yearItem.value) >= minDateYear) ||
            Number(yearItem.value) === year
          );
        } else return true;
      });
      const filteredYearsList = filteredYearsListMin.filter((yearItem) => {
        // Remove years above maxDate
        if (maxDateYear) {
          return (
            (maxDateYear && Number(yearItem.value) <= maxDateYear) ||
            Number(yearItem.value) === year
          );
        } else return true;
      });

      // Disable the year select option when the visible calendar year is outside the min/max range.
      // Useful in inline Calendar when, e.g., minDate~2030 and initial date is initially
      // set to ~2023 (e.g. due to lack of proper verification by the developer)
      const _yearsList = filteredYearsList.map((yearItem) => {
        if (
          (minDateYear && Number(yearItem.value) < minDateYear) ||
          (maxDateYear && Number(yearItem.value) > maxDateYear)
        ) {
          return {
            ...yearItem,
            disabled: true,
          };
        } else return yearItem;
      });

      setYearsList(_yearsList);

      /**
       *  Adjust monthsList to inactive dates
       * */

      // Clone array
      const _monthsList = initialMonthsList.map((el) => ({ ...el }));

      // Disable months when current calendar year === minDateYear, but maxDateYear is different or undefined
      if (year === minDateYear && year !== maxDateYear) {
        _monthsList.forEach((monthItem) => {
          if (minDateMonth && Number(monthItem.value) < minDateMonth) {
            monthItem.disabled = true;
          }
        });
      }

      // Disable months when current calendar year === maxDateYear, but minDateYear is different or undefined
      if (year === maxDateYear && year !== minDateYear) {
        _monthsList.forEach((monthItem) => {
          if (maxDateMonth && Number(monthItem.value) > maxDateMonth) {
            monthItem.disabled = true;
          }
        });
      }

      // Disable months when current calendar year is the same as minDateYear && maxDateYear
      if (year === maxDateYear && year === minDateYear) {
        _monthsList.forEach((monthItem) => {
          if (
            (maxDateMonth && Number(monthItem.value) > maxDateMonth) ||
            (minDateMonth && Number(monthItem.value) < minDateMonth)
          ) {
            monthItem.disabled = true;
          }
        });
      }

      // Disable all months for disabled years
      if (
        (minDateYear && year < minDateYear) ||
        (maxDateYear && year > maxDateYear)
      ) {
        _monthsList.forEach((monthItem) => {
          monthItem.disabled = true;
        });
      }

      setMonthsList(_monthsList);
    } else {
      setYearsList(initialYearsList);
      setMonthsList(initialMonthsList);
    }
  }, [
    initialMonthsList,
    initialYearsList,
    maxDate,
    maxDateMonth,
    maxDateYear,
    minDate,
    minDateMonth,
    minDateYear,
    year,
  ]);

  return (
    <>
      <SelectForCalendar
        options={monthsList}
        value={month.toString()}
        selectName="month"
        onChange={setMonth}
        disabled={disabled}
      />
      <SelectForCalendar
        options={yearsList}
        value={year.toString()}
        selectName="year"
        onChange={_setYearWithMonthAdjustment}
        disabled={disabled}
      />
    </>
  );
};
