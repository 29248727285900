import { ReactNode } from "react";
import { SelectInputProps } from "../../select-input/select-input.types";
import { CheckBoxPaymentProps } from "./default-payment.types";

export type PaymentGroupTypes = {
  paymentTypes: SelectInputProps;
  children: ReactNode;
  defaultCheckBoxProps: CheckBoxPaymentProps;
};

export enum PaymentOptions {
  creditCard = "CreditCardFieldset",
  crypto = "CryptoCurrency",
  cheque = "Cheque",
  paypal = "Paypal",
  bankTransfer = "BankTransfer",
}
