import { CALENDAR_MONTHS } from "./calendar.util";
import React from "react";

type MonthAndYearLabelProps = {
  month: number;
  year: number;
};

export const MonthAndYearLabel = ({
  month,
  year,
}: MonthAndYearLabelProps): JSX.Element => {
  const yearCalculated = month > 12 ? year + 1 : year;
  const monthCalculated = month > 12 ? 1 : month;
  const monthName =
    Object.keys(CALENDAR_MONTHS)[
      Math.max(0, Math.min(monthCalculated - 1, 11))
    ];

  return (
    <span>
      {monthName} {yearCalculated}
    </span>
  );
};

MonthAndYearLabel.displayName = "MonthAndYearLabel";
