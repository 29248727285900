import React, {
  ReactElement,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useClick,
  useInteractions,
  FloatingFocusManager,
  Placement,
  arrow,
  FloatingPortal,
  MiddlewareData,
  useRole,
  useMergeRefs,
} from "@floating-ui/react";
import { PopoverProps } from "./popover.types";
import classNames from "classnames";
import { get } from "../utils";
import { useCombinedPopoverRefs } from "./useCombinedPopoverRefs";
import { ConditionalWrapper } from "../utils/ConditionalWrapper";

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      anchorX,
      anchorY,
      children,
      className,
      closeButton,
      dismissible = true,
      id,
      onClose,
      onOpenChange: setControlledOpen,
      open: controlledOpen,
      toggleEl,
      toggleElWrapperStyle,
      toggleElWrapperClassName,
      toggleElWrapperRef,
      contentCloseElQuery,
      autoFlip = true,
      onClick,
      portalWrapperClassName,
      ...rest
    },
    containerExternalRef
  ): ReactElement => {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    const arrowRef = useRef(null);
    const [placementProp, setPlacementProp] = useState<Placement | undefined>();
    type Arrow = "below" | "above" | "right" | "left";
    const [arrowDirection, setArrowDirection] = useState<Arrow | undefined>();

    const childType: any = toggleEl?.type;
    const isBadge =
      React.isValidElement(toggleEl) &&
      (childType.displayName === "Badge" || childType.displayName === "Avatar");

    const { refs, floatingStyles, context, middlewareData, placement } =
      useFloating({
        open,
        onOpenChange: (isOpen, e) => {
          setOpen(isOpen);
          if (!isOpen && e) {
            onClose?.(e);
          }
        },
        // use pre-defined positions
        placement: placementProp,
        // middleware are options to position (and customize) the popover
        middleware: [
          offset({
            mainAxis: 8,
            // crossAxis: 130, // for custom use cases when pre-define postions will not solve problem
          }),
          // popover flips to any placement around the target element
          flip({
            fallbackAxisSideDirection: "end",
            fallbackPlacements: !autoFlip ? [] : undefined,
          }),
          // popover shifts only along an axis
          shift({ mainAxis: true, crossAxis: false }),
          arrow({
            element: arrowRef,
          }),
        ],
        transform: false,
        whileElementsMounted: autoUpdate,
      });

    useCombinedPopoverRefs(refs.floating, containerExternalRef);
    const toggleElRef = useMergeRefs([
      refs.setReference,
      (toggleEl as any)?.ref,
    ]);

    /**
     * Map Floating-UI library popover position name to Spark's popover position
     * @param  {string} term Name of Floating-UI's position
     * @return {string}      Name of Spark position
     */
    const matchPositionKey = useCallback(
      (term: string): Placement | undefined => {
        switch (term) {
          case "center-top":
            return "top";
          case "center-bottom":
            return "bottom";
          case "right-bottom":
            return "bottom-start";
          case "right-middle":
            return "right";
          case "right-top":
            return "top-start";
          case "left-bottom":
            return "bottom-end";
          case "left-middle":
            return "left";
          case "left-top":
            return "top-end";
          default:
            return "bottom";
        }
      },
      []
    );

    /**
     * Translate Floating-UI library popover position name to Spark's caret position
     * @param  {string} term Name of Floating-UI's position
     * @return {string}      Name of Spark caret
     */
    const matchCaretKey = useCallback((term: string): Arrow => {
      switch (term) {
        case "top":
          return "above";
        case "bottom":
          return "below";
        case "bottom-start":
          return "below";
        case "right":
          return "right";
        case "top-start":
          return "above";
        case "bottom-end":
          return "below";
        case "left":
          return "left";
        case "top-end":
          return "above";
        default:
          return "below";
      }
    }, []);

    /**
     * Save selected popover position key
     * @param  {string} placement Name of Floating-UI's position
     * @return {void}
     */
    const findKey = useCallback(
      (placement: string): void => {
        setPlacementProp(matchPositionKey(placement));
      },
      [matchPositionKey]
    );

    /**
     * Save caret position key from selected popover
     * @param  {string} placement Name of Floating-UI's position
     * @return {void}
     */
    const findCaretKey = useCallback(
      (popoverPlacement: string): void => {
        setArrowDirection(matchCaretKey(popoverPlacement));
      },
      [matchCaretKey]
    );

    useEffect(() => {
      const placementTerm = `${anchorX}-${anchorY}`;
      findKey(placementTerm);
      findCaretKey(placement);
    }, [findCaretKey, findKey, placement, anchorX, anchorY]);

    /**
     * Get and apply the arrow coordinates from Floating-UI's useFloating hook
     * @param  {string} data Data from the middleWareData property on useFloating hook
     * @param  {string} arrow Name of Spark's caret position
     * @return {Object}       Name of Spark caret
     */
    const getArrowCoords = (
      data: MiddlewareData,
      arrow: string | undefined
    ): { top: string; left: string; margin?: string } | undefined => {
      const ARROW_SIZE = 16.97;
      const ARROW_X = data.arrow?.x ?? 0;
      const ARROW_Y = data.arrow?.y ?? 0;
      switch (arrow) {
        case "above":
          return {
            top: "100%",
            left: ARROW_X + ARROW_SIZE / 2 + "px",
            margin: "0 -1px",
          };
        case "below":
          return { top: "0px", left: ARROW_X + ARROW_SIZE / 2 + "px" };
        case "right":
          return { top: ARROW_Y + ARROW_SIZE / 2 + "px", left: "0px" };
        case "left":
          return {
            top: ARROW_Y + ARROW_SIZE / 2 + "px",
            left: "100%",
            margin: "0 -1px",
          };
      }
    };

    const click = useClick(context, {
      enabled: controlledOpen == null,
    });

    const dismissal = useDismiss(context, {
      enabled: true,
      escapeKey: dismissible,
      outsidePress: dismissible,
    });

    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      dismissal,
      role,
    ]);

    const _onContentClick = (
      e:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.KeyboardEvent<HTMLDivElement>
    ): void => {
      const clickedElement = e.target;
      if (
        contentCloseElQuery &&
        (clickedElement as HTMLElement).matches(contentCloseElQuery)
      ) {
        e.preventDefault();
        onClose?.(e);
        setOpen(false);
      }
    };

    const renderPopoverContent = (): ReactElement => {
      return (
        <ConditionalWrapper
          condition={!!portalWrapperClassName}
          wrapper={(children): ReactElement => (
            <div className={portalWrapperClassName}>{children}</div>
          )}
        >
          <div
            id={id}
            aria-modal={true}
            role="dialog"
            className={classNames(
              "spark-popover__content",
              { active: open },
              className
            )}
            ref={refs.setFloating}
            {...getFloatingProps()}
            {...rest}
            onClick={_onContentClick}
            onKeyDown={(e): void => {
              if (e.key === "Enter" || e.key === " ") {
                _onContentClick(e);
              }
            }}
            style={{ ...floatingStyles, ...rest.style }}
          >
            {(closeButton || (!dismissible && closeButton !== false)) && (
              <a
                role="button"
                className="spark-popover__close"
                aria-label="Close"
                title="Close"
                tabIndex={0}
                onClick={(e): void => {
                  onClose?.(e);
                  setOpen(false);
                }}
                onKeyDown={(e): void => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    onClose?.(e);
                    setOpen(false);
                  }
                }}
              />
            )}
            {children}
            <span
              ref={arrowRef}
              id="arrow"
              className="spark-popover__caret"
              data-position={arrowDirection}
              style={getArrowCoords(middlewareData, arrowDirection)}
            ></span>
          </div>
        </ConditionalWrapper>
      );
    };

    const additionalProps = isBadge
      ? { _privateProps: { hasPopover: true } }
      : {};

    const renderPopoverTrigger = (): ReactElement | null => {
      if (React.isValidElement(toggleEl)) {
        return (
          <div
            style={toggleElWrapperStyle}
            className={classNames(
              "spark-popover",
              {
                "popover-active": open,
              },
              toggleElWrapperClassName
            )}
            ref={toggleElWrapperRef}
          >
            {cloneElement(toggleEl as ReactElement, {
              ...getReferenceProps({
                ref: toggleElRef,
                ...(toggleEl as ReactElement).props,
                ...additionalProps,
                className: classNames(
                  get(toggleEl, "props.className"),
                  "spark-popover__toggle",
                  { active: open }
                ),
                onClick(e) {
                  // @TODO: on V2 remove Popover's onClick redirection to toggleEl
                  const toggleOnClick = (toggleEl as ReactElement).props
                    .onClick;
                  const popoverDeprecatedOnClick = onClick;
                  toggleOnClick
                    ? toggleOnClick?.(e)
                    : popoverDeprecatedOnClick?.(e);
                  setOpen(!open);
                },
                "data-state": open ? "open" : "closed",
              }),
            })}
          </div>
        );
      } else return null;
    };

    return (
      <>
        {renderPopoverTrigger()}
        {open && (
          <FloatingPortal>
            <FloatingFocusManager
              context={context}
              modal={true}
              visuallyHiddenDismiss={!closeButton}
            >
              {renderPopoverContent()}
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    );
  }
);

Popover.displayName = "Popover";
