import React from "react";

export interface FocusTrapProps {
  /** If `true`, focus trap frame is mounted */
  enabled: boolean;
  /** A single child content element */
  children: React.ReactElement;
}

const possibleSelectors = [
  "input",
  "select",
  "textarea",
  "a[href]",
  "button",
  "[tabindex]",
].join(",");

export function ownerDocument(node: Node | null | undefined): Document {
  return (node && node.ownerDocument) || document;
}

function getTabIndex(node: HTMLElement): number {
  const tabindexAttr = parseInt(node.getAttribute("tabindex") || "", 10);

  if (!Number.isNaN(tabindexAttr)) {
    return tabindexAttr;
  }

  if (node.contentEditable === "true") {
    return 0;
  }

  return node.tabIndex;
}

function getTabbable(root: HTMLElement): HTMLElement[] {
  const regularTabNodes: HTMLElement[] = [];

  Array.from(root.querySelectorAll(possibleSelectors)).forEach((node, i) => {
    const nodeTabIndex = getTabIndex(node as HTMLElement);
    const isNodeDisabled = node.hasAttribute("disabled");

    if (nodeTabIndex === -1 || isNodeDisabled) {
      return;
    }

    if (nodeTabIndex === 0) {
      regularTabNodes.push(node as HTMLElement);
    }
  });

  return regularTabNodes;
}

/**
 * Utility component for keeping keyboard focus inside the component
 * Note: works only for regular tab index values (value 0). For more complex purposes
 * with ordered tab nodes, use one of the dedicated open libraries
 */

export const FocusTrap = ({
  children,
  enabled,
}: FocusTrapProps): JSX.Element => {
  const ignoreNextEnforceFocus = React.useRef(false);
  const frameStart = React.useRef<HTMLDivElement>(null);
  const frameEnd = React.useRef<HTMLDivElement>(null);
  const nodeToRestore = React.useRef<EventTarget | null>(null);
  const reactFocusEventTarget = React.useRef<EventTarget | null>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const lastKeydown = React.useRef<KeyboardEvent | null>(null);

  React.useEffect(() => {
    // We might render an empty child.
    if (!enabled || !rootRef.current) {
      return;
    }

    const doc = ownerDocument(rootRef.current);

    const contain = (event: FocusEvent | null): void => {
      const { current: rootElement } = rootRef;

      // Cleanup functions are executed lazily in React 17.
      // Contain can be called between the component being unmounted and its cleanup function being run.
      if (rootElement === null) {
        return;
      }

      if (!doc.hasFocus() || ignoreNextEnforceFocus.current) {
        ignoreNextEnforceFocus.current = false;
        return;
      }

      if (!rootElement.contains(doc.activeElement)) {
        // if the focus event is not coming from inside the children's react tree, reset the refs
        if (
          (event && reactFocusEventTarget.current !== event.target) ||
          doc.activeElement !== reactFocusEventTarget.current
        ) {
          reactFocusEventTarget.current = null;
        } else if (reactFocusEventTarget.current !== null) {
          return;
        }

        let tabbable: string[] | HTMLElement[] = [];
        if (
          doc.activeElement === frameStart.current ||
          doc.activeElement === frameEnd.current
        ) {
          tabbable = getTabbable(rootRef.current as HTMLElement);
        }

        if (tabbable.length > 0) {
          const isShiftTab = Boolean(
            lastKeydown.current?.shiftKey && lastKeydown.current?.key === "Tab"
          );

          const focusNext = tabbable[0];
          const focusPrevious = tabbable[tabbable.length - 1];

          if (
            typeof focusNext !== "string" &&
            typeof focusPrevious !== "string"
          ) {
            if (isShiftTab) {
              focusPrevious.focus();
            } else {
              focusNext.focus();
            }
          }
        }
      }
    };

    const loopFocus = (event: KeyboardEvent): void => {
      lastKeydown.current = event;

      if (event.key !== "Tab") {
        return;
      }

      // Make sure the next tab starts from the right place.
      // doc.activeElement refers to the origin.
      if (doc.activeElement === rootRef.current && event.shiftKey) {
        // We need to ignore the next contain as
        // it will try to move the focus back to the rootRef element.
        ignoreNextEnforceFocus.current = true;
        if (frameEnd.current) {
          frameEnd.current.focus();
        }
      }
    };

    doc.addEventListener("focusin", contain);
    doc.addEventListener("keydown", loopFocus, true);

    return () => {
      doc.removeEventListener("focusin", contain);
      doc.removeEventListener("keydown", loopFocus, true);
    };
  }, [enabled]);

  const handleFocusFrame = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (nodeToRestore.current === null) {
      nodeToRestore.current = event.relatedTarget;
    }
  };

  return enabled ? (
    <>
      <div tabIndex={0} onFocus={handleFocusFrame} ref={frameStart} />
      {React.cloneElement(children, { ref: rootRef })}
      <div tabIndex={0} onFocus={handleFocusFrame} ref={frameEnd} />
    </>
  ) : (
    children
  );
};
