export const undefinedButHas =
  (anotherProp) => (props, propName, componentName) => {
    if (!(props[propName] === undefined && props[anotherProp])) {
      return new Error(
        `Either ${propName} needed, or ${propName} should be undefined and '${anotherProp}' prop provided for '${componentName}'.`
      );
    }
  };

/*
  eitherOr
  Validates that a given prop should be provided or that a second prop should be provided instead
*/
export const eitherOr = (anotherProp) => (props, propName, componentName) => {
  if (props[propName] === undefined && props[anotherProp] === undefined) {
    return new Error(
      `Error in ${componentName}: You must provide either "${propName}" prop (uncontrolled) or "${anotherProp}" prop (controlled).`
    );
  }
};

/*
  alongWithAndOfType
  Validates that a given prop requires the presence of second prop and that it should be of a specific type
*/
export const alongWithAndOfType =
  (anotherProp, type) => (props, propName, componentName) => {
    if (
      props[propName] !== undefined &&
      (!props[anotherProp] || typeof props[anotherProp] !== type)
    ) {
      return new Error(
        `Error in <${componentName}>: "${anotherProp}" prop is missing or is not of type "${type}"`
      );
    }
  };
