/* eslint complexity: ["error", 25]*/
/**
 * # Affix
 * Source: sabre-spark version 2.4.6
 * Affix one element to another.
 *
 * @module node_modules/sabre-spark/js/src/helpers/position/affix.js
 */

import React, { PureComponent } from "react";
import isEqual from "lodash.isequal";
import {
  object,
  string,
  oneOf,
  bool,
  any,
  element,
  oneOfType,
} from "prop-types";
import { boxPosition, offset } from "./index";
import { ConditionalWrapper } from "../utils/ConditionalWrapper";

class Affix extends PureComponent {
  state = {
    caretLeft: 0,
    caretPosition: "",
    caretTop: 0,
    left: 0,
    top: 0,
  };

  componentDidMount() {
    this.props.isMounted && this._setPosition();

    window.addEventListener("resize", this._onResize);
    window.addEventListener("scroll", this._onScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.visibility, prevProps.visibility)) {
      this._setPosition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._onResize);
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    const {
      id,
      className,
      children,
      anchorX,
      caretType,
      anchorY,
      visibility,
      "aria-controls": ariaControls,
      role,
      modal,
      onClick,
      rtl,
      portalWrapperClassName,
    } = this.props;
    const { top, left, caretTop, caretLeft, caretPosition } = this.state;

    return (
      <ConditionalWrapper
        condition={!!portalWrapperClassName}
        wrapper={(children) => (
          <div className={portalWrapperClassName}>{children}</div>
        )}
      >
        <div
          id={id}
          aria-controls={ariaControls}
          aria-modal={modal}
          className={className}
          ref={(node) => (this.el = node)}
          role={role}
          data-anchor-x={anchorX}
          data-anchor-y={anchorY}
          data-affixed
          onClick={onClick}
          dir={rtl ? "rtl" : "auto"}
          style={{
            top,
            left,
            visibility,
          }}
        >
          {children}
          <span
            className={`spark-${caretType}__caret`}
            ref={(node) => (this.caretEl = node)}
            data-position={caretPosition}
            style={{
              top: caretTop,
              left: caretLeft,
            }}
          />
        </div>
      </ConditionalWrapper>
    );
  }

  /**
   * Set the position of the target element.
   */
  _setPosition() {
    const { affixTo = this.el, anchorX, anchorY, isFixed } = this.props;

    // Target element properties
    const { top: targetTop, left: targetLeft } = offset(affixTo, isFixed);
    const { offsetWidth: targetWidth, offsetHeight: targetHeight } = affixTo;

    // Element to affix properties
    const { offsetWidth: elWidth, offsetHeight: elHeight } = this.el;

    // Maxes
    const { offsetWidth: docWidth, offsetHeight: docHeight } =
      document.documentElement;

    // Get the values
    const { elTop, elLeft } = this._calculatePosition({
      anchorX,
      anchorY,
      elHeight,
      elWidth,
      maxX: docWidth - elWidth,
      maxY: Math.max(docHeight - elHeight, 0),
      targetHeight,
      targetLeft,
      targetTop,
      targetWidth,
    });

    // Position the caret
    const { extraLeft, extraTop, caretTop, caretLeft, caretPosition } =
      this._positionCaret({
        elHeight,
        elLeft,
        elTop,
        elWidth,
        targetHeight,
        targetLeft,
        targetTop,
        targetWidth,
      });

    // Set the position
    const left = elLeft + extraLeft;
    const top = elTop + extraTop;

    this.setState({ top, left, caretTop, caretLeft, caretPosition });
  }

  /**
   * Get the proper top and left position for an anchor direction.
   * @param  {Object} p
   * @return {Object}
   */
  _calculatePosition(p) {
    /*
     * Keep track of what we're trying to do here, so on subsequent, nested calls to this
     * method we can see what has already been tried.
     */
    p.previousAttempts = (p.previousAttempts || 0) + 1;
    p.previousChecks = p.previousChecks || [];

    const finalCheck = p.previousAttempts > 3;

    let top;

    let left;

    // Y-axis check
    switch (p.anchorY) {
      case "bottom":
        top = p.targetTop + p.targetHeight;
        break;
      case "middle":
        top = p.targetTop - (p.elHeight - p.targetHeight) / 2;
        break;
      default:
        top = p.targetTop - p.elHeight;
        break;
    }

    // Under min
    if (top < 0) {
      if (!finalCheck && p.previousChecks.indexOf("overY") === -1) {
        p.previousChecks.push("underY");
        p.anchorY = Affix._getNewAnchorY(true, p.anchorY, p.anchorX);

        return this._calculatePosition(p);
      }
      top = 0;
    }

    // X-axis check
    switch (p.anchorX) {
      case "right":
        left =
          p.targetLeft +
          (!isEqual(p.anchorY, "middle") && !p.isOverlapping
            ? 0
            : p.targetWidth);
        break;
      case "center":
        left = p.targetLeft - (p.elWidth - p.targetWidth) / 2;
        break;
      default:
        left =
          p.targetLeft -
          p.elWidth +
          (!isEqual(p.anchorY, "middle") ? p.targetWidth : 0);
        break;
    }

    // Under min
    if (left < 0) {
      if (!finalCheck && p.previousChecks.indexOf("overX") === -1) {
        p.previousChecks.push("underX");
        p.anchorX = Affix._getNewAnchorX(true, p.anchorX, p.anchorY);

        return this._calculatePosition(p);
      }
      left = 0;
    }

    // Over max
    if (left > p.maxX) {
      if (!finalCheck && p.previousChecks.indexOf("underX") === -1) {
        p.previousChecks.push("overX");
        p.anchorX = Affix._getNewAnchorX(false, p.anchorX, p.anchorY);

        return this._calculatePosition(p);
      }
      left = p.maxX;
    }

    // One element is covering another. Try to fix that, but bail out after four tries.
    if (
      isEqual(
        boxPosition(
          { width: p.elWidth, height: p.elHeight, left, top },
          {
            width: p.targetWidth,
            height: p.targetHeight,
            left: p.targetLeft,
            top: p.targetTop,
          }
        ),
        "overlap"
      )
    ) {
      p.isOverlapping = true;

      // Try Y
      if (!isEqual(p.repositionY, false)) {
        /*
         * Will start undefined, then true, then false. This limits us to entering
         * this loop twice, once to try moving in each direction.
         */
        p.repositionY = !p.repositionY;

        // First try to put above, then try to put below.
        p.anchorY = Affix._getNewAnchorY(p.repositionY, "middle", p.anchorX);

        // Give us one more shot at positioning
        p.previousAttempts--;

        return this._calculatePosition(p);

        // Try X
      } else if (!isEqual(p.repositionX, false)) {
        /*
         * Will start undefined, then true, then false. This limits us to entering
         * this loop twice, once to try moving in each direction.
         */
        p.repositionX = !p.repositionX;

        // First try to put above, then try to put below.
        p.anchorX = Affix._getNewAnchorX(p.repositionX, "center", p.anchorY);

        // Give us one more shot at positioning
        p.previousAttempts--;

        return this._calculatePosition(p);
      }
    }

    return { elTop: top, elLeft: left, anchorX: p.anchorX, anchorY: p.anchorY };
  }

  /**
   * Determine the new y-axis anchor
   * @param  {Boolean} underMin Under the min?
   * @param  {String} anchorY
   * @param  {String} anchorX
   * @return {String}
   */
  static _getNewAnchorY(underMin, anchorY, anchorX) {
    /*
     * If the x-axis is anchored in the center, skip
     * trying to anchor to the middle because then we'd
     * be overlaying the button.
     */
    if (isEqual(anchorX, "center") || isEqual(anchorY, "middle")) {
      return underMin ? "bottom" : "top";
    }

    return "middle";
  }

  /**
   * Determine the new y-axis anchor
   * @param  {Boolean} underMin Under the min?
   * @param  {String} anchorY
   * @param  {String} anchorX
   * @return {String}
   */
  static _getNewAnchorX(underMin, anchorX, anchorY) {
    /*
     * If the y-axis is anchored in the center, skip
     * trying to anchor to the middle because then we'd
     * be overlaying the button.
     */
    if (isEqual(anchorY, "middle") || isEqual(anchorX, "center")) {
      return underMin ? "left" : "right";
    }

    return "center";
  }

  /**
   * Set the position of the caret.
   * @param {Object} p
   * @return {Object}
   */
  _positionCaret(p) {
    if (!this.caretEl) {
      return {
        extraLeft: 0,
        extraTop: 0,
      };
    }

    const caretPosition = boxPosition(
      { width: p.elWidth, height: p.elHeight, left: p.elLeft, top: p.elTop },
      {
        width: p.targetWidth,
        height: p.targetHeight,
        left: p.targetLeft,
        top: p.targetTop,
      }
    );
    const { width, height } = this.caretEl.getBoundingClientRect();
    const caretLeft = Math.min(
      p.elWidth,
      Math.max(0, p.targetLeft - p.elLeft + p.targetWidth / 2)
    );
    const caretTop = Math.min(
      p.elHeight,
      Math.max(0, p.targetTop - p.elTop + p.targetHeight / 2)
    );

    let extraLeft = 0;

    let extraTop = 0;

    switch (caretPosition) {
      case "above":
        extraTop = -width / 2;
        break;
      case "below":
        extraTop = width / 2;
        break;
      case "left":
        extraLeft = -height / 2;
        break;
      default:
        extraLeft = height / 2;
        break;
    }

    return {
      extraLeft,
      extraTop,
      caretTop,
      caretLeft,
      caretPosition,
    };
  }

  /**
   * On resize, update the position.
   */
  _onResize = () => this._setPosition();

  /**
   * When the window scrolls, ensure the proper position of the popover.
   */
  _onScroll = () => this._setPosition();
}

Affix.propTypes = {
  affixTo: oneOfType([element, object]).isRequired,
  anchorX: string,
  anchorY: string,
  children: any,
  className: string,
  caretType: oneOf(["popover", "tooltip"]),
  isFixed: bool,
  isMounted: bool,
  visibility: string,
  "aria-controls": string,
};

Affix.defaultProps = {
  anchorX: "center",
  anchorY: "bottom",
  caretType: "popover",
  className: "",
  displayClose: false,
  isFixed: false,
  isMounted: false,
  visibility: "hidden",
};

export default Affix;
