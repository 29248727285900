import React, {
  ChangeEvent,
  HTMLAttributes,
  ReactNode,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";

export enum TableCellStatus {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  NULL = "null",
}

export enum TableSortOrder {
  ASC = "asc",
  DESC = "desc",
  NONE = "none",
  NO_SORT = "no_sort",
}

export enum TableCellAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

interface TableCommonProps {
  children?: ReactNode;
  className?: string;
}

export interface TableHeadProps
  extends TableCommonProps,
    Omit<HTMLAttributes<HTMLTableSectionElement>, "children"> {}

export interface TableBodyProps
  extends TableCommonProps,
    Omit<HTMLAttributes<HTMLTableSectionElement>, "children"> {}

export interface TableHeaderCellProps
  extends TableCommonProps,
    Omit<ThHTMLAttributes<HTMLTableHeaderCellElement>, "children"> {
  disabled?: boolean;
  sort?: TableSortOrder;
  /** @deprecated Use `isResizable` prop only at table level. This prop is not needed */
  isResizable?: boolean;
}

export interface TableRowProps
  extends TableCommonProps,
    Omit<HTMLAttributes<HTMLTableRowElement>, "children" | "onClick"> {
  id?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement>, rowId: string) => void;
  /**
   * To apply props on the TableCell that holds
   * the 'down chevron' used to expand a drawer row
   */
  drawerExpanderCellProps?: TableCellProps;
  /** To apply attributes on the row that expands to display drawer content */
  drawerRowProps?: HTMLAttributes<HTMLTableRowElement>;
  /** Content of the drawer row */
  drawerContent?: ReactNode;
  /** To expand the drawer content initially or not */
  drawerIsExpandedInitState?: boolean;
  /** ARIA label for the 'down chevron' button which expands drawer*/
  drawerExpanderAriaLabel?: string;
  /** Callback that fires before initiating the drawer expansion */
  onBeforeDrawerExpand?: () => void;
  /** Callback that fires after initiating the drawer expansion */
  onDrawerExpand?: () => void;
  /** Callback that fires after animation of drawer expansion completes */
  onAfterDrawerExpand?: () => void;
  /** Callback that fires before initiating the drawer collapse */
  onBeforeDrawerCollapse?: () => void;
  /** Callback that fires after initiating the drawer collapse */
  onDrawerCollapse?: () => void;
  /** Callback that fires after animation of drawer collapse completes */
  onAfterDrawerCollapse?: () => void;
}

export interface SelectAllCheckboxProps {
  ariaLabel?: string;
  checked?: boolean;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface TableCellProps
  extends Omit<TableCommonProps, "children">,
    TdHTMLAttributes<HTMLTableCellElement> {
  align?: "left" | "center" | "right";
  disabled?: boolean;
  /**
   * Enum of type 'TableCellStatus' that adds data-error/warning like status attributes.
   * This prop is mandatory while using TableEditableField with a status message to display
   * inside a TableCell
   */
  status?: TableCellStatus;
  children?: ReactNode;
}

export interface TableEditableFieldProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "onChange"> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  "aria-label": string;
  name?: string;
  /** Text message that appears on click of the input box */
  statusMessage?: string;
  "aria-invalid"?: boolean;
  /** Text Message id for accessibility. Optional by default. Mandatory for Server Side Rendering */
  statusMessageId?: string;
}

export interface TableEditRowActionsProps {}

export interface RowSelectionProps {
  /**
   * Callback to handle row selection. Row selection is disabled if no callback is passed
   */
  onSelectRow?: (
    event:
      | React.MouseEvent<HTMLTableRowElement>
      | React.ChangeEvent<HTMLInputElement>,
    rowIds?: string[]
  ) => void;
  /**
   * Callback to handle change in select all rows checkbox
   */
  onChangeSelectAllRows?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Controlled select rows from given ids. Requires id to be passed to all table row's 'tr' element
   */
  selectRowIds?: string[];
  /**
   * Assistive text label for the checkbox to select all rows
   */
  selectAllChkBoxLabel?: ReactNode;
  /**
   * Assistive text label for the checkbox to select single row
   */
  selectRowChkBoxLabel?: ReactNode;
}

export interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  /** Heading to be displayed for a table */
  title?: string;
  /** Use TableHead & TableBody components to be rendered inside the table */
  children: ReactNode;
  /** Display the table in condensed manner */
  condensed?: boolean;
  /** Display the table in condensed but larger font manner */
  condensedLargerFont?: boolean;
  /** To resize the width of columns by click and drag */
  isResizable?: boolean;
  /**
   * To switch the table between panel/card & regular view based on screen size.
   * 'dataPanelBreakpoint' prop is needed while using this prop.
   * Please refer http://sabrespark.com/resources/css_helpers.html#breakpoint-specific-classes
   * to add classes to child elements which have to be switched between the Table views
   */
  isRowToPanel?: boolean;
  /**
   * To be used when passing 'isRowToPanel' as true.
   * The width of screen as 'xs' or 'sm', depending on which
   * corresponding breakpoint the Table will switch to panel/card view
   */
  dataPanelBreakpoint?: string;

  /**
   * Set of props to enable row selection and manage it
   */
  rowSelectionProps?: RowSelectionProps;
}
