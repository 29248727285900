import React from "react";
import { MessageStatus } from "../../types";

export enum TimeFormat {
  TWELVE = "12",
  TWENTY_FOUR = "24",
}

export enum TimePeriod {
  AM = "a.m.",
  PM = "p.m.",
}

export interface TimeTextInputProps
  extends Omit<
    React.HTMLProps<HTMLLabelElement>,
    "onChange" | "onFocus" | "onBlur" | "placeholder"
  > {
  /** TimeTextInput Label **/
  label: string;

  /** The time format used by the TimeTextInput */
  timeFormat?: TimeFormat;

  /** Value of the TimeTextInput in the format of [HH:MM]**/
  value?: string;

  /** Default value of the TimeTextInput in the format of [HH:MM] (will be used only when 'value' prop is 'undefined') */
  defaultValue?: string;

  /** The aria-label value to be used on the hour input field */
  hourAriaLabel?: string;

  /** The aria-label value to be used on the minute input field */
  minuteAriaLabel?: string;

  /** The aria-label value to be used on the period input field if using the 12-hour clock format */
  periodAriaLabel?: string;

  /** Placeholder text for hour */
  hourPlaceholder?: string;

  /** Placeholder text for minutes */
  minutePlaceholder?: string;

  /** Placeholder text for period */
  periodPlaceholder?: string;

  /** Name for hour input */
  hourInputName?: string;

  /** Name for minutes input */
  minuteInputName?: string;

  /** Name for period input */
  periodInputName?: string;

  /** If `true`, `TimeTextInput` will be disabled */
  disabled?: boolean;

  /** One of Messages Status **/
  status?: MessageStatus | "success" | "warning" | "error" | "info";

  /** Status Message for Inline Message **/
  statusMessage?: string;

  /** A callback function run when the TimeTextInput value changes to another valid time **/
  onChange?: (value: string) => void;

  /** Attributes to add to the hidden input tag for accessibility */
  inputAttrs?: { [key: string]: string };
}
