/* eslint-disable lines-around-comment */
import React from "react";

export type ReactMouseOrTouchEvent = React.MouseEvent | React.TouchEvent;
export type MouseOrTouchEvent = MouseEvent | TouchEvent;

export enum SliderType {
  DEFAULT = "default",
  INPUT = "input",
  INTEGRATED = "integrated",
}

export interface ComputeOffsetBasedOnDrag {
  dragPosition: number;
  sliderWidth: number;
  minValue: number;
  maxValue: number;
  step: number;
  decimalsInStep: number;
}

interface IncDec {
  value: number;
  step: number;
  decimalsLength: number;
}

export interface Inc extends IncDec {
  maxValue: number;
}

export interface Dec extends IncDec {
  minValue: number;
}

export interface ComputeOffset {
  minValue: number;
  maxValue: number;
  value: number;
  isButton?: boolean;
  step?: number;
  sliderType?: SliderType;
  ceilValue: number;
  floorValue: number;
  sliderWidth: number;
  stepValue: number;
  buttonWidth: number;
}

export const defaultStepSize = 1;
export type SliderOffset =
  | {
      top: number;
      left: number;
      width: number;
    }
  | boolean;

export interface Slider
  extends Omit<React.HTMLProps<HTMLDivElement>, "onChange"> {
  /**
   * Text displayed above the Slider, for accessibility
   */
  label: string;

  /**
   * The minimum allowed value of the slider. Should not be equal to max
   */
  minValue: number;

  /**
   * The maximum allowed value of the slider. Should not be equal to min
   */
  maxValue: number;

  /**
   * Required for controlled variant, if not defaults to minValue
   */
  value?: number;

  /**
   * To be used in uncontrolled version of slider
   */
  defaultValue?: number;

  /**
   * Name attribute of the hidden input element
   */
  name?: string;

  /**
   * Step value should be a string. A value that steps through the Slider in increments
   */
  step?: string;

  /** If `true`, `Slider` will be disabled */
  disabled?: boolean;

  /**
   * Options for Single Value slider variant. Options include INPUT, INTEGRATED or DEFAULT
   */
  sliderType?: SliderType;

  /**
   * Variant with muted styling
   */
  alternate?: boolean;

  /**
   * Triggered when value is changed, used to sync the external state with the value from component
   * @param val
   */
  onChange?: (val: number) => void;

  /**
   * Label ID for accessibility. Optional by default. Mandatory for Server Side Rendering
   */
  labelId?: string;
}

export const defaultSliderProps = {
  disabled: false,
  sliderType: SliderType.DEFAULT,
  alternate: false,
};

export type SliderProps = Slider & typeof defaultSliderProps;
export type ReactElementOrNull = React.ReactElement | null;

export interface ChangeValuesAndUpdate {
  key: "ArrowRight" | "ArrowUp" | "Enter" | "ArrowLeft" | "ArrowDown";
  upDaterFn: (v: number) => void;
  currentMinValue: number;
  currentValue: number;
  step: number;
  currentMaxValue: number;
  decimalsInStep: number;
  isButton?: boolean;
}
