import React from "react";
import classNames from "classnames";
import { number, oneOf, string, object, array } from "prop-types";
import { ProgressIndicatorSize } from "./progress-indicator.types";

export function ProgressIndicator({
  className,
  percentComplete,
  progressText,
  precision,
  size,
  stages,
  svgConfigs,
  type,
}) {
  const classes = classNames(
    `spark-progress spark-progress--${size}`,
    { "spark-progress--integrated": size === ProgressIndicatorSize.LARGE },
    {
      "spark-progress--horizontal":
        size === ProgressIndicatorSize.SMALL ||
        size === ProgressIndicatorSize.EXTRA_SMALL,
    },
    className
  );

  const _renderDeterminate = () => {
    const percentCompleteNumber = parseFloat(
      ((percentComplete || 0) * 100).toFixed(precision)
    );
    const stateClasses = classNames("spark-progress__states", {
      "spark-assistive-text": size === ProgressIndicatorSize.EXTRA_SMALL,
    });
    const currentStage = stages?.find((element, index, stages) => {
      if (stages[index + 1]) {
        return (
          parseInt(element.value) <= percentCompleteNumber &&
          percentCompleteNumber < parseInt(stages[index + 1].value)
        );
      }
      return true;
    });

    const smallProgressText =
      size === ProgressIndicatorSize.EXTRA_SMALL ||
      size === ProgressIndicatorSize.SMALL
        ? progressText
        : "";

    const stageProgressText = stages?.find(
      (stage) => stage.value === currentStage?.value
    )?.progressText;

    return (
      <div
        className={classes}
        role="progressbar"
        aria-label={`${percentCompleteNumber}% ${smallProgressText} ${stageProgressText}`}
      >
        <progress value={percentComplete} />
        <span className="spark-progress__text">
          <span className="spark-progress__value" role="status">
            {percentCompleteNumber}% {smallProgressText}
          </span>
          <ul className={stateClasses}>
            {stages?.map((stage, index) => (
              <li
                key={index}
                data-value={stage.value}
                className={
                  stage.value === currentStage?.value ? "active" : undefined
                }
              >
                {stage.progressText}
              </li>
            ))}
          </ul>
        </span>
        {_createSVG(svgConfigs[size], percentComplete)}
      </div>
    );
  };

  const _renderIndeterminate = () => {
    return (
      <div className={classes} role="progressbar" aria-label={progressText}>
        <progress />
        <span className="spark-progress__text" role="status">
          {progressText}
        </span>
        <span className="spark-progress__meter" />
      </div>
    );
  };

  return type === "determinate" ? _renderDeterminate() : _renderIndeterminate();
}

/* built in svg config */
ProgressIndicator.SVGCONFIGS = {
  lg: {
    diameter: 264,
    stroke: 12,
    track:
      "M132 6c69.588 0 126 56.412 126 126s-56.412 126-126 126S6 201.588 6 132 62.412 6 132 6z",
    fill: "",
    border:
      "M132 252c-66.274 0-120-53.726-120-120S65.726 12 132 12s120 53.726 120 120-53.726 120-120 120z",
  },
  sm: {
    diameter: 60,
    stroke: 6,
    track:
      "M30 3c14.912 0 27 12.088 27 27S44.912 57 30 57 3 44.912 3 30 15.088 3 30 3z",
    fill: "",
    border:
      "M30 54C16.745 54 6 43.255 6 30S16.745 6 30 6s24 10.745 24 24-10.745 24-24 24z",
  },
  xs: {
    diameter: 24,
    stroke: 3,
    track:
      " M12 1.5c5.8 0 10.5 4.7 10.5 10.5S17.8 22.5 12 22.5S1.5 17.8 1.5 12S6.2 1.5 12 1.5z",
    fill: "",
    border: "",
  },
};

// Backwards compatibility to support component property. Enum import should be used instead.
ProgressIndicator.SIZE = ProgressIndicatorSize;

ProgressIndicator.displayName = "ProgressIndicator";

ProgressIndicator.propTypes = {
  /**
   * Any extra CSS classes for the component
   */
  className: string,

  /**
   * Percent complete [0 - 1]
   */
  percentComplete: number,

  /**
   * Progress Indicator text string
   */
  progressText: string,

  /**
   * How many decimal places to render in the percentage
   */
  precision: number,

  /**
   * Size of the Progress Indicator
   */
  size: oneOf(Object.values(ProgressIndicatorSize)),

  /**
   * An array of Objects that contain different messages based on percent complete
   * const stages = [
     { value: 0, progressText: "Applying Policy Rules" },
     { value: 20, progressText: "Sorting Options" },
     { ... },
     { ... },
     { value: 100, progressText: "All done!" }
   ];
   */
  stages: array,

  /**
   * SVG configuration for drawing out the progress indicator:
   *  lg: {
        diameter: 264,
        stroke: 12,
        track: 'M132 6c69.588 0 126 56.412 126 126s-56.412 126-126 126S6 201.588 6 132 62.412 6 132 6z',
        fill: '',
        border: 'M132 252c-66.274 0-120-53.726-120-120S65.726 12 132 12s120 53.726 120 120-53.726 120-120 120z'
      },
      sm: { ... },
      xs: { ... }
   */
  svgConfigs: object,

  /**
   * Progress Indicator type
   */
  type: oneOf(["determinate", "indeterminate"]),
};

ProgressIndicator.defaultProps = {
  precision: 0,
  percentComplete: 0,
  size: ProgressIndicatorSize.LARGE,
  svgConfigs: ProgressIndicator.SVGCONFIGS,
  type: "determinate",
};

/**
 * generate the SVG for the progress indicator
 * @return {ReactElement}                 [description]
 * @param svgConfig
 * @param percentComplete
 */
const _createSVG = (svgConfig, percentComplete) => {
  const dashArray = (svgConfig.diameter - svgConfig.stroke) * Math.PI;
  const dashOffset = dashArray - dashArray * percentComplete;

  return (
    <svg
      viewBox={`0 0 ${svgConfig.diameter} ${svgConfig.diameter}`}
      xmlns="http://www.w3.org/2000/svg"
      className="spark-progress__meter"
      role="presentation"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d={svgConfig.track}
          strokeWidth={svgConfig.stroke}
          className="spark-progress__track"
        />
        <path
          d={`${svgConfig.fill || svgConfig.track}`}
          strokeWidth={svgConfig.stroke}
          className="spark-progress__fill"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <path d={svgConfig.border} className="spark-progress__border" />
      </g>
    </svg>
  );
};
