/**
 * # Has Class
 * See if an element has a class.
 *
 * @param {Element|String} el
 * @param {String} name
 * @return {Boolean}
 *
 * @module helpers/dom/has-class.js
 */
function hasClass(el, name) {
  let cName = (
    typeof el === "object"
      ? el.className || (el.getAttribute && el.getAttribute("class")) || ""
      : el || ""
  ).replace(/[\t\r\n\f]/g, " ");
  return (" " + cName + " ").indexOf(" " + name + " ") !== -1;
}

export default hasClass;
