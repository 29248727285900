import React from "react";
import { IconSize } from "../types";

export enum FABPlacement {
  LEFT = "left",
  MIDDLE = "middle",
  RIGHT = "right",
}

export enum MultiActionFABPlacement {
  LEFT = "left",
  RIGHT = "right",
}

type FABActionCommon = {
  name: string;
  icon: string;
};

type FABActions =
  | (FABActionCommon & {
      ariaLabel: string;
      label?: never;
    })[]
  | (FABActionCommon & {
      ariaLabel?: never;
      label: string;
    })[];

interface FABCommonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "ref"> {
  /** Click handler for the FAB button without actions */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Right to Left Property */
  rtl?: boolean;
  /** The size of the Icon */
  iconSize?: IconSize;
  /** Horizontal placement prop to be used only for FAB without actions */
  placement?: FABPlacement;
  /** Horizontal placement prop to be used only for FAB with actions */
  multiActionPlacement?: MultiActionFABPlacement;
  /** Whether an Underlay should be shown when the Floating Action Button group is expanded */
  showUnderlay?: boolean;
  /** When 'showUnderlay' is true, the viewport size or breakpoint (and lower) at which an Underlay should be displayed when the Floating Action Button group is expanded */
  underlayBreakPoint?: string | number;
  /** Close the Floating Action Button group automatically when a related action button is clicked */
  autoClose?: boolean;
  /** A callback function run when the Floating Action Button group is expanded */
  onExpand?: Function;
  /** A callback function run when the Floating Action Button group is collapsed */
  onCollapse?: Function;
  /** Displays the button and options in a condensed look */
  condensed?: boolean;
  /** Actions that expand on click of the button. All action objects must have ariaLabel or label but not both */
  actions?: FABActions;
  /** ARIA label for collapse button when using FAB with actions*/
  collapseButtonAriaLabel?: string;
  /** Callback for click of the action button */
  onActionClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    actionName: string
  ) => void;
  type?: "submit" | "reset" | "button";
  fabGroupContainerProps?: React.HTMLProps<HTMLDivElement>;
  /** Providing this property will create a SPAN wrapper for the Portal and assign a given class to it. Useful, among others, for style isolation purposes */
  portalWrapperClassName?: string;
}

type FABConditionalProps =
  | {
      /** The name of the Spark icon to be displayed. Available icons list can be found on http://sabrespark.com/design/icons.html */
      icon?: never;
      /** ARIA label for the button when using only icon without label */
      ariaLabel?: never;
      /** Label to be used for text button */
      label: string;
    }
  | {
      /** The name of the Spark icon to be displayed. Available icons list can be found on http://sabrespark.com/design/icons.html */
      icon: string;
      /** ARIA label for the button when using only icon without label */
      ariaLabel?: never;
      /** Label to be used for text button */
      label: string;
    }
  | {
      /** The name of the Spark icon to be displayed. Available icons list can be found on http://sabrespark.com/design/icons.html */
      icon: string;
      /** ARIA label for the button when using only icon without label */
      ariaLabel: string;
      /** Label to be used for text button */
      label?: never;
    };

export type FloatingActionButtonProps = FABCommonProps & FABConditionalProps;

export interface FloatingActionButtonWithForwardRef
  extends React.ForwardRefExoticComponent<
    FloatingActionButtonProps & React.RefAttributes<HTMLButtonElement>
  > {}
