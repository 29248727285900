import React, { cloneElement, Component, Fragment } from "react";
import { oneOf, string, element, any } from "prop-types";
import classNames from "classnames";
import { createPortal } from "react-dom";
import uniqueId from "lodash.uniqueid";
import Affix from "../utils/affix";
import { get } from "../utils";

export class Tooltip extends Component {
  _isMounted = false;

  state = {
    isOpened: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.id = this.props.id || uniqueId("spark-tooltip_");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      className,
      anchorX,
      anchorY,
      toggleEl,
      children,
      portalWrapperClassName,
    } = this.props;
    const { isOpened } = this.state;
    const isButton = toggleEl.type === "button" || toggleEl.type === "span";
    const isBadge = toggleEl.type.displayName === "Badge";
    const cloneClasses = isButton ? get(toggleEl, "props.className", "") : "";

    const additionalProps = isBadge
      ? { _privateProps: { hasTooltip: true } }
      : {};

    const renderToolTip = isButton ? (
      toggleEl
    ) : (
      <div style={{ display: "inline-block" }}>
        {isBadge
          ? cloneElement(toggleEl, {
              ...additionalProps,
              className: "spark-tooltip tooltip-initialized",
            })
          : toggleEl}
      </div>
    );

    return (
      <Fragment>
        {cloneElement(renderToolTip, {
          className: classNames(
            { "spark-tooltip": !isBadge },
            { "tooltip-initialized": !isBadge },
            { "tooltip-active": isOpened },
            cloneClasses
          ),
          "aria-describedby": this.id,
          onMouseOver: this._onMouseOver,
          onMouseOut: this._onMouseOut,
          onFocus: this._onFocus,
          onBlur: this._onBlur,
          ref: (node) => (this.targetEl = node),
        })}

        {this._isMounted &&
          createPortal(
            <Affix
              id={this.id}
              affixTo={this.targetEl}
              isMounted={this._isMounted}
              anchorX={anchorX}
              anchorY={anchorY}
              role="tooltip"
              className={classNames(
                "spark-tooltip__content",
                { active: isOpened },
                className
              )}
              caretType="tooltip"
              visibility={isOpened ? "visible" : "hidden"}
              ref={(node) => (this.affixEl = node)}
              portalWrapperClassName={portalWrapperClassName}
            >
              {children}
            </Affix>,
            document.body
          )}
      </Fragment>
    );
  }

  _onOpen = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isOpened: true,
      });
    }, 0);
  };

  _onClose = () => {
    this.timer && clearTimeout(this.timer);
    this.setState({
      isOpened: false,
    });
  };

  _onMouseOver = () => this._onOpen();

  _onMouseOut = () => this._onClose();

  _onFocus = () => this._onOpen();

  _onBlur = () => this._onClose();
}

Tooltip.propTypes = {
  id: string,

  /** The default x-axis alignment of the popover. Can also be defined using the data-anchor-x attribute on the popover toggle element. */
  anchorX: oneOf(["left", "right", "center"]),

  /** The default y-axis alignment of the popover. Can also be defined using the data-anchor-y attribute on the popover toggle element. */
  anchorY: oneOf(["top", "middle", "bottom"]),

  /** Inner elements */
  children: any,

  /** Any extra CSS classes for the component */
  className: string,

  /** The element to use for the tooltip toggle. */
  toggleEl: element.isRequired,

  /** Providing this property will create a SPAN wrapper for the Portal and assign a given class to it. Useful, among others, for style isolation purposes */
  portalWrapperClassName: string,
};

Tooltip.defaultProps = {
  anchorX: "center",
  anchorY: "top",
};

Tooltip.displayName = "Tooltip";
