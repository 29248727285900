import React, { ReactNode } from "react";

export enum ButtonSize {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}

type ActionLink = {
  label: string;
  href?: string;
  target?: string;
};

export enum TooltipDirectionX {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum TooltipDirectionY {
  TOP = "top",
  BOTTOM = "bottom",
  MIDDLE = "middle",
}

export interface CommonButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "onClick" | "size"> {
  type?: "submit" | "reset" | "button";

  /** Function triggered by onClick. Have 3 parameters - click event, success and failure callbacks. When progress is true, success or failure callback must be called to end the progress indication*/
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
    successCallback?: () => {},
    failureCallback?: () => {}
  ) => void;

  size?: ButtonSize | "xs" | "sm" | "md" | "lg";

  /** If `true`, `Button` will be disabled */
  disabled?: boolean;

  /** Use a brand button for large banners and marketing contexts. Defaults size to "lg". */
  brand?: boolean;

  /** @deprecated */
  /** **Deprecated**. Use `textOnly` instead */
  text?: boolean;

  /** Use a text only button for secondary or tertiary actions that do not require the prominence of a button treatment. Ignores all "size" values. */
  textOnly?: boolean;

  /** Use a secondary button for non-primary actions. */
  secondary?: boolean;

  /** Buttons with a negative or critical action should use the negative button style. */
  negative?: boolean;

  /** Buttons with multiple actions to be displayed inside a popover must use multiAction prop. */
  multiAction?: boolean;

  /** Combination of 2 buttons with multiple actions to be displayed inside a popover must use combo prop. */
  combo?: boolean;

  /** DOM query of element on whose click, the popover closes */
  contentCloseElQuery?: string;

  /** Display a list of links when the button variant is of 'combo' or 'multiAction' */
  actionLinks?: (React.HTMLProps<HTMLAnchorElement> & ActionLink)[];

  /** Content of the popover that appears on click of button when the variant is of 'combo' or 'multiAction' */
  popoverChildren?: ReactNode;

  /** Id for popover for combo button & multi-action button */
  popoverId?: string;

  /** Right to Left Property **/
  rtl?: boolean;

  /** If set to true, the button will occupy the full width of its container. Not applicable to "textOnly" and "icon" variants **/
  fullWidth?: boolean;

  /** Use a button with progress indicator and success icon. */
  progress?: boolean;

  /** Renders icon after success inside the Button. Must be Spark-supported icon name. */
  progressSuccessIcon?: string;

  /** Function triggered after success or failure of progress. Must be used only when progress is true */
  onComplete?: () => void;

  /**
   * Note: Previous tooltip desription. Temporary inactive because with `tooltip` applied `Popover` can't be used over
   * When "icon" is set, a tooltip is required to describe the icon and action for the Button.
   * */

  /** Text for optional Tooltip */
  tooltip?: string | JSX.Element;

  /** Determines horizontal direction in which icon tooltip will render. */
  tooltipDirectionX?: TooltipDirectionX | "left" | "right" | "center";

  /** Determines vertical direction in which icon tooltip will render. */
  tooltipDirectionY?: TooltipDirectionY | "top" | "bottom" | "middle";

  /** Test ID used for identifying component instance in automated tests. */
  testId?: string;

  /** Value of "aria-label" HTML attribute. Required to improve accessibility when using Icon Button. */
  ariaLabel?: string;

  className?: string;

  /** Any extra CSS classes for the container component while using combo or multi action button */
  comboBoxContainerClassName?: string;

  /** Any extra CSS classes for the container component while using combo or multi action button */
  popoverClassName?: string;
}

interface IconButtonProps extends CommonButtonProps {
  /** Renders icon instead of text inside the Button. Must be Spark-supported icon name */
  icon: string;
  /** React children for the component. Not required when using "icon" */
  children?: never;
}

export interface TextButtonProps extends CommonButtonProps {
  /** Renders icon instead of text inside the Button. Must be Spark-supported icon name */
  icon?: never;
  /** React children for the component. Not required when using "icon" */
  children: ReactNode;
}

export type ButtonProps = IconButtonProps | TextButtonProps;
