import React, { forwardRef, ReactElement } from "react";
import { TypeAhead } from "../../utils/typeahead/typeahead";
import { TypeaheadProps } from "../../utils/typeahead/typeahead.types";

const isNumber = (val: string | number): boolean => {
  const numbersOnlyRegex = /^[\d\b]+$/;
  return numbersOnlyRegex.test(val.toString());
};
export const isValidValue = (val: string | number): boolean => isNumber(val);

interface TypeaheadYearProps
  extends Omit<
    TypeaheadProps,
    "width" | "name" | "onChange" | "isValidInputCharacter"
  > {
  /** A callback function run when the input value changes. **/
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const TypeaheadYear = forwardRef(
  (
    { value: year, onChange, onBlur, ...rest }: TypeaheadYearProps,
    externalRef
  ): ReactElement => {
    const onChangeHandle = (
      e: React.ChangeEvent<HTMLInputElement>,
      val: string
    ): boolean => {
      if (isValidValue(val) || val === "") {
        onChange(e, val);
        return true;
      }
      return false;
    };

    const onBlurHandle = (
      e: React.FocusEvent<HTMLInputElement>,
      val: string
    ): void => {
      let valStr = val;
      if (val.length > 0 && val.length < 4) {
        valStr = val.padStart(4, "0");
        onChange(e, valStr);
      }
      onBlur(e, valStr);
    };

    return (
      <TypeAhead
        {...rest}
        ref={externalRef}
        name="year"
        value={year}
        width={42}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
        isValidInputCharacter={isValidValue}
      />
    );
  }
);

TypeaheadYear.displayName = "TypeaheadYear";
