/* eslint-disable lines-around-comment */
import React, { LegacyRef, ReactNode } from "react";

interface TimeRangePrivateProps {
  /** [Internal] Render method used to render the min value input component */
  renderMinInput?: (id: string) => ReactNode;

  /** [Internal] Render method used to render the max value input component */
  renderMaxInput?: (id: string) => ReactNode;

  /** Slider wrapper classname */
  timeSliderClassName?: string;

  /** Slider wrapper classname */
  timeSliderInputSeparatorClassName?: string;

  /** Slider Min button ceiling value */
  minCeilValue?: number;

  /** Slider Max button floor value */
  maxFloorValue?: number;

  /** Start button props */
  startButtonProps?: Omit<React.HTMLProps<HTMLButtonElement>, "type"> & {
    "data-value": string;
    "data-value-minutes": number;
  };

  /** End button props */
  endButtonProps?: Omit<React.HTMLProps<HTMLButtonElement>, "type"> & {
    "data-value": string;
    "data-value-minutes": number;
  };
}

export interface SliderMultipleValueObject {
  min: number;
  max: number;
  activeThumb?: number;
}

export const defaultSliderMultipleValueProps = {
  startTitle: "Start",
  endTitle: "End",
  step: "1",
  disabled: false,
  minValueInputName: "slider-multi-1",
  maxValueInputName: "slider-multi-2",
  alternate: false,
};

export interface SliderMultipleValueProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "onChange"> {
  /**
   * Text displayed above the Slider, for accessibility
   */
  label: string;

  /**
   * Required for controlled variant,this value is set for first button in slider
   */
  minValue?: number;

  /**
   * Required for uncontrolled variant, this value is set for first button only if minValue is undefined
   */
  defaultMinValue?: number;

  /**
   * Required for controlled variant, this value is set for last button in slider
   */
  maxValue?: number;

  /**
   * Required for uncontrolled variant, this value is set for last button only if maxValue is undefined
   */
  defaultMaxValue?: number;

  /**
   * This is the minimum value the slider can take
   */
  minRange: number;

  /**
   * This is the maximum value the slider can take
   */
  maxRange: number;

  /**
   * The name attribute for the first input field in the slider
   */
  minValueInputName?: string;

  /**
   * The name attribute for the second input field in the slider
   */
  maxValueInputName?: string;

  /**
   * A value that steps through the Slider in increments
   */
  step?: string;

  /**
   * The title attribute for the first input (minValue) input field
   */
  startTitle?: string;

  /**
   * The title attribute for the second input (maxValue) input field
   */
  endTitle?: string;

  /** If `true`, `SliderMultiValue` will be disabled */
  disabled?: boolean;

  /**
   * onChange Hook to get the changed values from the slider
   */
  onChange?: ({ min, max }: SliderMultipleValueObject) => void;

  /**
   * Required only for uncontrolled variant, if not defaults to minValue
   */
  defaultValue?: number;

  /**
   * Variant with muted styling
   */
  alternate?: boolean;

  /**
   * Id of start input element for accessibility. Optional by default. Mandatory for Server Side Rendering
   */
  startInputFieldId?: string;

  /**
   * Id of end input element for accessibility. Optional by default. Mandatory for Server Side Rendering
   */
  endInputFieldId?: string;

  /** Internal props */
  _timeRangePrivateProps?: TimeRangePrivateProps;
}

export enum ButtonTypesMulti {
  START_BUTTON = 0,
  END_BUTTON,
}

export type RefsMulti = {
  minValueRef: LegacyRef<HTMLInputElement>;
  maxValueRef: LegacyRef<HTMLInputElement>;
};
