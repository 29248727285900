/**
 * Modified useCombinedRefs method.
 * The useEffect dependency array was omitted because the popover's floating element reference
 * is not available when the component is mounted and we should be ready to capture the reference later
 */

import { MutableRefObject, RefCallback, useEffect } from "react";

export const useCombinedPopoverRefs = <T>(
  localRef: MutableRefObject<T>,
  externalRef: MutableRefObject<T> | RefCallback<T> | null
): void => {
  useEffect(() => {
    if (!externalRef) return;

    if (typeof externalRef === "function") {
      externalRef(localRef.current);
    } else {
      externalRef.current = localRef.current;
    }
  });
};
