import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarProps, WhichMonth } from "./Calendar.types";
import {
  getDatesInPastCurrentAndNextMonth,
  getNextMonth,
  getPreviousMonth,
  isSameDate,
  isSameMonth,
  WEEK_DAYS,
  withoutTime,
} from "./calendar.util";
import { DayOfWeek } from "./DayOfWeek";
import classNames from "classnames";
import { MonthAndYearLabel } from "./MonthAndYearLabel";
import { MonthAndYearOptions } from "./MonthAndYearOptions";
import { CalendarDate } from "./CalendarDate";
import { FocusTrap } from "../../utils/FocusTrap";
import { CalendarDateType } from "./CalendarDate.types";
import { onCalendarDateMouseOver } from "./calendar.hover.util";
import useResizeObserver from "../../utils/useResizeObserver";
import { onCalendarKeyDown } from "./calendar.arrowkeys.util";
import { CalendarCssOverrides } from "./css-overrides";

export const DUAL_CALENDAR_WIDTH = 755;

export const Calendar: React.FC<CalendarProps> = ({
  value,
  defaultValue,
  range,
  defaultRange,
  minDate,
  maxDate,
  onChange,
  onRangeChange,
  className,
  advanceByMonthAndYear,
  monthsList,
  yearsList,
  visibleCount: visibleCountProp = 1,
  notes,
  ariaLabel,
  ariaNextMonthLabel = "Next month",
  ariaPreviousMonthLabel = "Previous month",
  calendarMonthsNavAriaLabel = "Months",
  daysDisabled,
  showTodayButton,
  showTodayButtonLabel = "Select Today",
  hideDaysOutsideCurrentMonth = false,
  disabled,
  navigateToDisabledMonths = true,
  _privateProps = {},
  ...rest
}) => {
  const isControlled = useRef(value !== undefined);
  const isRange = useRef(Array.isArray(range) || Array.isArray(defaultRange));
  const isRangeControlled = useRef(Array.isArray(range));
  const rangeOrderToggle = useRef("start");
  const countClicked = useRef(0);
  const today = useRef(new Date());
  const [firstFocusableDays, setFirstFocusableDays] = useState<
    (string | undefined)[]
  >([]);

  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const calendarContentRef = useRef<HTMLDivElement>(null);
  const hoverStartsRef = useRef<HTMLButtonElement[]>([]);
  const hoverEndsRef = useRef<HTMLButtonElement[]>([]);

  const [valueState, setValueState] = useState<Date | undefined | null>(
    defaultValue || value
  );
  const [month, setMonth] = useState(
    defaultValue ? defaultValue.getMonth() + 1 : new Date().getMonth() + 1
  );
  const [year, setYear] = useState(
    defaultValue ? defaultValue.getFullYear() : new Date().getFullYear()
  );

  const [rangeStart, setRangeStart] = useState<Date | null | undefined>(
    defaultRange?.[0]
  );
  const [rangeEnd, setRangeEnd] = useState<Date | null | undefined>(
    defaultRange?.[1]
  );

  const [rangePartiallyFilled, setRangePartiallyFilled] =
    useState<boolean>(false);

  const {
    isInline = true,
    isActive = false,
    unmountEvent,
    closePopover,
    popoverRef,
    startDateSetterOnly,
    endDateSetterOnly,
  } = _privateProps;

  /**
   * Force the number of "visibleCount" to be equal to 1 if
   * inline container is smaller then `DUAL_CALENDAR_WIDTH`
   */
  const { width: inlineCalendarContainerWidth } = useResizeObserver({
    ref: calendarContainerRef,
  });

  const visibleCount =
    isInline &&
    inlineCalendarContainerWidth &&
    inlineCalendarContainerWidth <= DUAL_CALENDAR_WIDTH
      ? 1
      : visibleCountProp;

  // Given the calendar popover is open, when user navigates through the dates using keyboard
  // then the focussed date is used to hold the computed date and set focus on the actual Calendar component.
  const [focussedDate, setFocussedDate] = useState<Date | undefined>(undefined);

  // Clear manually set focus
  useEffect(() => {
    const calendarContent = calendarContentRef.current;

    calendarContent?.addEventListener("focusout", (event) => {
      if (!calendarContent?.contains(event.relatedTarget as HTMLElement)) {
        setFocussedDate(undefined);
      }
    });

    return () => {
      calendarContent?.removeEventListener("focusout", (event) => {
        setFocussedDate(undefined);
      });
    };
  }, []);

  // Set first focusable items for proper keyboard interaction on inline calendar (today or 1st non disabled day)
  useEffect(() => {
    if (isInline) {
      const calendarContent = calendarContentRef.current;
      const visibleMonths = calendarContent?.querySelectorAll(
        ".spark-calendar__month"
      );

      let _firstFocusableDays: (string | undefined)[] = [];

      visibleMonths?.forEach((month) => {
        const selectedDay = month?.querySelector(
          ".spark-calendar__day--selected:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
        );
        const today = month?.querySelector(
          ".spark-calendar__day--today:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
        );
        const firstActiveDay = month?.querySelector(
          ".spark-calendar__day:not(.spark-calendar__day--inactive):not(.spark-calendar__day--disabled)"
        );

        const firstFocusableDay = selectedDay || today || firstActiveDay;
        const dateString = (firstFocusableDay as HTMLButtonElement)?.dataset
          .date;

        _firstFocusableDays = [..._firstFocusableDays, dateString];
      });

      setFirstFocusableDays(_firstFocusableDays);
    }
    // Enforcing range and value in the deps array, as their change also affects focusable items
  }, [isInline, month, range, value]);

  const clearHoverClasses = (): void => {
    hoverStartsRef.current.length &&
      hoverStartsRef.current.forEach((el) => {
        el?.classList.remove("hover-start");
        hoverStartsRef.current = [];
      });
    hoverEndsRef.current.length &&
      hoverEndsRef.current.forEach((el) => {
        el?.classList.remove("hover-end");
        hoverEndsRef.current = [];
      });
  };

  // Clear hover classNames when range established
  useEffect(() => {
    if (!rangePartiallyFilled) {
      clearHoverClasses();
    }
  }, [rangePartiallyFilled]);

  const getCalendarDates = (count: number): (string | number)[][] => {
    const calendarMonth =
      month || (valueState && +valueState.getMonth() + 1) || undefined;
    const calendarYear = year || valueState?.getFullYear();

    return getDatesInPastCurrentAndNextMonth(
      calendarMonth,
      calendarYear,
      count
    );
  };

  const primaryCalendarMonth = (month: number, count: number): number => {
    const isJanuaryInSecondCalendar = month === 1 && count === 1;
    return isJanuaryInSecondCalendar ? 12 : month - count;
  };

  const primaryCalendarYear = (
    year: number,
    month: number,
    count: number
  ): number => {
    const isJanuaryInSecondCalendar = month === 1 && count === 1;
    return isJanuaryInSecondCalendar ? year - 1 : year;
  };

  const goToDate = (date: Date, count: number = 0): void => {
    setValueState(date);
    onChange?.(date);
    countClicked.current = count;

    date && setMonth(primaryCalendarMonth(date.getMonth() + 1, count));
    date &&
      setYear(
        primaryCalendarYear(date.getFullYear(), date.getMonth() + 1, count)
      );

    !isInline && closePopover?.();
  };

  const _setRangeDate = (
    date: Date,
    isBeforeRangeStart: boolean,
    isAfterRangeEnd: boolean,
    count: number = 0
  ): void => {
    if (startDateSetterOnly) {
      setRangeStart(date);
      if (isAfterRangeEnd) {
        setRangeEnd(null);
        onRangeChange?.([date, null]);
      } else {
        onRangeChange?.([date, rangeEnd]);
      }
    } else if (endDateSetterOnly) {
      setRangeEnd(date);
      if (isBeforeRangeStart) {
        setRangeStart(null);
        onRangeChange?.([null, date]);
      } else {
        onRangeChange?.([rangeStart, date]);
      }
    } else if (isBeforeRangeStart) {
      setRangeStart(date);
      onRangeChange?.([date, rangeEnd]);
      rangeOrderToggle.current = "end";
    } else if (isAfterRangeEnd) {
      setRangeEnd(date);
      onRangeChange?.([rangeStart, date]);
      rangeOrderToggle.current = "start";
    } else {
      if (rangeOrderToggle.current === "start") {
        rangeOrderToggle.current = "end";
        onRangeChange?.([date, rangeEnd]);
        setRangeStart(date);
      } else if (rangeOrderToggle.current === "end") {
        rangeOrderToggle.current = "start";
        onRangeChange?.([rangeStart, date]);
        setRangeEnd(date);
      }
    }

    countClicked.current = count;

    date && setMonth(primaryCalendarMonth(date.getMonth() + 1, count));
    date &&
      setYear(
        primaryCalendarYear(date.getFullYear(), date.getMonth() + 1, count)
      );

    !isInline && closePopover?.();
  };

  const isPrevMonthButtonDisabled = useMemo((): boolean => {
    if (navigateToDisabledMonths || !minDate) return false;

    const unifiedMinDate = withoutTime(
      new Date(minDate.getFullYear(), minDate.getMonth(), 15)
    );
    const unifiedCalendarDate = withoutTime(new Date(year, month - 1, 15));

    if (unifiedMinDate.getTime() >= unifiedCalendarDate.getTime()) {
      return true;
    }

    return false;
  }, [minDate, month, navigateToDisabledMonths, year]);

  useEffect(() => {
    if (isActive) {
      // setTimeout needed when switching from one Calendar popover to another. Otherwise focus is not gained
      setTimeout(
        () =>
          // If prev month button is disabled, focus on its container (navigation element)
          isPrevMonthButtonDisabled
            ? prevRef?.current?.parentElement?.focus()
            : prevRef?.current?.focus(),
        0
      );
    }
    return () => {
      if (isActive) {
        unmountEvent?.();
      }
    };
  }, [isActive, isPrevMonthButtonDisabled, unmountEvent]);

  // Method to prevent to prevent unnecessary calendar shifting when date is already visible
  const isOutOfCurrentMonthsScope = (newDate: Date): boolean =>
    !(
      visibleCount === 2 &&
      ((newDate.getFullYear() === year &&
        // the case when the second month is not January
        (newDate.getMonth() + 1 === month ||
          newDate.getMonth() + 1 === month + 1)) ||
        // the case when the second month is January
        (month === 12 &&
          newDate.getMonth() + 1 === 1 &&
          newDate.getFullYear() === year + 1))
    );

  useEffect(() => {
    if (isControlled.current === true) {
      setValueState(value);
      if (value && isOutOfCurrentMonthsScope(value)) {
        setMonth(
          primaryCalendarMonth(value.getMonth() + 1, countClicked.current)
        );
        setYear(
          primaryCalendarYear(
            value.getFullYear(),
            value.getMonth() + 1,
            countClicked.current
          )
        );
      }

      if (value === null) {
        setMonth(today.current.getMonth() + 1);
        setYear(today.current.getFullYear());
      }
    }
    // as a rule, remove callbacks from deps to prevent unexpected re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isRangeControlled.current && range) {
      const [newRangeStart, newRangeEnd] = range;

      let newRangeStartForceNull = false;
      let newRangeEndForceNull = false;

      if (
        (!newRangeEnd && !!newRangeStart) ||
        (!!newRangeEnd && !newRangeStart)
      ) {
        setRangePartiallyFilled(true);
      } else {
        setRangePartiallyFilled(false);
      }

      // If controlled range start changes, move calendar to proper month
      if (
        (newRangeStart && rangeStart === null) ||
        (newRangeStart &&
          rangeStart &&
          withoutTime(newRangeStart).getTime() !==
            withoutTime(rangeStart).getTime())
      ) {
        // Handle situation when rangeStart > rangeEnd
        if (
          newRangeEnd &&
          withoutTime(newRangeStart) > withoutTime(newRangeEnd)
        ) {
          newRangeEndForceNull = true;
        }

        if (!isInline || isOutOfCurrentMonthsScope(newRangeStart)) {
          setMonth(newRangeStart.getMonth() + 1);
          setYear(newRangeStart.getFullYear());
        }
      }

      // If controlled range end changes, move calendar to proper month
      if (
        (newRangeEnd && rangeEnd === null) ||
        (newRangeEnd &&
          rangeEnd &&
          withoutTime(newRangeEnd).getTime() !==
            withoutTime(rangeEnd).getTime())
      ) {
        // Handle situation when rangeEnd < rangeStart
        if (
          newRangeStart &&
          withoutTime(newRangeEnd) < withoutTime(newRangeStart)
        ) {
          newRangeStartForceNull = true;
        }

        setMonth(
          primaryCalendarMonth(newRangeEnd.getMonth() + 1, visibleCount - 1)
        );
        setYear(
          primaryCalendarYear(
            newRangeEnd.getFullYear(),
            newRangeEnd.getMonth() + 1,
            visibleCount - 1
          )
        );
      }

      // Handling moving calendar to proper month when nulls as new values
      if ((!newRangeStart && !!newRangeEnd) || (!!newRangeEnd && !rangeStart)) {
        setMonth(
          primaryCalendarMonth(newRangeEnd.getMonth() + 1, visibleCount - 1)
        );
        setYear(
          primaryCalendarYear(
            newRangeEnd.getFullYear(),
            newRangeEnd.getMonth() + 1,
            visibleCount - 1
          )
        );
        rangeOrderToggle.current = "start";
      }

      if ((!newRangeEnd && !!newRangeStart) || (!!newRangeStart && !rangeEnd)) {
        if (!isInline || isOutOfCurrentMonthsScope(newRangeStart)) {
          setMonth(newRangeStart.getMonth() + 1);
          setYear(newRangeStart.getFullYear());
        }
        rangeOrderToggle.current = "end";
      }

      if (!newRangeStart && !newRangeEnd) {
        // If both controlled range dates are null, move calendar month to today
        setMonth(today.current.getMonth() + 1);
        setYear(today.current.getFullYear());
        rangeOrderToggle.current = "start";
      }

      if (newRangeStartForceNull) {
        onRangeChange?.([null, newRangeEnd]);
      }
      if (newRangeEndForceNull) {
        onRangeChange?.([newRangeStart, null]);
      }

      setRangeStart(newRangeStartForceNull ? null : newRangeStart);
      setRangeEnd(newRangeEndForceNull ? null : newRangeEnd);
    }

    // we need to compare rangeStart with newRangeStart but we don't want rangeStart to trigger effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, visibleCount]);

  useEffect(() => {
    if (isRange.current && (range || defaultRange)) {
      const initialRangeStart = defaultRange?.[0] || range?.[0];
      const initialRangeEnd = defaultRange?.[1] || range?.[1];

      // Set calendar month and year for when both range values exist
      if (!!initialRangeStart && !!initialRangeEnd) {
        setMonth(
          endDateSetterOnly
            ? primaryCalendarMonth(
                initialRangeEnd.getMonth() + 1,
                visibleCount - 1
              )
            : initialRangeStart.getMonth() + 1
        );
        setYear(
          endDateSetterOnly
            ? primaryCalendarYear(
                initialRangeEnd.getFullYear(),
                initialRangeEnd.getMonth() + 1,
                visibleCount - 1
              )
            : initialRangeStart.getFullYear()
        );
      }
    }
    // No array deps needed as we adjust Calendar month and year ONLY on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCalendarDates = (
    dateArray: CalendarDateType,
    index: number,
    count = 0,
    dayInWhichMonth: WhichMonth,
    disabledCalendar: boolean | undefined
  ): JSX.Element => {
    let note: string | undefined;
    const _date = withoutTime(
      new Date(
        Number(dateArray[0]),
        Number(dateArray[1]) - 1,
        Number(dateArray[2])
      )
    );
    notes?.forEach((dateObj) => {
      const dateObjWithoutTime = withoutTime(dateObj?.date);
      const isSameDate = _date.getTime() === dateObjWithoutTime.getTime();
      if (isSameDate) {
        note = dateObj?.text;
      }
    });
    let disabled = false;
    daysDisabled?.forEach((dateObj: Date) => {
      const dateObjWithoutTime = withoutTime(dateObj);
      const isSameDate = _date.getTime() === dateObjWithoutTime.getTime();
      if (isSameDate) {
        disabled = true;
      }
    });
    const isToday = isSameDate(_date, today.current);
    const isCurrent = valueState && isSameDate(_date, valueState);
    const monthNew = month + count > 12 ? 1 : month + count;
    const yearNew = month + count > 12 ? year + 1 : year;
    const inMonth =
      monthNew &&
      year &&
      isSameMonth(_date, withoutTime(new Date(yearNew, monthNew - 1, 1)));

    const isOutOfRange =
      (minDate && _date < withoutTime(minDate)) ||
      (maxDate && _date > withoutTime(maxDate));

    const isDateDisabled = !!isOutOfRange || disabled || disabledCalendar;

    const isRangeStart = Boolean(
      isRange.current &&
        rangeStart &&
        _date.getTime() === withoutTime(rangeStart).getTime()
    );

    const isBeforeRangeStart = Boolean(
      isRange.current &&
        rangeStart &&
        _date.getTime() < withoutTime(rangeStart).getTime()
    );

    const isAfterRangeEnd = Boolean(
      isRange.current &&
        rangeEnd &&
        _date.getTime() > withoutTime(rangeEnd).getTime()
    );

    const isRangeEnd = Boolean(
      isRange.current &&
        rangeEnd &&
        _date.getTime() === withoutTime(rangeEnd).getTime()
    );

    const isRangeMiddle = Boolean(
      isRange.current &&
        rangeStart &&
        rangeEnd &&
        _date.getTime() > withoutTime(rangeStart).getTime() &&
        _date.getTime() < withoutTime(rangeEnd).getTime()
    );

    return (
      <CalendarDate
        note={note}
        isCurrent={!!isCurrent}
        isDateDisabled={isDateDisabled}
        inMonth={!!inMonth}
        isToday={isToday}
        isRangeStart={isRangeStart}
        isRangeEnd={isRangeEnd}
        isRangeMiddle={isRangeMiddle}
        focussedDate={isActive || isInline ? focussedDate : undefined}
        key={index}
        date={dateArray}
        hideDaysOutsideCurrentMonth={hideDaysOutsideCurrentMonth}
        onClick={(): void => {
          if (isDateDisabled && inMonth) {
            return;
          }
          if (dayInWhichMonth === "CURRENT" && !disabled) {
            isRange.current
              ? _setRangeDate(_date, isBeforeRangeStart, isAfterRangeEnd, count)
              : goToDate(_date, count);
            setFocussedDate(_date);
          }
          if (dayInWhichMonth === "PREV") {
            if (!isPrevMonthButtonDisabled) {
              goToPrevMonth();
            }
          } else if (dayInWhichMonth === "NEXT") {
            if (!isNextMonthButtonDisabled) {
              goToNextMonth();
            }
          }
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>): void => {
          // Handle Arrow keys
          onCalendarKeyDown(event);

          // Handle Enter and Spacebar
          if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            if (!isDateDisabled) {
              setFocussedDate(new Date(_date));
              isRange.current
                ? _setRangeDate(
                    _date,
                    isBeforeRangeStart,
                    isAfterRangeEnd,
                    count
                  )
                : goToDate(_date, count);
            }
          }
        }}
        onMouseOver={(e): void =>
          (rangePartiallyFilled && isInline) ||
          (rangePartiallyFilled &&
            ((startDateSetterOnly && !!rangeEnd) ||
              (endDateSetterOnly && !!rangeStart)))
            ? onCalendarDateMouseOver(e, hoverStartsRef, hoverEndsRef)
            : undefined
        }
        tabIndex={
          isDateDisabled ||
          !inMonth ||
          (isInline && dateArray?.join("-") !== firstFocusableDays[count])
            ? -1
            : undefined
        }
      />
    );
  };

  const swipeCalendar = (direction?: "left" | "right"): void => {
    // targetting two possible refs because location of "spark-calendar" class is different in popover and inline implementation
    const calendarContainer =
      popoverRef?.current || calendarContainerRef.current?.children[0];

    calendarContentRef.current?.setAttribute(
      "style",
      `transform: translateX(${direction === "left" ? "30" : "-30"}rem)`
    );
    calendarContainer?.classList.add("no-animate");
    setTimeout(() => {
      calendarContentRef.current?.setAttribute(
        "style",
        "transform: translateX(0)"
      );
      calendarContainer?.classList.remove("no-animate");
    }, 10);
  };

  const goToPrevMonth = (): void => {
    const { month: prevMonth, year: prevYear } = getPreviousMonth(month, year);
    setMonth(prevMonth);
    setYear(prevYear);
    swipeCalendar("right");
  };

  const goToNextMonth = (): void => {
    const { month: nextMonth, year: nextYear } = getNextMonth(month, year);
    setMonth(nextMonth);
    setYear(nextYear);
    swipeCalendar("left");
  };

  const findCalendarCountMonth = (
    year: number,
    month: number,
    count: number
  ): Date => {
    return withoutTime(
      new Date(
        month === 12 && count === 1 ? year + 1 : year,
        month === 12 && count === 1 ? 0 : month + count - 1,
        1
      )
    );
  };

  const rangeStartInThisMonth = (count: number): boolean =>
    Boolean(
      rangeStart &&
        isSameMonth(rangeStart, findCalendarCountMonth(year, month, count))
    );

  const rangeEndInThisMonth = (count: number): boolean =>
    Boolean(
      rangeEnd &&
        isSameMonth(rangeEnd, findCalendarCountMonth(year, month, count))
    );

  const calendarMonthClasses = (count: number): string => {
    const startValueBefore =
      rangeStart &&
      !rangeStartInThisMonth(count) &&
      withoutTime(rangeStart) <
        withoutTime(new Date(year, month - 1 + count, 1));
    const endValueBefore =
      rangeEnd &&
      !rangeEndInThisMonth(count) &&
      withoutTime(rangeEnd) < withoutTime(new Date(year, month - 1 + count, 1));
    const startValueAfter =
      rangeStart &&
      !rangeStartInThisMonth(count) &&
      withoutTime(rangeStart) >
        withoutTime(new Date(year, month - 1 + count, 1));
    const endValueAfter =
      rangeEnd &&
      !rangeEndInThisMonth(count) &&
      withoutTime(rangeEnd) > withoutTime(new Date(year, month - 1 + count, 1));

    return classNames("spark-calendar__month", {
      "has-value":
        (value && !isRange) ||
        rangeStartInThisMonth(count) ||
        rangeEndInThisMonth(count),
      "range-start": rangeStartInThisMonth(count),
      "value-after": startValueAfter || endValueAfter,
      "before-range-start": startValueAfter,
      "range-end": rangeEndInThisMonth(count),
      "value-before": startValueBefore || endValueBefore,
      "after-range-end": endValueBefore,
    });
  };

  const _setTodayAsRangeStart = (): void => {
    setRangeStart(today.current);
    setRangeEnd(null);
    onRangeChange?.([today.current, null]);
    rangeOrderToggle.current = "end";
    setMonth(today.current.getMonth() + 1);
    setYear(today.current.getFullYear());
  };

  const calendarCounts = visibleCount === 1 ? [0] : [0, 1];

  const isNextMonthButtonDisabled = useMemo((): boolean => {
    if (navigateToDisabledMonths || !maxDate) return false;

    const unifiedMaxDate = withoutTime(
      new Date(maxDate.getFullYear(), maxDate.getMonth(), 15)
    );

    const unifiedCalendarDate =
      visibleCount === 1
        ? withoutTime(new Date(year, month - 1, 15))
        : withoutTime(
            new Date(month < 12 ? year : year + 1, month < 12 ? month : 0, 15)
          );

    if (unifiedMaxDate.getTime() <= unifiedCalendarDate.getTime()) {
      return true;
    }

    return false;
  }, [maxDate, month, navigateToDisabledMonths, visibleCount, year]);

  const isTodayDisabled = useMemo(() => {
    let todayDisabled = false;

    const isTodayOutOfRange =
      (minDate && withoutTime(today.current) < withoutTime(minDate)) ||
      (maxDate && withoutTime(today.current) > withoutTime(maxDate));

    daysDisabled?.forEach((dateObj: Date) => {
      const dateObjWithoutTime = withoutTime(dateObj);
      const isSameDate =
        today.current.getTime() === dateObjWithoutTime.getTime();
      if (isSameDate) {
        todayDisabled = true;
      }
    });

    return disabled || isTodayOutOfRange || todayDisabled;
  }, [daysDisabled, disabled, maxDate, minDate]);

  return (
    <FocusTrap enabled={!isInline}>
      <div
        className={classNames(
          {
            "spark-calendar-inline": isInline,
            "spark-calendar--disabled": disabled,
          },
          className
        )}
        ref={calendarContainerRef}
        {...rest}
      >
        <div
          tabIndex={isInline ? undefined : -1}
          className={classNames({ "spark-calendar": isInline })}
          role={isInline ? "application" : undefined}
          aria-label={ariaLabel}
          aria-disabled={disabled}
          data-visible-count={isInline ? String(visibleCount) : undefined}
          onMouseLeave={(): void => clearHoverClasses()}
        >
          <nav
            className="spark-calendar__nav"
            aria-label={calendarMonthsNavAriaLabel}
            tabIndex={isPrevMonthButtonDisabled ? 0 : undefined}
          >
            <button
              type="button"
              className="spark-calendar__previous spark-icon-chevron-left"
              aria-label={ariaPreviousMonthLabel}
              onClick={goToPrevMonth}
              ref={prevRef}
              disabled={disabled || isPrevMonthButtonDisabled}
            />
            <button
              type="button"
              className="spark-calendar__next spark-icon-chevron-right"
              aria-label={ariaNextMonthLabel}
              onClick={goToNextMonth}
              ref={nextRef}
              disabled={disabled || isNextMonthButtonDisabled}
            />
          </nav>
          <div className="spark-calendar__overflow">
            <div className="spark-calendar__content" ref={calendarContentRef}>
              {calendarCounts.map((count) => {
                return (
                  <div className={calendarMonthClasses(count)} key={count}>
                    <div className="spark-calendar__month-title">
                      {advanceByMonthAndYear && count === 0 ? (
                        <MonthAndYearOptions
                          month={month + count}
                          year={year}
                          setMonth={setMonth}
                          setYear={setYear}
                          monthsList={monthsList}
                          yearsList={yearsList}
                          disabled={disabled}
                          minDate={
                            navigateToDisabledMonths === false
                              ? minDate
                              : undefined
                          }
                          maxDate={
                            navigateToDisabledMonths === false
                              ? maxDate
                              : undefined
                          }
                        />
                      ) : (
                        <MonthAndYearLabel month={month + count} year={year} />
                      )}
                    </div>
                    <div className="spark-calendar__days-of-week">
                      {Object.values(WEEK_DAYS).map((day, index) => (
                        <DayOfWeek dayOfWeek={day} key={index} />
                      ))}
                    </div>
                    <div
                      className="spark-calendar__days"
                      role={isInline ? "grid" : undefined}
                    >
                      {getCalendarDates(count).map((dateArray, index) => {
                        const monthInDate = Number(dateArray[1]);
                        let dayInWhichMonth: WhichMonth = "CURRENT";
                        const monthNew = month + count > 12 ? 1 : month + count;

                        // Edge case on new year transition (december - january)
                        if (monthNew === 1 && monthInDate === 12) {
                          dayInWhichMonth = "PREV";
                        } else if (monthNew === 12 && monthInDate === 1) {
                          dayInWhichMonth = "NEXT";
                          // Standard case
                        } else if (monthInDate < monthNew) {
                          dayInWhichMonth = "PREV";
                        } else if (monthInDate > monthNew) {
                          dayInWhichMonth = "NEXT";
                        }

                        return renderCalendarDates(
                          dateArray,
                          index,
                          count,
                          dayInWhichMonth,
                          disabled
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            {showTodayButton && (
              <div className="spark-calendar__footer">
                <button
                  type="button"
                  className="spark-calendar__today spark-btn spark-btn--text"
                  onClick={(): void => {
                    isRange.current
                      ? _setTodayAsRangeStart()
                      : goToDate(new Date());
                  }}
                  onKeyDown={(event): void => {
                    if (event.key === "Enter" || event.key === " ") {
                      isRange.current
                        ? _setTodayAsRangeStart()
                        : goToDate(new Date());
                    }
                  }}
                  disabled={isTodayDisabled}
                >
                  {showTodayButtonLabel}
                </button>
              </div>
            )}
          </div>
        </div>
        <CalendarCssOverrides />
      </div>
    </FocusTrap>
  );
};

Calendar.displayName = "Calendar";
