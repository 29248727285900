import classNames from "classnames";
import React from "react";
import { TableCellProps } from "./table.types";

export const TableCell = ({
  align,
  children,
  className,
  disabled,
  status,
  ...rest
}: TableCellProps): JSX.Element => {
  const cellClasses = classNames(
    className,
    { "spark-table__disabled-cell": disabled },
    { [`spark-text-${align}`]: align }
  );

  const cellProps: { [key: string]: string } = {};

  if (status) {
    cellProps[`data-${status}`] = status;
  }

  const classProp = cellClasses ? { className: cellClasses } : {};

  // Status icon/tooltip needs to be rendered before children.
  // The data-warn attribute doesn't render it.
  return (
    <td {...classProp} {...cellProps} {...rest}>
      {children}
    </td>
  );
};

TableCell.displayName = "TableCell";
