import { MonthAndYear } from "./Calendar.types";
import { CalendarDateType } from "./CalendarDate.types";

export const THIS_YEAR = +new Date().getFullYear();
export const THIS_MONTH = +new Date().getMonth() + 1;

export const WEEK_DAYS = {
  Sunday: "S",
  Monday: "M",
  Tuesday: "T",
  Wednesday: "W",
  Thursday: "T",
  Friday: "F",
  Saturday: "S",
};

export const CALENDAR_MONTHS = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

export const CALENDAR_WEEKS = 6;

/**
 * Returns padded string of the number based on the length provided
 * @param value
 * @param length
 */
export const zeroPad = (value: number, length: number): string =>
  `${value}`.padStart(length, "0");

/**
 * Returns boolean true if valid date is passed
 * @param date
 */
export const isDate = (date: Date): boolean => {
  const isDate = Object.prototype.toString.call(date) === "[object Date]";
  const isValidDate = date && !Number.isNaN(date.valueOf());
  return isDate && isValidDate;
};

export const isLeapYear = (year: number | undefined): boolean => {
  // If the year is not yet provided, we should assume it is a leap year to allow entering "29" as the day number
  if (!year) return true;

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

/**
 * Get the last date of the month passed based on month & year
 * @param month
 * @param year
 */
export const getMonthLength = (
  month = THIS_MONTH,
  year = THIS_YEAR
): number => {
  const months30 = [4, 6, 9, 11];

  return month === 2
    ? isLeapYear(year)
      ? 29
      : 28
    : months30.includes(month)
    ? 30
    : 31;
};

/**
 * Should return first day of the month. i.e. if month starts on Monday, then the return would be 2
 * Sun - 1
 * Mon - 2
 * Tue - 3
 * Wed - 4
 * Thu - 5
 * Fri - 6
 * Sat - 7
 * @param month
 * @param year
 */
export const getMonthFirstDay = (
  month = THIS_MONTH,
  year = THIS_YEAR
): number => {
  // using Date() constructor with syntax: Date(year, monthIndex, day)
  return new Date(year, month - 1, 1).getDay() + 1;
};

/**
 * Returns the string version of date for display i.e 2022-10-10 YYYY-MM-DD
 * @param date
 */
export const getDateString = (date: Date): string => {
  return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(
    date.getDate(),
    2
  )}`;
};

/**
 * Should return true if both args are from same month
 * @param date
 * @param baseDate
 */
export const isSameMonth = (date: Date, baseDate = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) return false;

  const baseDateMonth = +baseDate.getMonth() + 1;
  const baseDateYear = baseDate.getFullYear();

  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return +baseDateMonth === +dateMonth && +baseDateYear === +dateYear;
};

/**
 * Should return true if both date args are the same
 * @param date
 * @param baseDate
 */
export const isSameDate = (date: Date, baseDate = new Date()): boolean => {
  if (!(isDate(date) && isDate(baseDate))) return false;

  const baseDateCopy = baseDate.getDate();
  const baseDateMonth = +baseDate.getMonth() + 1;
  const baseDateYear = baseDate.getFullYear();

  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();

  return (
    baseDateCopy === dateDate &&
    baseDateMonth === dateMonth &&
    baseDateYear === dateYear
  );
};

/**
 * Used when we use prev button on calendar popover. The month and year have to be computed before setting to the state.
 * This method calculates the previous month and year of the current date
 * @param month
 * @param year
 */
export const getPreviousMonth = (month: number, year: number): MonthAndYear => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;

  return { month: prevMonth, year: prevMonthYear };
};

/**
 * Used when next button is clicked on the calendar popover
 * The method generates the next month based on the index
 * @param month
 * @param year
 */
export const getNextMonth = (month: number, year: number): MonthAndYear => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;

  return { month: nextMonth, year: nextMonthYear };
};

/**
 * Used to generate the dates for displaying in the calendar popover
 * Total dates would be 42, which includes previous, present and next month dates
 * @param month
 * @param year
 */
export const getDatesInPastCurrentAndNextMonth = (
  month = THIS_MONTH,
  year = THIS_YEAR,
  count: number = 0
): CalendarDateType[] => {
  const monthLimited = month + count > 12 ? 1 : month + count;
  const yearChecked = month + count > 12 ? year + 1 : year;

  const monthDays = getMonthLength(monthLimited, yearChecked);
  const monthFirstDay = getMonthFirstDay(monthLimited, yearChecked);

  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth =
    CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(
    monthLimited,
    yearChecked
  );
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(
    monthLimited,
    yearChecked
  );

  const prevMonthDays = getMonthLength(prevMonth, prevMonthYear);

  const prevMonthDates = [
    ...new Array(daysFromPrevMonth > 0 ? daysFromPrevMonth : 0),
  ].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [yearChecked, zeroPad(monthLimited, 2), zeroPad(day, 2)];
  });

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });

  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};

/**
 * Removes the time part of the date. Avoid using ISO string while creating a date object. Use individual year, month, and day components (months are zero-based), e.g., new Date(2022, 0, 1)
 * @param dateTime
 */
export const withoutTime = (dateTime: Date): Date =>
  new Date(new Date(dateTime.getTime()).setHours(0, 0, 0, 0));

export const withoutTimeStr = (dateTime: Date): string =>
  withoutTime(dateTime).toString();
