import classNames from "classnames";
import React, { ReactElement, ReactNode, useRef } from "react";
import { useCombinedRefs } from "../utils/useCombinedRefs";
import { IconSize } from "../types";
import { FloatingActionButtonWithOptions } from "./fab-with-options";
import {
  FloatingActionButtonProps,
  FloatingActionButtonWithForwardRef,
} from "./floating-action-button.types";
import { renderIconClassName } from "../utils";

const WithBDO = (Component: ReactNode, dir = "rtl"): ReactElement => (
  <bdo dir={dir}>{Component}</bdo>
);

export const FloatingActionButton = React.forwardRef(
  (props: FloatingActionButtonProps, buttonRefInp): ReactElement => {
    const {
      onClick,
      condensed,
      label,
      icon,
      iconSize = IconSize.MEDIUM,
      placement,
      multiActionPlacement,
      ariaLabel,
      rtl,
      actions,
      className: classNameInp,
      ...rest
    } = props;

    const buttonRef = useRef<HTMLButtonElement>(null);
    useCombinedRefs(buttonRef, buttonRefInp);

    const renderFABWithoutActions = (): ReactElement => {
      const className = classNames("spark-fab", {
        "spark-fab--sm": condensed,
        "spark-fab--extended": label,
        [`spark-fab--${placement}`]: placement,
        classNameInp,
      });
      const iconClassName = classNames(
        { [`${renderIconClassName(icon)}`]: icon },
        { [`spark-icon--${iconSize}`]: icon && iconSize && !label }
      );

      const content = (
        <button
          type="button"
          {...rest}
          onClick={onClick}
          ref={buttonRef}
          className={className}
          aria-label={icon && ariaLabel}
        >
          {icon && <i className={iconClassName} aria-hidden="true"></i>}
          {label}
        </button>
      );
      return rtl && !placement ? WithBDO(content) : content;
    };

    if (!actions) {
      return renderFABWithoutActions();
    } else {
      return <FloatingActionButtonWithOptions ref={buttonRef} {...props} />;
    }
  }
) as FloatingActionButtonWithForwardRef;

FloatingActionButton.displayName = "FloatingActionButton";
