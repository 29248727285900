export const handleInvalidDate = (
  date?: Date | null
): Date | undefined | null => {
  if (date) {
    return date.toString() !== "Invalid Date" ? date : undefined;
  }
  return undefined;
};
/**
 * Add number of days to passed date object
 * @param dateTime
 * @param count_days
 */
export const addDays = (dateTime: Date, count_days = 0): Date => {
  return new Date(new Date(dateTime).setDate(dateTime.getDate() + count_days));
};

/** Check if valid date has been passed */
export const isValidDate = (d: Date | undefined): boolean => {
  if (d?.getTime()) {
    return true;
  }
  return false;
};
