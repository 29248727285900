import React, { ReactElement, forwardRef } from "react";
import classNames from "classnames";
import { CheckboxProps } from "./checkbox.types";

export interface CheckboxWithForwardRef
  extends React.ForwardRefExoticComponent<
    CheckboxProps & React.RefAttributes<HTMLInputElement>
  > {}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      className,
      defaultChecked,
      disabled,
      id,
      label,
      name,
      onChange,
      showLabel = true,
      value,
      ...rest
    },
    ref
  ): ReactElement => (
    <label className={classNames("spark-checkbox", className)}>
      <input
        id={id}
        type="checkbox"
        className={"spark-checkbox__input"}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        onChange={(e): void => {
          onChange?.(e, e.target.value);
        }}
        value={value}
        ref={ref}
        {...rest}
      />
      <span className="spark-checkbox__box" />
      <span className={showLabel ? "spark-label" : "spark-assistive-text"}>
        {label}
      </span>
    </label>
  )
) as CheckboxWithForwardRef;

Checkbox.displayName = "Checkbox";
