/* eslint-disable lines-around-comment */

import { ReactNode } from "react";

export enum ViewMoreStatus {
  NONE = "None",
  LOADING = "Loading",
  NO_RESULTS = "No Results",
}

interface ViewMorePropsBase {
  /** "None" or "Loading" or "No Results"
   * Use ViewMoreStatus enum for values.
   * If an invalid prop value is passed, then "None" will be used in that case.
   */
  status: ViewMoreStatus;

  /** Any extra CSS classes for the component */
  className?: string;

  /** A flag that changes the appearance of component between a button & spinny with text. Default value is true */
  isButton?: boolean;

  /** Text to display as count of records loading */
  loadingRecordsCount?: number;
}

export interface ViewMoreButtonProps
  extends ViewMorePropsBase,
    Omit<React.HTMLProps<HTMLButtonElement>, "size" | "type"> {
  /** Text to display inside button */
  renderViewMoreButtonText?: (loadingRecordsCountParam?: number) => ReactNode;

  /** Text to display while loading */
  renderLoadingTitle?: (loadingRecordsCountParam?: number) => ReactNode;
}

export interface ViewMoreLazyLoadProps extends ViewMorePropsBase {
  /** Text to display when no results are present and also when isButton is false */
  renderNoResultsAvailableText?: () => string;

  /** Text to display while loading */
  renderLazyLoadingTitle?: (loadingRecordsCountParam?: number) => string;
}

export interface ViewMoreProps
  extends ViewMoreButtonProps,
    ViewMoreLazyLoadProps {}
